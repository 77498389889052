"use strict";

const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";
// const baseURL = "https://ideaatlas3xweb-104077-ruby.b104077.dev.eastus.az.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL;
