import React from "react";
import { IconButton, Collapse } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import "./Common.web.css";

const CustomErrorAlert = (props:any) => {
  return (
    <>
      <Collapse in={props.isError}>
        <Alert
          icon={false}
          className="ErrorBox ErrorArrowTop"        
          action={
            <IconButton
              aria-label="close"
              color="inherit"
              size="small"
              onClick={props.handleError}
            >
              {!props.closeBtnDisabled && <CloseIcon fontSize="inherit" />}
            </IconButton>
          }
        >
          {props.errorMsg}
        </Alert>
      </Collapse>      
    </>
    
  );
};

export default CustomErrorAlert;