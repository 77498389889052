import React from "react";
// Customizable Area Start
import EmailAccountRegistrationControllerWeb, {
  Props
} from "./EmailAccountRegistrationControllerWeb";
import {
  Button,
  Dialog,
  DialogProps,
  DialogContent,
  DialogTitle,
  IconButton,
  DialogContentText
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
export const configJSON = require("./config");
// Customizable Area End

export default class CookieInfo extends EmailAccountRegistrationControllerWeb {
  constructor(props: Props) {
    // Customizable Area Start
    // Customizable Area End
    super(props);
  }
  // Customizable Area Start
  async componentDidMount() {
    this.getCookiePrivacyPolicy();
  }
  handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    this.setState({
      open: true,
      scroll: scrollType
    });
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <div>
        <Button
          id="openDialog"
          onClick={this.handleClickOpen("paper")}
          style={{
            fontSize: "10px",
            fontWeight: 500,
            textTransform: "uppercase",
            fontFamily: "Jost-Regular",
            color: "#6A8393",
            cursor: "pointer"
          }}
        >
          Information
        </Button>
        <Dialog
          id="closeDialog"
          open={this.state.open}
          onClose={this.handleClose}
          scroll={this.state.scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
          className="termsPopUpContainer"
        >
          <IconButton
            edge="end"
            color="inherit"
            onClick={this.handleClose}
            aria-label="close"
            className="close-icon"
          >
            <CloseIcon />
          </IconButton>

          <DialogTitle id="scroll-dialog-title">privacy policy</DialogTitle>
          <DialogContent dividers={this.state.scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            tabIndex={-1}
          >
            {this.state.privacyPolicyContent && this.state.privacyPolicyContent.length > 0 ? (
                <p className="termsAndContidionContent"
                    dangerouslySetInnerHTML={{
                      __html: this.state.privacyPolicyContent
                    }}>
                </p>):null }
           
          </DialogContentText>
        </DialogContent>
        </Dialog>
      </div>
    );
    // Customizable Area End
  }
}
