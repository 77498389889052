import React from "react";
import { IconButton, Collapse } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import CloseIcon from "@material-ui/icons/Close";
import "./Common.web.css";

const CustomConfirmation = (props:any) => {
  return (
    <>
      <Collapse in={props.isSuccess}>
        <Alert
          severity="success"
          className="CustomConfirmationBox"
          action={
            props.closeIcon?<IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={props.handleSuccessCloseIcon}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>:undefined
          }
        >
          {props.SuccessMsg}
        </Alert>
      </Collapse>      
    </>
    
  );
};

export default CustomConfirmation;