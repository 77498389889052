import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  location: any;
  // Customizable Area Start
  history?: any;
  classes?: any;
  parent_Id?: any;
  is_notify?: any;
  respData?: any;
  hashTagFound?: any;
  headerKeyFound?: any;
  navLink?: any;
  keywordInTags?: any;
  parentLinkId?: any;
  respHashTagList?: any;
  solKeyFound?: any;
  probKeyFound?: any;
  contextkeyFound?: any;
  postId?: any;
  changeNotification?: () => void;
  changeReportIdea?: () => void;
  handleHide?: () => void;
  ideaStream?: boolean;
  reportIdea?: boolean;
  propTime?: any
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  searchMoreOptions: boolean;
  searchOpen: boolean;
  searchValue: string;
  searchDummyValue: string;
  userName: string;
  hashtag: string;
  keyword: string;
  advSearchResults: any;
  notifications: boolean;
  ideaStream: boolean;
  reportIdea: boolean;
  startDate: any;
  endDate: any;
  openUserSuggestion: boolean;
  userNameList: {
    attributes: {
      user_name: string;
      image: string;
    };
  }[];
  userNameSelectedByUser: string[];
  filterPopular: boolean;
  filterContested: boolean;
  filterMedia: boolean;
  startDay: any;
  startMonth: any;
  startYear: any;
  endDay: any;
  endMonth: any;
  endYear: any;
  monthsValue: any;
  anchorEl: boolean;
  anchorEl1: boolean;
  anchorEl2: boolean;
  isDateError: any;
  isYearError: any;
  searchResultsArray: any;
  currentPage: any;
  totalCount: any;
  posts: any;
  searchViaAdvanceSearch: boolean;
  suggestedUsername: any;
  isLoading: boolean;
  isAlertOpen: boolean;
  alertMessageText: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class AdvancedSearchControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  advSearchResultsCallID: any
  getUserNamesCallID: any
  suggestedUserName: any = []
  fetchMoreDataCallID: any = ""
  notificationOnIdeaCallID: any;
  notificationOffIdeaCallID: any;
  hideIdeaVisibilityCallID: any;
  unhideIdeaVisibilityCallID: any;
  reportIdeaCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      searchMoreOptions: false,
      searchOpen: false,
      searchDummyValue: "",
      searchValue: "",
      userName: "",
      hashtag: "",
      keyword: "",
      advSearchResults: [],
      notifications: true,
      ideaStream: true,
      reportIdea: true,
      startDate: "",
      endDate: "",
      openUserSuggestion: false,
      userNameList: [],
      userNameSelectedByUser: [],
      filterPopular: false,
      filterContested: false,
      filterMedia: false,
      startDay: "",
      startYear: "",
      monthsValue: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
      startMonth: "",
      endDay: "",
      endMonth: "",
      endYear: "",
      isDateError: false,
      isYearError: false,
      anchorEl: false,
      anchorEl1: false,
      anchorEl2: false,
      searchResultsArray: [],
      currentPage: 1,
      totalCount: 100,
      posts: [],
      searchViaAdvanceSearch: false,
      suggestedUsername: "",
      isLoading: false,
      isAlertOpen: false,
      alertMessageText: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.notificationOnIdeaCallID:
            this.notificationOnIdeaResponse(responseJson);
            break;
          case this.notificationOffIdeaCallID:
            this.notificationOffIdeaResponse(responseJson);
            break;
          case this.advSearchResultsCallID:
            this.handleAdvSearchResponse(responseJson);
            break;
          case this.getUserNamesCallID:
            this.handleGetUserNamesResponse(responseJson);
            break;
          case this.fetchMoreDataCallID:
            this.handleFetchMoreResponse(responseJson);
            break;
          case this.hideIdeaVisibilityCallID:
            this.handleHideIdeaVisibilityResponse(responseJson);
            break;
          case this.unhideIdeaVisibilityCallID:
            this.handleUnhideIdeaVisibilityResponse(responseJson);
            break;
          case this.reportIdeaCallID:
            this.handleReportIdeaResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  notificationOnIdeaResponse = (responseJson: any) => {
    if (responseJson.message === 'status is set to true') {
      this.getAdvSearchResults([], "", "", 1)
    }
  }
  notificationOffIdeaResponse = (responseJson: any) => {
    if (responseJson.message === 'status is set to false') {
      this.getAdvSearchResults([], "", "", 1)
    }
  }
  handleAdvSearchResponse = (responseJson: any) => {

    this.setState({ isLoading: false })
    if (!responseJson.errors && responseJson.status !== 500) {
      if (responseJson.accounts) {
        this.props.history.push('/AdvancedSearch', { data: responseJson.accounts })
      } else if (responseJson.data === null) {
        this.setState({ posts: [] }, () => {
          this.props.history.push('/AdvancedSearch', {
            posts: [],
            respData: [],
            totalCount: 0,
            searchValue: "",
            keyword: "",
            hashtag: ''
          })
        });
      }
      else {
        this.setState({
          searchResultsArray: responseJson?.data,
          totalCount: responseJson?.meta?.total_count,
          advSearchResults: responseJson.data,
          posts: responseJson.data
        }, () => {
          this.props.history.push('/AdvancedSearch', {
            posts: responseJson?.data,
            respData: responseJson?.data,
            totalCount: responseJson?.meta?.total_count,
            searchValue: this.state.searchValue || sessionStorage.getItem("barSearchWord") || null,
            keyword: this.state.keyword || sessionStorage.getItem("advKeyword") || null,
            hashtag: this.state.hashtag || sessionStorage.getItem("hashTags") || null,
          })
        })
      }

    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Failed to load Results. Please try again."
      })
    }

  }

  handleGetUserNamesResponse = (responseJson: any) => {

    if (!responseJson.errors && responseJson.status !== 500) {
      if (JSON.stringify(this.state.userNameList) !== JSON.stringify(responseJson.data))
        this.setState({ userNameList: [...responseJson.data] })
    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Something went wrong. Please try again."
      })
    }

  }

  handleFetchMoreResponse = (responseJson: any) => {

    this.setState({ isLoading: false })
    if (!responseJson.errors && responseJson.status !== 500) {
      if (JSON.stringify(this.state.userNameList) !== JSON.stringify(responseJson.data))
        console.log("san this.state.posts 2", this.state.posts);
      this.setState({ posts: [...this.state.posts, ...responseJson.data] })
    }

  }

  handleHideIdeaVisibilityResponse = (responseJson: any) => {

    if (!responseJson.errors && responseJson.status !== 500) {
      this.setState({ ideaStream: false, posts: [], currentPage: 1 }, () => {
        console.log("san this hide posts", this.state.posts);
        this.getAdvSearchResults([], "", "", 1)
      });

    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Failed to hide. Please try again."
      })
    }

  }

  handleUnhideIdeaVisibilityResponse = (responseJson: any) => {
    if (!responseJson.errors && responseJson.status !== 500) {
      console.log("idea unhideIdea");
      this.setState({ posts: [], currentPage: 1 }, () => {
        console.log("san this unhide posts", this.state.posts);
        this.getAdvSearchResults([], "", "", 1)
      });
    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Failed to unhide. Please try again."
      })
    }

  }

  handleReportIdeaResponse = (responseJson: any) => {

    if (!responseJson.errors && responseJson.status !== 500) {
      if (responseJson.message.report_status || responseJson.message === "reported successfully") {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "The iteration has been reported."
        })
      }
      if (responseJson.message === "unreport successfully") {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "The iteration has been unreported."
        })
      }
      this.setState({ posts: [], currentPage: 1 }, () => {
        this.getAdvSearchResults([], "", "", 1)
      });
    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Failed to report. Please try again."
      })
    }

  }

  getSearchString = (suggestedUserName: any, keyword: string, calStartDate: string, calEndDate: string, page: number) => {
    if (sessionStorage.getItem("searchViaAdvanceSearch") === "true") {
      return this.getAdvSearchResultString(suggestedUserName, keyword, calStartDate, calEndDate, page)
    }
    return this.getNormalSearchResultString(page);
  }

  getAdvSearchResultString = (suggestedUserName: any, keyword: string, calStartDate: string, calEndDate: string, page: number) => {
    let hashTagsString = this.covertHashTagsToString();
    let searchNames = suggestedUserName.length > 0 ? suggestedUserName : sessionStorage.getItem("userName");

    return configJSON.advSearchResultEndPoint
      + `?user_name=${searchNames}`
      + `${hashTagsString || sessionStorage.getItem("hashTagsString") || ""}`
      + `&keyword=${keyword || sessionStorage.getItem("advKeyword") || ''}`
      + `&start_date=${calStartDate || sessionStorage.getItem("calStartDate")}`
      + `&end_date=${calEndDate || sessionStorage.getItem("calEndDate")}`
      + `&popular=${this.state.filterPopular || sessionStorage.getItem("filterPopular") || ""}`
      + `&contested=${this.state.filterContested || sessionStorage.getItem("filterContested") || ""}`
      + `&media=${this.state.filterMedia || sessionStorage.getItem("filterMedia") || ""}`
      + `&page=${page}&per_page=${configJSON.PAGE_LIMIT}`
  }

  getNormalSearchResultString = (page: number) => {
    return configJSON.searchResultEndPoint
      + `?keyword=${this.state.searchValue || sessionStorage.getItem("barSearchWord")}`
      + `&user_name=${this.state.searchValue || sessionStorage.getItem("barSearchWord")}`
      + `&page=${page}&per_page=5`
  }

  getAdvSearchResults = (suggestedUserName: string[], calStartDate: string, calEndDate: string, page: number) => {
    this.setState({ isLoading: true });

    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.advSearchResultsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getSearchString(suggestedUserName, this.state.keyword, calStartDate, calEndDate, page)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  loadInfiniteData = (suggestedUserName: string[], searchKeyword: string, calStartDate: string, calEndDate: string, page: number) => {

    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fetchMoreDataCallID = requestMessage.messageId;


    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      this.getSearchString(suggestedUserName, searchKeyword, calStartDate, calEndDate, page)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getUserNames = (str: string) => {

    const header = {
      token: localStorage.getItem("authToken"),
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getUserNamesCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getUserNamesEndPoint + `?user_name=${str}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  notificationOnIdea = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationOnIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsIdeaEndPoint + `?is_notify=true&idea_id=${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  notificationOffIdea = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notificationOffIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsIdeaEndPoint + `?is_notify=false&idea_id=${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  hideIdeaVisibility = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.hideIdeaVisibilityCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hideIdeaEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unhideIdeaVisibility = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.unhideIdeaVisibilityCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.unhideIdeaEndPoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePUT
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  reportIdea = (ideaID: number, status: boolean) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };
    const endpoint = status ? configJSON.reportIdeaEndPoint : configJSON.unReportIdeaEndPoint;
    const methodType = status ? configJSON.exampleAPiMethod : configJSON.MethodTypePUT;

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.reportIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint + `${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      methodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  changeNotification = (isNotify: boolean, id: number) => {
    if (isNotify) {
      this.notificationOffIdea(id)
    } else {
      this.notificationOnIdea(id);
    }
  }

  handleVisibilityFn = (isHidden: boolean, id: number) => this.handleVisibility(isHidden, id)

  handleVisibility = (isHidden: boolean, id: number) => {
    //for visibility.,  we are using the id 
    if (isHidden) {
      this.unhideIdeaVisibility(id);
    } else {
      this.hideIdeaVisibility(id)
    }
  }

  handleReportIdeaFn = (is_reported: boolean, id: number) => this.handleReportIdea(is_reported, id)

  handleReportIdea = (isReported: boolean, id: number) => {
    //for report.,  we are using the id 
    if (isReported) {
      this.reportIdea(id, false)
    } else {
      this.reportIdea(id, true)
    }
  }
  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };


  covertHashTagsToString = () => {
    let hashTagsList: any = [];
    let hashTagsString = ""
    if (this.state.hashtag?.length > 0) {
      let temparr: any = []

      hashTagsList = this.state.hashtag.match(/[A-Z][a-z0-9_-]+/gmi);
      hashTagsList?.length > 0 && hashTagsList.map((each: any, index: number) => {
        return temparr.push(each.slice(0))
      })
      hashTagsList = temparr
    }
    if (hashTagsList?.length > 0) {
      hashTagsList.map((each: any, index: number) => {
        hashTagsString += "&tag_list[]=" + each
      })
    }
    hashTagsString && sessionStorage.setItem("hashTagsString", hashTagsString)
    return hashTagsString;
  }

  covertUserNamesToString = (item: any) => {
    let userName = "";

    if (item.length > 0)
      item.forEach((each: any, index: number) => {
        if (index > 0) {
          userName += " "
        }
        userName += each
      })
    this.setState({ suggestedUsername: userName })
    sessionStorage.setItem("userName", userName)
    return userName
  }


  handleDates = () => {
    if (this.state.startDay && this.state.startMonth && this.state.startYear) {
      this.setState({ startDate: `${this.state.startYear}-${this.state.startMonth}-${this.state.startDay}` })
    }

    if (this.state.endDay && this.state.endMonth && this.state.endYear) {
      this.setState({ endDate: `${this.state.endYear}-${this.state.endMonth}-${this.state.endDay}` })
    }
  }
  handleFetchMoreData = () => this.fetchMoreData();

  fetchMoreData = () => {
    if (this.state.currentPage <= this.state.totalCount / configJSON.PAGE_LIMIT) {/* @ts-ignore */ }
    const searchValueProp = this.props.location?.state?.searchValue;
    {/* @ts-ignore */ }
    const searchKeyword = this.props.location?.state?.keyword || "";
    this.setState({
      searchValue: searchValueProp,
      currentPage: this.state.currentPage + 1,
      isLoading: true
    }, () => {
      this.loadInfiniteData([], searchKeyword, "", "", this.state.currentPage)
    })
  };


  handleAdvanceSearchSubmit = (suggestedUserName: any) => {
    this.setState({
      searchViaAdvanceSearch: true,
      isLoading: true
    }, () => {
      sessionStorage.setItem("searchViaAdvanceSearch", "true");
      sessionStorage.setItem("advKeyword", this.state.keyword);
      sessionStorage.setItem("hashTags", this.state.hashtag);
      sessionStorage.setItem("filterPopular", `${this.state.filterPopular || ""}`);
      sessionStorage.setItem("filterContested", `${this.state.filterContested || ""}`);
      sessionStorage.setItem("filterMedia", `${this.state.filterMedia || ""}`);

      this.setState({ searchOpen: false })
      let calStartDate = "";
      let calEndDate = "";

      if (this.state.startDay && this.state.startMonth && this.state.startYear) {
        calStartDate = `${this.state.startYear}-${+this.state.startMonth + 1}-${this.state.startDay}`;
      }

      if (this.state.endDay && this.state.endMonth && this.state.endYear) {
        calEndDate = `${this.state.endYear}-${+this.state.endMonth + 1}-${this.state.endDay}`
      }

      if (this.state.userName !== "" || this.state.hashtag !== "" || this.state.keyword !== "" || this.state.startDate !== "" || this.state.endDate !== "" || this.state.filterPopular || this.state.filterContested || this.state.filterMedia || calStartDate || calEndDate) {
        sessionStorage.setItem("calStartDate", calStartDate);
        sessionStorage.setItem("calEndDate", calEndDate);
        this.getAdvSearchResults(suggestedUserName, calStartDate, calEndDate, 1);
      }
    })
  }

  handleSearchBarSubmit = () => {
    this.setState({
      searchDummyValue: "",
      searchViaAdvanceSearch: false,
      isLoading: true,
    })
    sessionStorage.setItem("searchViaAdvanceSearch", "false");
    sessionStorage.setItem("barSearchWord", this.state.searchValue);
    this.getAdvSearchResults([], "", "", this.state.currentPage);
  }

  contentHashTags = (context: any, problem: any, solution: any) => {
    let str = context + " " + problem + " " + solution;
    let arr: string[] = [];
    let hashTags: any = str.match(/#[A-Z][a-z0-9_-]+/gmi);
    if (hashTags?.length > 0) {
      hashTags.map((each: any, index: number) => {
        if (index >= 1) {
          console.log("index", index);
        }
        arr.push(each)
      })
      return arr.join("");
    }
    return []
  }

  handleAdvancedSearchIconClick = () => {
    this.setState({ searchOpen: true })
  }



  checkIfDataExists = (username: string): boolean => {
    let flag = false;

    this.suggestedUserName.filter((each: any) => {
      if (each == username) {
        flag = true
      }
    })
    return flag
  }

  handleUserNameChange = (e: any) => {
    this.suggestedUserName = []
    e.map((each: any) => {
      this.suggestedUserName.push(each.attributes.user_name)
    })
  }


  clearAddChip = () => {
  }

  handleClick = () => {
    this.setState({ anchorEl: !this.state.anchorEl })
  };
  handleClick1 = () => {
    this.setState({ anchorEl1: !this.state.anchorEl1 })
  };
  handleClick2 = () => {
    this.setState({ anchorEl2: !this.state.anchorEl2 })
  };

  closeCalendar1 = () => {
    this.setState({ anchorEl1: false })
  };

  closeCalendar2 = () => {
    this.setState({ anchorEl2: false })
  };
  checkValidDay = (day: any) => {
    if (day < 1 || day > 31) {
      this.setState({ isDateError: true })
    } else {
      this.setState({ isDateError: false });
    }
  }
  checkValidYear = (year: any) => {
    if (year < 2000 || year > (new Date().getFullYear())) {
      this.setState({ isYearError: true });
    } else {
      this.setState({ isYearError: false });
    }
  }

  handleStartDate = () => {
    let dayValue = (document.getElementById("calDay") as HTMLInputElement).value

    if (dayValue.length <= 2) {
      this.setState({ startDay: dayValue }, () => {
        this.checkValidDay(this.state.startDay)
      })
    }

  }
  handleStartMonth = (e: any) => {
    this.setState({ startMonth: e.currentTarget.value })
  }
  handleStartYear = () => {
    let startYearValue = (document.getElementById("calYear") as HTMLInputElement).value

    if (startYearValue.length <= 4) {
      this.setState({ startYear: startYearValue }, () => {
        this.checkValidYear(this.state.startYear)
      })
    }

  }


  handleEndDate = () => {
    let endDayValue = (document.getElementById("calDay") as HTMLInputElement).value
    if (endDayValue.length <= 2) {
      this.setState({ endDay: endDayValue }, () => {
        this.checkValidDay(this.state.endDay)
      })
    }

  }
  handleEndMonth = (e: any) => {
    this.setState({ endMonth: e.currentTarget.value })
  }
  handleEndYear = () => {
    let endYearValue = (document.getElementById("calYear") as HTMLInputElement).value
    if (endYearValue.length <= 4)
      this.setState({ endYear: endYearValue }, () => {
        this.checkValidYear(this.state.endYear)
      })
  }
  clearUserName = () => {
    this.suggestedUserName = []
  }

  getDateData = (weekDiff: number, daysDiff: number, dayFormat: string, yearFormat: string) => {
    const weekDifference = weekDiff === 1 ? `${weekDiff} week ago` : `${weekDiff} weeks ago`;
    const moreThanMonth = daysDiff < 7 ? dayFormat : weekDifference;
    return weekDiff < 4 ? moreThanMonth : yearFormat
  }

  /*custom Search Logic starts*/
  onCheckEqual(value1: any, value2: any) {
    return value1 === "Enter" && value2 !== "";
  }

  handleSearchValueInput = (e: any) => {
    this.setState(
      {
        searchValue: this.state.searchValue.length != 0 ? e.target.value.replace(/[!#$&%;]/g, "") : e.target.value.trim().replace(/[!#$%&+;]/g, ""),
        searchDummyValue: e.target.value
      }
    );
  }
  /*custom Search Logic ends*/

  getVariable = (condition: any, value1: any, value2: any) => {
    return condition ? value1 : value2;
  }

  assignState = () => {
    this.setState({ posts: this.props.location?.state?.respData });
  };

  assignStateInRender = () => {
    if (
      this.props?.location?.state?.respData[0]?.id !== this.state.posts[0]?.id ||
      this.props?.location?.state?.respData[1]?.id !== this.state.posts[1]?.id ||
      this.props?.location?.state?.respData[2]?.id !== this.state.posts[2]?.id ||
      this.props?.location?.state?.respData[3]?.id !== this.state.posts[3]?.id ||
      this.props?.location?.state?.respData[4]?.id !== this.state.posts[4]?.id
    ) {
      this.assignState();
    }
  }

  conditionalRenderFun(stateValue: any, children: any, children1: any) {
    return stateValue !== "" ? children : children1;
  }
  checkDependencyDates(variable1: any, variable2: any) {
    if (variable1 != '' && variable2 === '') {
      return false
    } else if (variable1 === '' && variable2 != '') {
      return false
    } else {
      return true;
    }
  }

  isAdvanceSearchValid() {
    let calStartDate = "";
    let calEndDate = "";

    if (this.state.startDay && this.state.startMonth && this.state.startYear) {
      calStartDate = `${this.state.startYear}-${+this.state.startMonth + 1}-${this.state.startDay}`;
    }

    if (this.state.endDay && this.state.endMonth && this.state.endYear) {
      calEndDate = `${this.state.endYear}-${+this.state.endMonth + 1}-${this.state.endDay}`
    }
    return (
      this.suggestedUserName.length > 0 ||
      this.covertHashTagsToString() !== '' ||
      this.state.keyword.trim() !== '' ||
      this.state.filterPopular || this.state.filterMedia || this.state.filterContested
      || this.state.startDay || this.state.endDay
    ) && this.checkDependencyDates(calStartDate, calEndDate);
  }

  isNormalSearchValid() {
    return this.state.searchValue.trim().replace(/#/g, "") === "" ? true : false
  }

  isRenderIfElseCondition(state: any, value1: any, value2: any) {
    return state ? value1 : value2
  }

  handleCloseAlertMessagePopup = () => {
    this.setState({
      isAlertOpen: false,
      alertMessageText: ""
    })
  }

  // Customizable Area End
}