import React from "react";
// Customizable Area Start
//@ts-ignore
import "./AddNewIdea.web.css";
import { Select, MenuItem, Input as MUIInput, TextField, Button } from "@material-ui/core";
import InputAdornment from "@material-ui/core/InputAdornment";
import Tooltip from "@material-ui/core/Tooltip";
import Grid from "@material-ui/core/Grid";
import {
  ContextIcon,
  SolutionIcon,
  ProblemIcon,
  crossIcon,
  logoIcon,
  LockIcon,
  GroupIcon,
  WorldIcon,
  DropDownIcon,
  InfoReactIcon,
  revertIcons
} from "./assets";
import AddNewIdeaController from "./AddNewIdeaController";
import ProtectedRoute from "../../email-account-login/src/ProtectedRoute.web";
import { reloadIcon } from "../../customform/src/assets";
import HyperLinkModal from "./HyperLinkModal.web";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";
//@ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import CustomConfirmation from "../../../components/src/CustomConfirmation";
import { noProfileImg } from "../../user-profile-basic/src/assets";
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";
import CustomTypography from "../../../components/src/CustomTypography";
import CustomMediaDisplay from "../../../components/src/CustomMediaDisplay";
import CustomPlusTooltip from "../../../components/src/CustomPlusTooltip";



const getProfilePic = (contextPic: any) => {
  return (
    contextPic ||
    (localStorage.getItem("profilepic") &&
      localStorage.getItem("profilepic") !== "null"
      ? baseURL + localStorage.getItem("profilepic")
      : null) ||
    noProfileImg
  );
};


const getStyle = (stateValue: any, style1: string, style2: string) => {
  return stateValue ? style1 : style2;
};

// Customizable Area End

export default class AddNewIdea extends AddNewIdeaController {
    constructor(props: any) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start   
    // Customizable Area End

    render() {
        // Customizable Area Start

    const options = [
      {
        id: 1,
        img: GroupIcon,
        text: "Contacts",
      },
      {
        id: 2,
        img: WorldIcon,
        text: "Platform",
      },
      {
        id: 3,
        img: LockIcon,
        text: "Private",
      },
    ];
    const tooltiptext = {
      content: [
        {
          id: 1,
          text:
            "Where and when did you encounter the problem you are trying to solve here? Explain the situation as clearly as you can.",
        },
        {
          id: 2,
          text:
            "It might be useful to attach a photo, map, graph, or video to provide useful insights or an article to provide background information.",
        },
      ],
      problem: [
        {
          id: 1,
          text:
            "What is the problem and why did you encounter it? Try to estimate the impact or frequency of the issue to be solved. (Also explain if it might be urgent or exceptionally important)",
        },
        {
          id: 2,
          text:
            "Alternatively, describe the value of a new opportunity you found and elucidate the specifics of the challenge you are facing.",
        },
      ],
      solution: [
        {
          id: 1,
          text:
            "Which solution would you implement and how would you approach it? Outline the best scenario or your first attempt at developing one.",
        },
        {
          id: 2,
          text:
            "Be specific and constructive in your framing of the solution. Try to identify the key stakeholders and partners your solution requires to work well.",
        },
      ],
    };
    return (
      <ProtectedRoute>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <CustomTypography text="Add new idea" />
        <form className="AddNewIdeaBox test">
          <div className="arrow-left">
            <div className="profileImage">
              <img
                src={getProfilePic(this.context.profile_pic)}
                alt="profilePic"
              />
            </div>
          </div>

          {/* Below box for Title Input */}
          <div
            className="flex-box sm-view addTitleBox"
            style={{ justifyContent: "space-between" }}
          >
            <MUIInput
              id="title"
              name="title"
              placeholder="Type your title here..."
              type="text"
              className={`AddNewIdeaInput text-color-white ${getStyle(
                this.state.TitleText,
                "titleAdded",
                ""
              )}`}
              onChange={this.handleAddIdeaTitleChange}
              value={this.state.TitleText}
              endAdornment={
                this.state.TitleText.length !== 0 ? (
                  <InputAdornment position="end">
                    {this.state.TitleText.length}/44
                  </InputAdornment>
                ) : null
              }
              inputProps={{ maxLength: 44, width: "100%" }}
              disableUnderline={true}
            />

            <Select
              renderValue={() => {
                return (
                  <div className="addNewIdeaSelectText">
                    <img
                      src={this.getImage(this.state.PrivacySelectAddNewIdea)}
                      alt=""
                      className="drop-down-image"
                    />
                    <p>{this.state.PrivacySelectAddNewIdea}</p>
                  </div>
                );
              }}
              value={this.state.PrivacySelectAddNewIdea}
              label="Private"
              className="AddNewIdeaSelect dropdown-padding"
              MenuProps={{ classes: { paper: "work arrow-top" } }}
              onChange={this.addNewideaDropdownFun}
              IconComponent={DropDownIcon}
              disableUnderline
            >
              {options.map((e: { img: any; text: string }) => {
                if (e.text === (this.state.PrivacySelectAddNewIdea as string)) {
                  return false;
                } else {
                  return (
                    <MenuItem
                      key={e.text}
                      value={e.text}
                      className="AddNewIdeaDropDownItem"
                    >
                      <img src={e.img} alt={this.state.PrivacySelectAddNewIdea} className="drop-down-image" />
                      <p className={"SelectMenuItem"}>{e.text}</p>
                    </MenuItem>
                  );
                }
              })}
            </Select>
          </div>

          {/* Below box for Context */}
          <div
            className={`boxSeparator ${getStyle(
              this.state.ContentText,
              "editedIdea",
              ""
            )}`}
          >
            <div
              className={`LabelBox ${getStyle(
                this.state.ErrorInContent,
                "Error",
                ""
              )} ${getStyle(this.state.ErrorInContentMedia, "Error",
                "")}`}
            >
              <label className="form-label flex-box">
                <div className={"img-center"}>
                  <img src={ContextIcon} alt="ContextIcon" />
                </div>
                <p className="no-margin addNewIdeaTitleBoxFont18">CONTEXT</p>
                <Grid item>
                  <Tooltip
                    arrow={true}
                    classes={{ tooltip: "customToolTip" }}
                    title={tooltiptext.content.map((e) => {
                      return (
                        <ul className="toolTipUL" key={e.id}>
                          <li>{e.text}</li>
                        </ul>
                      );
                    })}
                    placement="right-start"
                  >
                    <InfoReactIcon
                      alt="infoIcon"
                      className={getStyle(
                        this.state.ErrorInContent,
                        "errInfoIcon",
                        ""
                      )}
                    />
                  </Tooltip>
                </Grid>
              </label>
              <div style={{ display: "flex" }}>
                {this.state.ContentText.length > 0 ?
                  <Button
                    className="cursorPointer feedHoverUndo"
                    data-title="Undo changes"
                    style={{
                      minWidth: 0,
                      padding: 0,
                      marginRight: "14px",
                      color: "Gray"
                    }}
                    onClick={this.handleContextUndo}
                  >
                    <img
                      src={revertIcons}
                      alt=""
                      color="gray"

                    />
                  </Button> : null}
                {this.checkContextMedia() ? (
                  <CustomPlusTooltip
                    feedTitle={"context"}
                    handleTooltipCloseContent ={this.handleTooltipCloseContent}
                    openPlusDialogContent={this.state.openPlusDialogContent}
                    contextVideoUploadAddnewIdea={this.contextVideoUploadAddnewIdea}
                    handleMediaClickOpen={this.handleMediaClickOpen}
                    imageCropPopup={this.state.imageCropPopup}
                    profileImageCrop={this.profileImageCrop}
                    handleDate={this.handleDate}
                    AddCropImageDailog={this.AddCropImageDailog}
                    setImageDataAddIdea={this.setImageDataAddIdea}
                    ideaPicImageCrop={this.state.ideaPicImageCrop}
                    handleAddIdeaPlusContent={this.handleAddIdeaPlusContent}
                  />
                ) : (
                  <div>
                    <img
                      src={reloadIcon}
                      className="plus-icon"
                      alt="resetIcon"
                      onClick={this.handleCntxtRemoveIcon}
                      onKeyPress={this.handleCntxtRemoveIcon}
                      title="Reset"
                    />
                  </div>
                )}
              </div>
            </div>
            <CustomMediaDisplay
              imagePreview={this.state.contextImagePreview}
              imagePreviewStyle="contextPreviewImage"
              videoUpload={this.state.contextVideoUpload}
              textWithHyperLink={this.state.ContentTextWithHyperLink}
            />

            {/* <Markup content={this.state.ContentText} /> */}
            <TextField
              id="context"
              minRows={1}
              className="form-control"
              classes={{
                root: "height-auto",
              }}
              onChange={this.handleContextTextFun}
              value={this.state.ContentText}
              multiline
              placeholder="Type context here..."
              InputProps={{ disableUnderline: true }}
            />
          </div>

          {/* Below box for Problem and solution*/}
          <div className="flex-box justify-content smbottom">
            <div
              className={`smaller-input ${getStyle(
                this.state.ProblemText,
                "editedIdea",
                ""
              )}`}
            >
              <div
                className={`LabelBox ${getStyle(
                  this.state.ErrorInProblem,
                  "Error",
                  ""
                )} ${getStyle(this.state.ErrorInProblemMedia, "Error",
                  "")}`}
              >
                <label className="form-label flex-box">
                  <div className={"img-center"}>
                    <img src={ProblemIcon} alt="ProblemIcon" />
                  </div>
                  <p className="no-margin addNewIdeaTitleBoxFont18">PROBLEM</p>
                  <Grid item>
                    <Tooltip
                      arrow={true}
                      classes={{ tooltip: "customToolTip" }}
                      title={tooltiptext.problem.map((e) => {
                        return (
                          <ul className="toolTipUL" key={e.id}>
                            <li>{e.text}</li>
                          </ul>
                        );
                      })}
                      placement="right-start"
                    >
                      <InfoReactIcon
                        alt="infoIcon"
                        className={getStyle(
                          this.state.ErrorInProblem,
                          "errInfoIcon",
                          ""
                        )}
                      />
                    </Tooltip>
                  </Grid>
                </label>
                <div style={{ display: "flex" }}>
                  {this.state.ProblemText.length > 0 ?
                    <Button
                      className="cursorPointer feedHoverUndo"
                      data-title="Undo changes"
                      style={{
                        minWidth: 0,
                        padding: 0,
                        marginRight: "14px",
                        color: "Gray"
                      }}
                      onClick={this.handleProblemUndo}
                    >
                      <img
                        src={revertIcons}
                        alt=""
                        color="gray"

                      />
                    </Button> : null}
                  {this.checkProblemMedia() ? (
                    <CustomPlusTooltip
                    feedTitle={"problem"}
                    handleTooltipCloseContent ={this.handleAddIdeaTooltipCloseProblem}
                    openPlusDialogContent={this.state.openPlusDialogProblem}
                    contextVideoUploadAddnewIdea={this.contextVideoUploadAddnewIdea}
                    handleMediaClickOpen={this.handleMediaClickOpen}
                    imageCropPopup={this.state.imageCropPopup}
                    profileImageCrop={this.profileImageCrop}
                    handleDate={this.handleDate}
                    AddCropImageDailog={this.AddCropImageDailog}
                    setImageDataAddIdea={this.setImageDataAddIdea}
                    ideaPicImageCrop={this.state.ideaPicImageCrop}
                    handleAddIdeaPlusContent={this.handleAddIdeaPlusProblem}
                    />
                  ) : (
                    <div>
                      <img
                        src={reloadIcon}
                        className="plus-icon"
                        alt="resetIcon"
                        onClick={this.handleProbRemoveIcon}
                        title="Reset"
                        onKeyPress={this.handleProbRemoveIcon}
                      />
                    </div>
                  )}
                </div>
              </div>

              <CustomMediaDisplay
                imagePreview={this.state.problemImagePreview}
                imagePreviewStyle="previewImage"
                videoUpload={this.state.problemVideoUpload}
                textWithHyperLink={this.state.ProblemTextWithHyperLink}
              />

              <TextField
                id="problem"
                minRows={1}
                className="form-control"
                classes={{
                  root: "height-auto",
                }}
                onChange={this.problemTextFun}
                value={this.state.ProblemText}
                multiline
                placeholder="Type problem here..."
                InputProps={{ disableUnderline: true }}
              />
            </div>
            <div
              className={`smaller-input ${getStyle(
                this.state.SolutionText,
                "editedIdea",
                ""
              )}`}
            >
              <div
                className={`LabelBox ${getStyle(
                  this.state.ErrorInSolution,
                  "Error",
                  ""
                )} ${getStyle(this.state.ErrorInSolutionMedia, "Error",
                  "")}`}
              >
                <label className="form-label flex-box">
                  <div className={"img-center"}>
                    <img src={SolutionIcon} alt="SolutionIcon" />
                  </div>
                  <p className="no-margin addNewIdeaTitleBoxFont18">SOLUTION</p>
                  <Grid item>
                    <Tooltip
                      arrow={true}
                      classes={{ tooltip: "customToolTip" }}
                      title={tooltiptext.solution.map((e) => {
                        return (
                          <ul className="toolTipUL" key={e.id}>
                            <li>{e.text}</li>
                          </ul>
                        );
                      })}
                      placement="right-start"
                    >
                      <InfoReactIcon
                        alt="infoIcon"
                        className={getStyle(
                          this.state.ErrorInSolution,
                          "errInfoIcon",
                          ""
                        )}
                      />
                    </Tooltip>
                  </Grid>

                  <div />
                </label>
                <div style={{ display: "flex" }}>
                  {this.state.SolutionText.length > 0 ?
                    <Button
                      className="cursorPointer feedHoverUndo"
                      data-title="Undo changes"
                      style={{
                        minWidth: 0,
                        padding: 0,
                        marginRight: "14px",
                        color: "Gray"
                      }}
                      onClick={this.handleSolutionUndo}
                    >
                      <img
                        src={revertIcons}
                        alt=""
                        color="gray"

                      />
                    </Button> : null}
                  {this.checkSolutionMedia() ? (

                    <CustomPlusTooltip
                      feedTitle={"solution"}
                      handleTooltipCloseContent ={this.handleAddIdeaTooltipCloseSolution}
                      openPlusDialogContent={this.state.openPlusDialogSolution}
                      contextVideoUploadAddnewIdea={this.contextVideoUploadAddnewIdea}
                      handleMediaClickOpen={this.handleMediaClickOpen}
                      imageCropPopup={this.state.imageCropPopup}
                      profileImageCrop={this.profileImageCrop}
                      handleDate={this.handleDate}
                      AddCropImageDailog={this.AddCropImageDailog}
                      setImageDataAddIdea={this.setImageDataAddIdea}
                      ideaPicImageCrop={this.state.ideaPicImageCrop}
                      handleAddIdeaPlusContent={this.handleAddIdeaPlusSolution}
                      />
                  ) : (
                    <div>
                      <img
                        src={reloadIcon}
                        className="plus-icon"
                        alt="Reset ICon"
                        onClick={this.handleSolRemoveIcon}
                        onKeyPress={this.handleSolRemoveIcon}
                        title="Reset"
                      />
                    </div>
                  )}
                </div>
              </div>

              <CustomMediaDisplay
                imagePreview={this.state.solutionImagePreview}
                imagePreviewStyle="previewImage"
                videoUpload={this.state.solutionVideoUpload}
                textWithHyperLink={this.state.SolutionTextWithHyperLink}
              />

              <TextField
                id="solution"
                minRows={1}
                className={"form-control"}
                classes={{
                  root: "height-auto",
                }}
                onChange={(e) => {
                  this.setState(
                    { SolutionText: e.target.value.substring(0, 440) },
                    () => {
                      this.ErrorCheckInSolutionAddIdea();
                    }
                  );
                }}
                value={this.state.SolutionText}
                multiline
                placeholder="Type solution here..."
                InputProps={{ disableUnderline: true }}
              />
            </div>
          </div>

          <div className="CustomErrorOverwrite">
            <CustomErrorAlert
              isError={this.state.openSnackBarError}
              errorMsg={
                "Text cannot contain any of the following characters: { } [ ] | "
              }
              handleError={this.onCustomAlertCloseButtonClick}
              closeBtnDisabled={true}
            />
            <CustomErrorAlert
              isError={this.state.totalFileError}
              errorMsg={"Combined file size should be less than 50MB"}
              handleError={this.onCustomAlertCloseButtonClick}
              closeBtnDisabled={true}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <CustomConfirmation
                isSuccess={this.state.ideaAdded} //Overwrite: this.props.displayConfirmMsg this was making the confirmation appear on all ideas
                SuccessMsg={"Idea Added!"}
                closeIcon={true}
                handleSuccessCloseIcon={this.handleSuccessCloseIcon}
              />
            </div>
            <CustomErrorAlert
              isError={this.state.titleValidation}
              errorMsg={"Please add title."}
              handleError={this.onCustomAlertCloseButtonClick}
              closeBtnDisabled={true}
            />
            <CustomErrorAlert
              isError={this.state.problemValidation}
              errorMsg={"Either problem or solution are mandatory."}
              handleError={this.onCustomAlertCloseButtonClick}
            />
            <CustomErrorAlert
              isError={this.state.ExtentionError}
              errorMsg={
                "Only files with the following extensions are allowed: .png, .gif, .jpg, .jpeg, .mp4"
              }
              handleError={this.onCustomAlertCloseButtonClick}
              closeBtnDisabled={true}
            />
          </div>
          <div className="center">
            {/* Use Avatar can be implemented for below */}
            <Button
              onClick={this.crossButtonHandle}
              style={{ minWidth: 0, padding: 0 }}
            >
              <img
                src={crossIcon}
                alt=""
                className="footer-icon"
                title="Clear everything"
              />
            </Button>
            <Button
              onClick={this.submitIdeaPreCheck}
              style={{ minWidth: 0, padding: 0 }}
            >
              <img
                src={logoIcon}
                alt=""
                className="footer-icon"
                title="Submit"
              />
            </Button>
          </div>
        </form>

        {/* Model for HyperLink */}

        {this.state.openHyperLinkDialoge && (
          <HyperLinkModal
            handleClose={this.handleHyperLinkClose}
            handleClickOpen={this.handleMediaClickOpen}
            submitHyperLink={this.submitHyperLink}
            title={this.state.HyperLinkModalTitle}
            hyperLinkError={this.state.hyperLinkError}
            onHyperlinkErrorClose={this.onHyperlinkErrorClose}
          />
        )}

        {/* </div> */}
      </ProtectedRoute>
    );
        // Customizable Area End
    }

    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start