import React from "react";
import {
  Button,
  Dialog,
  DialogProps,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./TermsAndConditions.web.css";
export const configJSON = require("./config");

export default function TermsAndConditions(props:any) {
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");

  const handleClickOpen = (scrollType: DialogProps["scroll"]) => () => {
    setOpen(true);
    setScroll(scrollType);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <div>
   <Button onClick={handleClickOpen("paper")} className="termsText2" style={{minWidth:0,
      fontFamily: "Jost-Regular",
      fontWeight: 800,
      fontSize: "12px",
      lineHeight: "17px",
      color: "#f1f2f2",
      textTransform:'none'
      }}>
      <a className="termsText2">
        {configJSON.labelLegalTermCondition}
      </a>
      </Button>

      <Dialog
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        className="termsPopUpContainer"
      >
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className="close-icon"
        >
          <CloseIcon />
        </IconButton>

        <DialogTitle id="scroll-dialog-title">
          {" "}
          {configJSON.labelLegalTermCondition}
        </DialogTitle>
        <DialogContent dividers={scroll === "paper"}>
          <DialogContentText
            id="scroll-dialog-description"
            ref={descriptionElementRef}
            tabIndex={-1}
          >
            {props.respData && props.respData.length > 0 ? (
                <p className="termsAndContidionContent"
                    dangerouslySetInnerHTML={{
                      __html: props?.respData
                    }}>
                </p>):null }
           
          </DialogContentText>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            {configJSON.labelCancel}
          </Button>
          <Button onClick={handleClose} color="primary">
            {configJSON.labelAccept}
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
