// Customizable Area Start
import React, { useState } from "react";
import "../../blocks/AdvancedSearch/src/AdvancedSearch.css";
import {
  contextIcon,
  problemIcon,
  solutionIcon,
  noProfileImg,
  ForwardIcon
} from "../../blocks/AdvancedSearch/src/assets"
//@ts-ignore
import { baseURL } from "../../framework/src/config.js";
import moment from "moment";
import CustomIconButton from "./CustomIconButton";

moment.locale("de");

export const ImgDescBox = (props: any) => {
  const {
    keyType,
    keyFound,
    boxIcon,
    txtDesc,
    descOpen,
    handleViewAllText
  } = props;

  return (
    <>
      <div
        className={`advImgDescBox context ${keyFound ? "advSearchFound" : ""} ${descOpen ? "advImgDescBoxDescOpen" : ""
          }`}
        data-testid="advImgDescBox"
        onClick={handleViewAllText}
      >
        <div className="advImgDescDivider">
          <img src={boxIcon} className="advImgDescImg" />
        </div>

        {descOpen ? (
          <div className={`advImgDescKeyType`}>{keyType}</div>
        ) : (
          <div
            // className={`advImgDescText ${txtDesc.length > 70 ? "searchItemTextFade" : ""}`}
            className="advImgDescText"
          >
            {txtDesc}
          </div>
        )}
      </div>
      {descOpen && <div className="advDetailedDesc">{txtDesc}</div>}
    </>
  );
};

const AdvanceSearchBox = (props: any) => {
  const { respData, respHashTagList, hashTagFound } = props;
  const [contextOpen, setContextOpen] = useState(false);
  const [problemOpen, setProblemOpen] = useState(false);
  const [solutionOpen, setSolutionOpen] = useState(false);
  const [hoverForwadOn, setHoverForwardOn] = useState(false);
  const [hoverNotifyOn, setHoverNotifyOn] = useState(false);
  const [hoverVisibiltyOn, setHoverVisibiltyOn] = useState(false);
  const [hoverReportOn, setHoverReportOn] = useState(false);

  const handleViewAllContext = () => {    
    setContextOpen(!contextOpen);
    setProblemOpen(false);
    setSolutionOpen(false);
  };
  const handleViewAllProblem = () => {    
    setContextOpen(false);
    setProblemOpen(!problemOpen);
    setSolutionOpen(false);
  };
  const handleViewAllSolution = () => {    
    setContextOpen(false);
    setProblemOpen(false);
    setSolutionOpen(!solutionOpen);
  };

  return (
    <div className="advSearchWrapper">
      <div
        className={`advSearchBox ${props.headerKeyFound ? "titleFound" : ""}`}
      >
        <div className="advTitle"> {respData.title}
          {/* --- {props?.postId} */}
        </div>
        {respHashTagList.length > 0 && (
          <div className="advHashTagBox">
            {respHashTagList.map((tag: any, index: any) => {
              return (
                <span
                  key={index}
                  className={`advHashTagText ${hashTagFound(tag) ? "advTagFound" : ""
                    }`}
                >{`#${tag}`}</span>
              );
            })}
          </div>
        )}

        <ImgDescBox
          keyType="context"
          keyFound={props.contextkeyFound}
          boxIcon={contextIcon}
          txtDesc={respData.idea_context.data.attributes.description}
          descOpen={contextOpen}
          handleViewAllText={handleViewAllContext}
        />
        <ImgDescBox
          keyType="Problem"
          keyFound={props.probKeyFound}
          boxIcon={problemIcon}
          txtDesc={respData.idea_problem.data.attributes.description}
          descOpen={problemOpen}
          handleViewAllText={handleViewAllProblem}
        />
        <ImgDescBox
          keyType="Solution"
          keyFound={props.solKeyFound}
          boxIcon={solutionIcon}
          txtDesc={respData.idea_solution.data.attributes.description}
          descOpen={solutionOpen}
          handleViewAllText={handleViewAllSolution}
        />

        <div className="advProfile">
          <img
            src={
              respData.profile_details.data.attributes.image === null
                ? noProfileImg
                : `${baseURL}${respData.profile_details.data.attributes.image}`
            }
            className={`searchProfilePic ${props.respData.profile_details.data.attributes.image === null
              ? "noSearchProfilePic "
              : ""
              }`}
          />
          <span className="advSearchProfileName">
            {props.respData.profile_details.data.attributes.user_name}
          </span>
          <span className="searchTime">{props.propTime}</span>
        </div>
      </div>
      <div className="advsearchBtnWrapper">
        <a href={`/SharePost/${props.navLink}`} style={{ textDecoration: 'none', width: "100%" }} rel="noopener noreferrer">
          <CustomIconButton
            classNameStyle="firstCustomIconBtn"
            buttonIcon={ForwardIcon}
            handleOnMouseEnter={() => setHoverForwardOn(true)}
            handleOnMouseLeave={() => setHoverForwardOn(false)}
            hoverEnabled={hoverForwadOn}
            hoverText="Go to iteration"
          />
        </a>
        <CustomIconButton
          handleOnClick={props.notificationHandleOnClick}
          handleOnMouseEnter={() => setHoverNotifyOn(true)}
          handleOnMouseLeave={() => setHoverNotifyOn(false)}
          buttonIcon={props.notificationButtonIcon}
          hoverEnabled={hoverNotifyOn}
          hoverText={props.notificationHoverText}
        />

        <CustomIconButton
          handleOnClick={props.visibilityHandleOnClick}
          handleOnMouseEnter={() => setHoverVisibiltyOn(true)}
          handleOnMouseLeave={() => setHoverVisibiltyOn(false)}
          buttonIcon={props.visibilityButtonIcon}
          hoverEnabled={hoverVisibiltyOn}
          hoverText={props.visibilityHoverText}
        />

        <CustomIconButton
          handleOnClick={props.reportHandleOnClick}
          handleOnMouseEnter={() => setHoverReportOn(true)}
          handleOnMouseLeave={() => setHoverReportOn(false)}
          buttonIcon={props.reportButtonIcon}
          hoverEnabled={hoverReportOn}
          hoverText={props.reportHoverText}
        />
      </div>
    </div>
  );
};

export default AdvanceSearchBox;
export {AdvanceSearchBox}
// Customizable Area End