import React from "react";
import { Box } from "@material-ui/core";
import "../../postcreation/src/PopUpMenu.web.css";
import "./ActivityFeed.css";

const FeedDropDown = (props: any) => {
  const defaultFilterOptions = [
    "default",
    "recent",
    "popular",
    "contested",
    "promoted",
    "library",
    "saved",
  ];
  const libraryFilterOptions = ["popular", "contested", "promoted", "library"];
  const savedFilterOptions = ["popular", "contested", "promoted", "saved"];
  const iterationListOptions = ["default", "recent", "popular", "contested", "promoted"];


  let filterOptions;
  switch (props.feedOptions) {
    case "savedOptions":
      filterOptions = savedFilterOptions;
      break;
    case "libraryOptions":
      filterOptions = libraryFilterOptions;
      break;
    case "iterationListOptions":
      filterOptions = iterationListOptions;
      break;
    default:
      filterOptions = defaultFilterOptions;
      break;
  }


  return (
    <>
      <Box component="span" m={1} className="box-body">
        <Box
          className="title-box"
          id="title-feedFilter"
          onClick={() => { }}
          style={{ flexDirection: "column", textTransform: "uppercase" }}
        >
          {filterOptions.map((item: string, index: number) => {
            if (props.feedFilter !== item) {
              return (
                <Box
                  key={index}
                  onClick={() => {
                    props.handleFeedFilter(item);
                  }}
                  className={"feedFilterDropDownBox"}
                >
                  <p className="menu-title">{item}</p>
                  {index === 4 ? <p className="addDividerDropdown"></p> : null}
                </Box>
              );
            }
          })}
        </Box>
      </Box>
    </>
  );
};

export default FeedDropDown;