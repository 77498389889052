import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import "./PopUpMenu.web.css";
import AddNewIdeaController from "./AddNewIdeaController";
import { ImageLogo, VideoLogo, HyperlinkLogo } from "./assets";
import CropEasy from "../../user-profile-basic/src/userProfileComponent/CropEasy";
// Customizable Area End

export default class PopUpMenu extends AddNewIdeaController {
  constructor(props: any) {
    super(props);
    // Customizable Area Start
    // this.receive = this.receive.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start
   handleVideoFun = (e:any) => {
    this.props.contextImageUpload(e, this.props.title)
   }
   handleCropImage = (img:any,file:any) => this.props.profileImageCrop(img,file,this.props.title)
   handleImageFun =(e:any) =>  this.props.setImageData(e,this.props.title)
   handleHYperLinkFun = () => {this.props.handleHyperLink(this.props.title)}
  // Customizable Area End

  render() {
    // Customizable Area Start
    const ImageInputRef = React.createRef<HTMLInputElement>();
    const VideoInputRef = React.createRef<HTMLInputElement>();
    const handleImageRef = () => { ImageInputRef.current?.click()}
    const handleVideoRef = () => { VideoInputRef.current?.click()}
    return (
      <>
        <Box component="span" m={1} className="box-body">
          <div data-testid="imgContext" className="title-box" onClick={ handleImageRef}>
            <img src={ImageLogo} alt="" className="title-icons" 
            onClick={this.handleImageFun}
            />
            <p className="menu-title">Image</p>
            <input
              data-testid = "txtInputimg"
              accept=".png, .jpg, .jpeg"
              id="inputinfeedback"
              type="file"
              ref={ImageInputRef}
              onChange={this.handleImageFun}
            />
          </div> 
          {this.props.imageCropPopup && (
                    <CropEasy
                      modelTitle={"Edit Photo"}
                      imgUrl={
                        this.props.ideaPicImageCrop ||
                        ImageLogo
                      }
                      handleImage={this.handleCropImage}
                      handleCloseDailog={this.props.AddCropImageDailog}
                      navigation={undefined}
                      id={""}
                      handleDate={this.props.handleDate}
                      day={undefined}
                      month={undefined}
                      year={undefined}
                      dayErr={false}
                      monthErr={false}
                      yearErr={false}
                      errorMsg={undefined}
                      img={undefined}
                    />
                  )}
          <div className="title-box" onClick={handleVideoRef}>
            <img src={VideoLogo} alt="" className="title-icons" />
            <p className="menu-title">Video</p>
            <input
              data-testid = "txtInputvideo"
              accept="video/*"
              id="inputinfeedback"
              type="file"
              ref={VideoInputRef}
              onChange={this.handleVideoFun}
            />
          </div>
          <div className="title-box" data-testid="txtInputhyperlink" onClick={this.handleHYperLinkFun}>
            <img src={HyperlinkLogo} alt="" className="title-icons" />
            {/* <YouTubeIcon className="title-icons"/> */}
            <p className="menu-title">Youtube</p>
          </div>
        </Box>
      </>
    );
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
// Customizable Area Start
