import React from "react";
// Customizable Area Start
import {
  Checkbox,
  Button,
  FormControlLabel,
} from "@material-ui/core";
import { Row, FormGroup, Col, Label } from "reactstrap";
import { Link } from "react-router-dom";
import EmailAccountLoginController from "./EmailAccountLoginController.web";
import "./EmailAccountLogin.web.css";
import { Logo } from "./assets";
import CustomPassword from "../../../components/src/CustomPassword";
import CustomInput from "../../../components/src/CustomInput";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";
//@ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";

const configJSON = require("./config");

export default class EmailAccountLogin extends EmailAccountLoginController {
  render() {
    return (
      <>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <section className="mainContainerlogin_card">
          <img src={Logo} className="logo" alt="logo" />
          <div className="loginContainer LoginErrorBox">
            <h2 className="logInText">{configJSON.labelLoginTitle}</h2>
            <CustomErrorAlert
              isError={this.state.isLoginError}
              handleError={this.handleCloseError}
              errorMsg={this.state.LoginErrorMsg}
            />
            <FormGroup
              className={`loginFormInputFeild emailFeild ${
                this.state.isEmailError ? `inputError` : `form-group`
              }`}
            >
              <CustomInput
                data-testid="txtInputEmail"
                id="txtInputEmail"
                placeholder={configJSON.placeHolderEmail}
                type="text"
                handleInputChange={this.handleEmailChange}
                value={this.state.email}
                isError={this.state.isEmailError}
                handleError={this.handleEmailError}
                errorMsg={configJSON.errorEmailNotValid}
              />
            </FormGroup>
            <FormGroup
              className={`loginFormInputFeild passwordFeild ${
                this.state.isPasswordError ? `inputError` : ``
              }`}
              style={{ marginBottom: "10px !important" }}
            >
              <CustomPassword
                data-testid="txtInputPassword"
                id="txtInputPassword"
                placeholder={configJSON.placeHolderPassword}
                value={this.state.password}
                handlePasswordChange={this.handlePasswordChange}
                pressEnter={this.handleSubmit}
                isError={this.state.isPasswordError}
                errorMsg={configJSON.errorPasswordNotValid}
                handleError={this.handlePasswordError}
              />
          
            </FormGroup>
            <div className="loginLinksBlock">
              <Link
                data-testid="forgetPasswordTest"
                to="/ForgotPassword"
                className="forgetPasswordLink"
              >
                {configJSON.labelForgotPassword}
              </Link>
              {/* <Label onClick={this.handleModal} style={{cursor:'pointer'}}>{configJSON.labelForgotPassword}</Label> */}
              <FormControlLabel
                control={<Checkbox />}
                className="form-label rememberMeCheckbox"
                value={this.state.checkedRememberMe}
                checked={this.state.checkedRememberMe}
                label={configJSON.labelRememberMe}
                labelPlacement="end"
                onClick={this.handleRememberMeValue}
              />
            </div>
            <div className="submitButtonLogin ">
              <Button data-testid="submitBtnId" onClick={this.handleSubmit} className="loginBtn">
                {configJSON.btnTxtLogin}
              </Button>
            </div>
            <div className="loginTextsignUp">
              <Label>
                {configJSON.labelNoAccount}
                <span>
                  <Link
                    to="/EmailAccountRegistration"
                    className="signUphyperLink"
                  >
                    {configJSON.labelSignUp}
                  </Link>
                </span>
              </Label>
            </div>
          </div>
          <Row className="loginCopyRightText">
            <Col md={12} className="copyrightTextLogin">
              <Label>{configJSON.labelCopyRight}</Label>
            </Col>
          </Row>
        </section>
      </>
    );
  }
}
// Customizable Area End
