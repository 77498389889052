// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { imgBell } from "./assets";
import { WithStyles } from "@material-ui/core/styles";
import { styles } from "../../../components/src/WebHeader/NotificationPopUp";

export const configJSON = require("./config");

export interface Props extends WithStyles<typeof styles> {
  navigation: any;
  id: string;
  friendRequest?: any;
  friendRequestUpdate?: any;
  closePopUP?: any;
  history?: any;
  isPopup?:any;
  parent_id?:any;
  popUp?:any;
  is_notify?:any;
  ideaBoxnotify?:any;
  handleUserNotication?:() => void;
  handleIdeaBoxNotification?:()=>void;
  makeNotificationAsRead?:() => void;
  isNotiPopUp?:any;

  notificationDetail?:any;
  deleteNotifications?:any;
  isNotify?:any;
  isIdeaBox?:any;
  getPendingRequests?:any;
}

interface S {
  data: any[];
  selectedData: any;
  token: any;  
  pendingRequests:any;
  openVerticalDotsMenu: boolean;
  isLoading: boolean;
  notifications: any[];
  unreadNotifications: any[];
  isUserNotification:boolean;
  read:boolean;
  userNotification:boolean;
  ideaBoxnotification:boolean;
  isVerticalDotsOpen:boolean;
  date:any;
  friendRequest:boolean;
  isThreeDotsOpen:boolean;
}

interface SS {
  id: any;
}

class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  markAsReadCallId: string = "";
  friendRequestUpdateCallID: string = "";
  makeReadNotificationApiCallID: string = "";
  pendingRequestsCallID: any;
  NotificationsCallID: any;
  deleteNotificationsCallID: any;
  notifyOnIdeaCallID:any;

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      data: [],
      selectedData: null,
      token: "",
      pendingRequests:[],
      openVerticalDotsMenu: false,
      isLoading: false,
      notifications: [],
      unreadNotifications: [],
      isUserNotification:false,
      read:false,
      userNotification:false,
      ideaBoxnotification:false,
      isVerticalDotsOpen:false,
      date:"",
      friendRequest:false,
      isThreeDotsOpen:false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  async receive(from: string, message: Message) {
    if (message.id != getName(MessageEnum.RestAPIResponceMessage)) {
      return;
    }

      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
    
      // friend Request Update Api
        if (apiRequestCallId === this.friendRequestUpdateCallID) {
          if (this.checkApiResponse(responseJson)) {
            console.log("frindRequest update")
          } else{
            this.showAlert(
              "Unable to Process Request",
              "Something went wrong. Please try again"
            );
          }
          window.location.reload();
           
          }
        
        if (apiRequestCallId === this.pendingRequestsCallID) {
          if (this.checkApiResponse(responseJson)) {
            this.setState({
              pendingRequests: responseJson.data,
            });
          } else {
            this.apiErrorsHandle(responseJson.errors[0].token)
          }
          // this.setState({
          //   isLoading: false
          // })
        }
        if (apiRequestCallId === this.NotificationsCallID) {
          this.setState({isLoading:true})
          if (this.checkApiResponse(responseJson) || responseJson.errors[0].message == "No notification found.") {
            this.setState({
              notifications: responseJson?.data || [],
              isLoading: false
            });
          
          } else{
            this.apiErrorsHandle(responseJson.errors[0].token)
            this.setState({
              isLoading: false
            })
          }
          
        }
    // }
  }
  iconBellProps = {
    source: imgBell,
  };
  checkApiResponse(response:any){
    return !response.errors && response.status !== 500
  }

  apiErrorsHandle(responseJsonErrors:any){
    if (responseJsonErrors.errors[0].token) {
      this.showAlert("Failed", "Please login again. Redirecting...");
      setTimeout(() => {
        this.props.navigation.navigate("EmailAccountLogin");
      }, 2000);
    } else {
      this.showAlert(
        "Failed to load contacts",
        "Something went wrong. Please try again"
      );
    }
    
  }
  notifyOnIdea = (ideaID: number,isNotify:boolean) => {
    let token = ""
    if (typeof window !== 'undefined') {
      // @ts-ignore
      token = localStorage.getItem("authToken") 
    }
    const header = {
      'token': token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notifyOnIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userNotificationEndPoint + `?status=${!isNotify}&notified_account_id=${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  notifyIdeaBox = (ideaID: number,isNotify:boolean) => {
    let token = ""
    if (typeof window !== 'undefined') {
      // @ts-ignore
      token = localStorage.getItem("authToken") 
    }
    const header = {
      'token': token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.notifyOnIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.notificationsIdeaBoxEndPoint + `?is_notify=${!isNotify}&idea_id=${ideaID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPendingRequests = () => {
    this.setState({
      isLoading: true
    })
    let token = ""
    if (typeof window !== 'undefined') {
      // @ts-ignore
      token = localStorage.getItem("authToken") 
    }
    const header = {
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.pendingRequestsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.pendingRequestEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getNotifications() {
    this.setState({
      isLoading: true
    })
    let token = ""
    if (typeof window !== 'undefined') {
      // @ts-ignore
      token = localStorage.getItem("authToken") 
    }
    const header = {
      token: token

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.NotificationsCallID = requestMessage.messageId;

    if(window.location.pathname.includes("Notifications")){
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.NotificationEndpoint
        );
      }else{
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.NotificationEndpoint + '?page=1&per_page=10'
        );
      }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDataMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteNotifications(id:number) {
    let abc;
    let token = ""
    if (typeof window !== 'undefined') {
      // @ts-ignore
      token = localStorage.getItem("authToken") 
    }
    const header = {
      token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.NotificationEndpoint + `/${id}`
    );
      

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "DELETE"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  markAsRead(id: number) {
    const markAsReadMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.markAsReadCallId = markAsReadMsg.messageId;

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.endPoint}/${id}`
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify({
        "Content-Type": configJSON.apiContentType,
        token: this.state.token ? this.state.token : "",
      })
    );

    markAsReadMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(markAsReadMsg.id, markAsReadMsg);
  }

  friendRequestUpdate = (ID: number, status: string) => {
    let token = ""
    if (typeof window !== 'undefined') {
      // @ts-ignore
      token = localStorage.getItem("authToken") || ""
    }
    const header = {
      token: token,
      "Content-Type": "application/json",
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.friendRequestUpdateCallID = requestMessage.messageId;

    const httpBody = {
      data: {
        status: status,
      },
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.friendRequestUpdateEndPoint + `${ID}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.markAsReadMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleVeticalDotsTooltipClose = () => {
    if (this.state.openVerticalDotsMenu)
      this.setState({ openVerticalDotsMenu: false });
  };
  handleVeticalDotsTooltipOpen = () => {
      this.setState({ openVerticalDotsMenu: true });
  };
  
  makeNotificationAsRead = (ID:any) => {
    let token = ""
    if (typeof window !== 'undefined') {
      // @ts-ignore
      token = localStorage.getItem("authToken") 
    }
    const header = { token: token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.makeReadNotificationApiCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
     `bx_block_notifications/notifications/` + `${ID}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PATCH"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  handleUserNotication =() => {
    this.setState({userNotification: !this.state.userNotification})
    this.props?.isNotify(this.props.notificationDetail?.attributes?.created_by?.data?.id,this.state.userNotification)
  }
  handleIdeaBoxNotification =() =>{
    this.setState({ideaBoxnotification:!this.state.ideaBoxnotification})
    this.props.isIdeaBox(this.props.notificationDetail?.attributes?.parent_id,this.state.ideaBoxnotification);
 }
}

//@ts-ignore
export default NotificationsController

// Customizable Area End