//@ts-nocheck

export { ReactComponent as InfoIcon } from "./assets/infoIcon.svg";
export {ReactComponent as Union} from "./assets/Union.svg";
export {ReactComponent as feedbackIcon } from "./assets/icon_extra_text.svg";
export {ReactComponent as Password} from "./assets/Group.svg";
export {ReactComponent as FeedBackSvg} from "./assets/icon_feedback.svg";
export {ReactComponent as WebAddIconSvg} from "../src/WebHeader/images/icon_add.svg";
export {ReactComponent as NotificationsIconSvg} from "./assets/icon_notification.svg";
export {ReactComponent as SettingsIcon} from "../src/WebHeader/images/icon_settings.svg";
export {ReactComponent as GroupsIcon} from "../src/WebHeader/images/icon_groups.svg";

export const noProfileImg = require("./assets/noProfile.svg");
export const notificationsIcon = require("./assets/icon_notification.svg");
export const personIcon = require("./assets/Person.svg");
export const privacyIcon = require("./assets/Privacy.svg");
export const chatIcon = require("./assets/chat.svg");
export const globeIcon = require("./assets/Globe.svg");
export const WebAddIcon = require("../src/WebHeader/images/icon_add.svg");
