Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.MethodTypePUT = "PUT";
exports.MethodTypeDELETE = "DELETE";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.MethodTypePOST = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ActivityFeed";
exports.labelBodyText = "ActivityFeed Body";
exports.recentEndPoint = "recent_ideas";
exports.defaultEndPoint = "default_ideas";
exports.friendRequestSentEndPoint = "bx_block_request_management/requests";
exports.removeFriendRequestEndPoint = "unsend_request/";
exports.promotedIdeasEndPoint = "promoted_ideas";
exports.sendFriendRequestEndPoint = "bx_block_request_management/requests";
exports.contestedIdeasEndPoint = "contested_ideas";
exports.libraryEndPoint = "bx_block_settings/add_ideas";
exports.popularIdeasEndPoint = "popular_likes";
exports.GetAllIdeasPostEndPoint = "all_ideas";
exports.notificationsIdeaEndPoint =
  "bx_block_notifications/change_notify_status";
exports.hideIdeaEndPoint = "hide_idea/";
exports.unhideIdeaEndPoint = "unhide_idea/";
exports.reportIdeaEndPoint = "report_iteration/";
exports.unReportIdeaEndPoint = "unreport_iteration/";
exports.reportUserEndPoint="report_account";
exports.unHideUserEndPoint = "hide_profile"
exports.deleteIdeaEndPoint = "delete/";
exports.getIdeaByIDEndPoint = "show_user_profile/";
exports.upVotingEndPoint = "upvote_like";
exports.downVotingEndPoint = "dislike/";
exports.updateIdeaEndPoint = "update_idea/";
exports.editIdeaEndPoint = "edit_idea/";
exports.likedIdeasEndPoint = "user_like_idea/";
exports.sharedIdeasEndPoint = "user_share_ideas";
exports.dislikedIdeasEndPoint = "user_dislike_idea/";
exports.upVotingCountEndPoint = "upvote_like_count/";
exports.sharePostEndPoint = "bx_block_settings/add_ideas/share_link/";
exports.downVotingCountEndPoint = "downvote_count/";
exports.undoDownVotingCountEndPoint = "downvote_destroy/";
exports.undoUpVotingCountEndPoint = "upvote_like_destroy/";
exports.unsaveIdeatEndPoint = "unsave_idea/";
exports.saveIdeaCountEndPoint = "save_idea_count/";
exports.saveIdeaEndPoint = "save_idea/";
exports.savedIdeaEndPoint = "saved_ideas/";
exports.shareIdeaEndPoint = "share_idea/";
exports.shareIdeaCountEndPoint = "share_idea_count/";
exports.btnExampleTitle = "CLICK ME";
exports.advSearchResultEndPoint = "advance_search";
exports.allContactsEndPoint =
  "bx_block_request_management/requests?status=accepted";

//saved ideas filters
exports.savedDefaultIdeaEndPoint = "saved_default";
exports.savedRecentIdeaEndPoint = "saved_recent";
exports.savedPromotedIdeaEndPoint = "saved_promoted";
exports.savedPopularIdeaEndPoint = "saved_popular";
exports.savedContestedIdeaEndPoint = "saved_contested";

//library idea filters
exports.libraryDefaultIdeaEndPoint = "library_default";
exports.libraryRecentIdeaEndPoint = "library_recent";
exports.libraryPromotedIdeaEndPoint = "library_promoted";
exports.libraryPopularIdeaEndPoint = "library_popular";
exports.libraryContestedIdeaEndPoint = "library_contested";

//Iteartion List Test
exports.hiddenIteration = "Hidden iteration"
exports.reportedItartion = "Reported iteration"
exports.reportedUser = "Reported user"
exports.hiddenUser = "Hidden user"
exports.originalIdea = "Original idea"
exports.currentView = "Currently viewing "
// Customizable Area End