Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.PAGE_LIMIT = 5;
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "AdvancedSearch";
exports.labelBodyText = "AdvancedSearch Body";
exports.labelAdvancedSearch = "ADVANCED SEARCH";
exports.labelUser = "User(s) ";
exports.labelHashtag = "Hashtag(s) ";
exports.labelKeyword = "Keyword(s) ";
exports.labelDate = "Date(s)";
exports.labelFilters = "Filter(s)";
exports.labelComplex = "Complex";
exports.labelPopular = "Popular";
exports.labelContested = "Contested";
exports.labelMedia = "Media";
exports.labelSearch = "Search";
exports.placeholderUsername = "Type username...";
exports.placeholderHashtag = "Add hashtag...";
exports.placeholderKeyword = "Add keyword...";
exports.placeholderStartDay = "Start date";
exports.placeholderEndDay = "End date";
exports.labelSearch = "Search";
exports.labelIdeaLibrary = "Idea Library";
exports.labelSavedIdeas = "Saved Ideas";
exports.labelDay = "Day";
exports.labelMonth = "Month";
exports.labelYear = "Year";
exports.notificationsIdeaEndPoint =
  "bx_block_notifications/change_notify_status";
exports.btnExampleTitle = "CLICK ME";
exports.hideIdeaEndPoint = "hide_idea/";
exports.unhideIdeaEndPoint = "unhide_idea/";
exports.reportIdeaEndPoint = "report_iteration/";
exports.unReportIdeaEndPoint = "unreport_iteration/";
exports.MethodTypePOST = "POST";
exports.MethodTypePUT = "PUT";
exports.advSearchResultEndPoint = "advance_search";
exports.searchResultEndPoint = "search";
exports.getUserNamesEndPoint = "user_list";
// Customizable Area End
