Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.deleteAPIMethod = "DELETE";
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelName = "Name"
exports.labelFirstName = "First name (given name)";
exports.labelMiddleName = "Middle name(s) or initial(s)";
exports.labelLastName = "Last name (surname)";
exports.labelDateOfBirth = "Date of birth";
exports.labelDay = "Day";
exports.labelMonth = "Month";
exports.labelYear = "Year";
exports.labelLocation = "Location";
exports.labelCity= "City or locality";
exports.labelCountry = "Country or state";
exports.labelBackground = "BACKGROUND";
exports.labelBackgroundContent = "Tell us something about yourself.";
exports.labelSummary = "SUMMARY";
exports.labelOriginalIdeas = "Original Ideas";
exports.labelIterations = "Iterations";
exports.labelOriginalIdeasPrivate = "Original Ideas (private)";
exports.labelIterationsPrivate = "Iterations (private)";
exports.labelOriginalIdeasContacts = "Original Ideas (private)";
exports.labelIterationsContacts = "Iterations (private)";
exports.labelShares = "Shares";
exports.labelImpact = "Impact";
exports.labelApproves = "Approves";
exports.labelDisapproves = "Disapproves";
exports.labelDeleteAccount = "Delete Account";
exports.labelDeleteDescription = "Deleting your account will only delete your private original ideas and iterations.";
exports.labelDeleteSubDescription = "All other ideas will be assigned to "
exports.labelDeleteAnonymous = "Anonymous."
exports.labelDeleteConfirmationTitle1 ="Confirmation";
exports.labelDeleteConfirmationTitle2 ="Are you sure?";
exports.labelDeleteConfirmationDescription1 ="Proceed with account deletion?"
exports.labelDeleteConfirmationDescription2 = "This decision cannot be undone."
exports.labelDeleteConfirmationDescription3 ="Do you really wish to permanently delete this account?"
exports.labelDeleteConfirmationDescription4 = "This decision cannot be undone."

exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.labelAccountDeleted = "Account Deleted";

exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save";
exports.btnTextChangePassword = "Change Password";
exports.btnTextDelete = "Delete";

exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.errorNameNotValid ="Enter a valid name";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.errorDateOfBirthValid = "Enter valid Date"
exports.loginAPiURL = 'update_profile/';
exports.deleteAPiURL = 'delete_account/';
exports.endPointApiGetUser = "current_user/";

exports.errorDayRequire = "enter full date of birth"
exports.labelHidenIdea = "Hidden idea"
exports.labelReportedIdea = "Reported idea"
// Customizable Area End

