import React, { useState } from "react";
import "./Common.web.css";
import CustomErrorAlert from "./CustomErrorAlert";

const hidePassword = require("./assets/hidePassword.svg");
const viewPassword = require("./assets/viewPassword.svg");
const hidePasswordError = require("./assets/hidePasswordError.svg");
const viewPasswordError = require("./assets/viewPasswordError.svg");

const CustomPassword = (props: any) => {
  const [enablePasswordIcon, setEnablePasswordIcon] = useState(false);
  const togglesignPasswordShow = () => {
    setEnablePasswordIcon(!enablePasswordIcon);
  };
  return (
    <>
      <input
        id={props.id}
        placeholder={props.placeholder}
        value={props.value}
        type={enablePasswordIcon ? "text" : "password"}
        autoComplete="new-password"
        onChange={props.handlePasswordChange}
        className="passwordInputField form-control"
        onKeyPress={(e:any)=>{
          if (e.key === "Enter") {
            if(props.pressEnter){
              props.pressEnter();
            }
          }
            
        }}
      />

      {enablePasswordIcon ? (
        <img
          src={props.isError ? viewPasswordError : viewPassword}
          alt="show"
          className="icon_pass"
          onClick={togglesignPasswordShow}
        />
      ) : (
        <img
          src={props.isError ? hidePasswordError : hidePassword}
          alt="show"
          className="icon_pass"
          onClick={togglesignPasswordShow}
        />
      )}
      <CustomErrorAlert {...props} />
    </>
  );
};

export default CustomPassword;
