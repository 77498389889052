// Customizable Area Start
import React from "react";

import { createTheme, ThemeProvider } from "@material-ui/core/styles";

import NotificationsController, {
  Props,
  configJSON,
} from "./NotificationController.web";
//@ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import NotificationPostFeed from "./NotificationPostFeed.web";


export default class Notifications extends NotificationsController {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getPendingRequests();
    this.getNotifications()
  }
  render() {
    if (this.state.isLoading) {
      return (
        <FullScreenSnippingLoader />
      )
    } else {
      return (
        <ThemeProvider theme={theme}>
          {this.state.isLoading && <FullScreenSnippingLoader />}
          <div style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: '1rem' }}>
            {!this.state.isLoading && this.state.notifications && this.state.notifications.length > 0 ? this.state.notifications.map((each, index) => {
              return <div style={{ marginBottom: '8px' }} key={index}>
                <NotificationPostFeed
                  navigation={undefined}
                  id={""}
                  classes={this.props.classes}
                  isPopup={true}
                  isNotiPopUp={false}
                  friendRequestUpdate={this.friendRequestUpdate}
                  notificationDetail={each}
                  deleteNotifications={this.deleteNotifications}
                  friendRequest={each?.attributes?.notificationable_type !== "BxBlockSettings::AddIdea"}
                  isNotify={this.notifyOnIdea}
                  isIdeaBox={this.notifyIdeaBox}
                  makeNotificationAsRead={() => this.makeNotificationAsRead(each?.id)}
                  getPendingRequests={this.getPendingRequests}


                />
              </div>
            }) :
              <div className={`circularProgress`}>
                <div className={"noPendingNotification"}>
                  {configJSON.labelNoPendingNotification}
                </div>
              </div>
            }
          </div>
        </ThemeProvider>
      )
    }

  }
}


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

// Customizable Area End
