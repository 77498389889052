import React from "react";
// Customizable Area Start
import { Box } from "@material-ui/core";
import "../../postcreation/src/PopUpMenu.web.css";
import "./ActivityFeed.css";
import { GroupIcon, LockIcon, WorldIcon } from "./assets";

// Customizable Area End

const ActivityFeedPrivacyDropDown = (props: any) => {
    // Customizable Area Start

    const filter = [
        {
            text: "Contacts",
            icon: <GroupIcon />,
            function: null
        },
        {
            text: "Platform",
            icon: <WorldIcon />,
            function: null
        },
        {
            text: "Private",
            icon: <LockIcon />,
            function: "handleHide"
        } 
    ];
    const handlePrivacyDropDownFun= (item:any) => {
        props.handlePrivacyDropDown(item?.text);
    }

    


    return (
        <>
            <Box component="span" m={1} className="box-body" >
                <Box
                    className="title-box"
                    id="title-box"
                    style={{ flexDirection: "column", textTransform: "uppercase" }}
                    
                >
                    {filter.map((item: any, index: number) => {
                        return (
                            <Box
                                data-testid= {`feedDropDownBox${index}`}
                                key={index}
                                onClick={() => handlePrivacyDropDownFun(item)}
                                className={"feedFilterDropDownBox"}
                            >

                                <p className="menu-title" >
                                    <span className="menu-icon">{item.icon}</span>
                                    {item.text}
                                </p>
                            </Box>
                        );

                    })}
                </Box>
            </Box>
        </>
    );
    // Customizable Area End
};

// Customizable Area Start
// Customizable Area End

// Customizable Area Start
export default ActivityFeedPrivacyDropDown;
