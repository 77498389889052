//@ts-nocheck
import React from 'react'
import LoadingOverlay from 'react-loading-overlay-ts';
import styles from './FullScreenSnippingLoader.module.css'
import "../LOADING_GIF_4.css"
const MySpinner = () => <div className="custom-spinner LOADING_GIF_4 Ellipse_189"></div>;

interface props{
    message1?: string | null,
    timeoutMessage?: string | null,
    style?: object | null,
    timeoutCounter?: number,
    className?: string,
}

const FullScreenSnippingLoader: React.FC<props> = ({message1, timeoutMessage, className, style, timeoutCounter}) =>{

    return (
        <div className={styles.LoadingOverlayStyle}>
            <LoadingOverlay
            active={true}
            spinner={<MySpinner/>}
            className={`${className?className:styles.LoadingComponent}`}
            >
            {message1 && <div style={ style || { height: 200 }}>
                <p>{message1}</p>
            </div>}
            {timeoutMessage && <div style={ style || { height: 200 }}>
                {setTimeout(() => {
                    <p>{timeoutMessage}</p>
                }, timeoutCounter)}
            </div>}
         </LoadingOverlay>
        </div>
    );
}

export default FullScreenSnippingLoader