// Customizable Area Start
import { Button, ClickAwayListener, makeStyles, Tooltip, Typography, withStyles } from '@material-ui/core';
import moment from 'moment';
import { Link } from "react-router-dom";
import React from 'react';
import { VerticalThreeDots } from './assets';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import NotificationsController from './NotificationController.web';
import VerticalDotsDropDown from './VerticalDotsDropDown';
import {noProfileImg } from '../../user-profile-basic/src/assets';
import "./PendingFriendRequests.css";
import "./NotificationPostFeed.web.css"
//@ts-ignore
import {baseURL} from "../../../framework/src/config.js";

const useStyles = makeStyles({
 
});
const webStyle = {
    notificationMain:{
      width: 592,
      // height: 92,
      background: '#030F17',
      borderRadius: 4,
      display: 'flex',
      justifyContent:'space-between'
    },
    unreadMessage:{
      background: '#09202F',
      width: 592,
      // height: 92,
      borderRadius: 4,
      display: 'flex',
      justifyContent:'space-between'
    },
    notificationMainhover:{
        width: 592,
        // height: 92,
        background: '#09202F',
        borderRadius: 4,
        display: 'flex',
        justifyContent:'space-between'
    },
    innerBox:{
      width: 68,
      height: 68,
      borderRadius: '50%',
      margin: '12px 24px',
      // border: '1px solid #F1F2F2',
    },
    popInnerBox:{
      width: 40,
      height: 40,
      borderRadius: '50%',
      // margin: '16.5px 18px',
      margin: '14px 16px'
      // border: '1px solid #F1F2F2',
    },
    popInnerBoxs :{
      width: 40,
      height: 40,
      borderRadius: '50%',
      // margin: '16.5px 18px',
      margin: '14px 16px'
    },
    imageNotify:{
        width:'66.52px',
        height: '66.52px',
        borderRadius: '50%',
        marginRight: '10px',
        border: '1px solid #F1F2F2'
    },
    imageNotifyPopup:{
      width:'40px',
      height: '40px',
      borderRadius: '50%',
      marginRight: '10px',
      border: '1px solid #F1F2F2'
    },
    notificationstartContainer:{
      display:'flex'
    },
    textcontainer:{
      marginTop:22.5,
      fontFamily: 'Jost-Regular, Sans-serif',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '23px',
      color: '#F1F2F2'
    },
    textcontainerPopup:{
      marginTop:16,
      fontFamily: 'Jost-Regular, Sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '13px',
      lineHeight: '19px',
      color: '#F1F2F2'
    },
    timeText:{
      fontFamily: 'Jost-Regular, Sans-serif',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '12px',
      lineHeight: '17px',
      color: '#86A2B4'
    },
    threeDotbtn:{
      // width:'28px',
      margin:'auto 12px',
      justifyContent:'right',
    },
    threeDotbtnNoti:{
      // width:'28px',
      margin:'auto 16px',
      justifyContent:'right',
    },
    threebtn:{
      cursor:'pointer'
    },
}
const ThreeDotsToolTip = withStyles({
  tooltip: {
      // "&:MuiTooltip-tooltipPlacementRight":{
      //     paddingRight:5
      //     },
          
      // maxHeight: 200,
      backgroundColor: '#0D2738  !important',
      padding:"3px !important",
      position:'absolute',
      // left:12,
      
      width:230
  },
  
  arrow: {
      "&:before": {
          backgroundColor: '#0D2738  !important',
      }
  }
})(Tooltip);

class NotificationPostFeed extends NotificationsController {
  assignDate = () => {
   let date = this.props.notificationDetail?.attributes?.updated_at || "2022-07-04T15:09:42.435-07:00"
    let p1 = date.substr(0, 19)
    let p2 = date.substr(23)
    date = p1 + p2
    this.setState({date:date})
}
 async componentDidMount(){
  this.assignDate();
  const isUserNotification= this.props.notificationDetail?.attributes?.is_user_notify
  const isIdeaBoxnotification= this.props.notificationDetail?.attributes?.is_notify
  this.setState({
    read:this.props.notificationDetail.attributes.is_read,
    userNotification:isUserNotification,
    ideaBoxnotification:isIdeaBoxnotification,
    friendRequest:this.props.friendRequest
  })
  }
  
  getReadableStatus(isVerticalDots:any) {
    if (isVerticalDots) {
      return webStyle.notificationMainhover;
    }
  
    return !this.props.isNotiPopUp && !this.props.notificationDetail?.attributes?.is_read ? webStyle.unreadMessage : webStyle.notificationMain; 
  }
  
  
  notificationMainClass(){
    if(this.props.isNotiPopUp && !this.props.notificationDetail?.attributes?.is_read){
      return "unreadnotificationPopUpContainer"
    }
    
    return this.props.isNotiPopUp ? "notificationPopUpContainer" : ""
  }
  cssConditionalCss(value:any,children:any,children2:any){
     return value ? children : children2;
  }
  
  render(){
    const { friendRequest = null, friendRequestUpdate, notificationDetail, isNotiPopUp = false } = this.props
    let {isVerticalDotsOpen,isThreeDotsOpen} = this.state;
    const isProfilePic = this.props.notificationDetail?.attributes.created_by.data.attributes.image;
    return(
      <div  className={this.notificationMainClass()}  
      onMouseEnter={() => { this.setState({isThreeDotsOpen:true})}}
      onMouseLeave={() => { this.setState({isThreeDotsOpen:false})}}
      style={this.getReadableStatus(isThreeDotsOpen) }
      >
        {this.state.friendRequest ? (
          <div style={webStyle.notificationstartContainer}>
          <div style={this.cssConditionalCss(this.props.isNotiPopUp, webStyle.popInnerBox, webStyle.innerBox)}>
           <img src={isProfilePic ? `${baseURL}${isProfilePic }` : noProfileImg}  style={this.cssConditionalCss(this.props.isNotiPopUp , webStyle.imageNotifyPopup , webStyle.imageNotify )} />
          </div>
        
          {/* <Link to={`/SharePost/${notificationDetail?.attributes?.notificationable_id}`} style={{ textDecoration: 'none', width: "100%" }} onClick={props.makeNotificationAsRead}> */}
       
          <div style={this.cssConditionalCss(this.props.isNotiPopUp  , webStyle.textcontainerPopup , webStyle.textcontainer)}>
          <b>{notificationDetail?.attributes.headings.split(" ")[0]} </b>{notificationDetail?.attributes.headings.split(" ").slice(1).join(" ")}
           <div>          
           <Typography style={webStyle.timeText}>
               {moment([this.state.date as string], 'YYYY-MM-DD hh:mm:ss Z').fromNow() == "a day ago" ? "Yesterday" : moment([this.state.date as string], 'YYYY-MM-DD hh:mm:ss Z').fromNow()}
           </Typography>
            </div>   
           <div style={{marginBottom:16,marginTop:12}}>
                   <div style={{ marginRight: '10px',display:'flex' }}>
                  
                      <Button id="rejectbtnfeed" data-testid="rejectbtnfeed" className="rejectbtnfeed" variant="outlined" onClick={() => { friendRequestUpdate(notificationDetail?.attributes.notificationable_id, "rejected"); this.deleteNotifications(notificationDetail?.id);  }} >
                          <p className='feedrejectContact displayFlex' style={{textTransform:'uppercase'}}> <ClearIcon fontSize="small" /><span>Reject</span></p>
                      </Button>
                      <Button id="acceptbtnfeed" data-testid="acceptbtnfeed" className="acceptbtnfeed" variant="outlined" onClick={() => { friendRequestUpdate(notificationDetail?.attributes.notificationable_id, "accepted"); this.deleteNotifications(notificationDetail?.id) }}>
                          <p className='feedAddContact displayFlex' style={{textTransform:'uppercase'}}><CheckIcon fontSize="small" /><span>Accept</span></p>
                      </Button>
                  </div>
           </div>
          </div>
        </div>

      ) : 
      <Link to={`/SharePost/${notificationDetail?.attributes?.notificationable_id}`} style={{ textDecoration: 'none' }} data-testid="markAsReadTest" onClick={this.props.makeNotificationAsRead}>
     
      <div style={webStyle.notificationstartContainer}>
        <div style={this.cssConditionalCss(this.props.isNotiPopUp , webStyle.popInnerBoxs , webStyle.innerBox)}>
         <img src={isProfilePic ? `${baseURL}${isProfilePic }` : noProfileImg}  style={this.cssConditionalCss(this.props.isNotiPopUp , webStyle.imageNotifyPopup , webStyle.imageNotify) } />
        </div>
      
        {/* <Link to={`/SharePost/${notificationDetail?.attributes?.notificationable_id}`} style={{ textDecoration: 'none', width: "100%" }} onClick={props.makeNotificationAsRead}> */}
     
        <div style={this.cssConditionalCss(this.props.isNotiPopUp  , webStyle.textcontainerPopup , webStyle.textcontainer)}>
        <b>{notificationDetail?.attributes.headings.split(" ")[0]} </b>{notificationDetail?.attributes.headings.split(" ").slice(1).join(" ")+"."}
         <div>
        {/* </Link> */}
         <Typography  style={webStyle.timeText}>
             {moment([this.state.date as string], 'YYYY-MM-DD hh:mm:ss Z').fromNow() == "a day ago" ? "Yesterday" : moment([this.state.date as string], 'YYYY-MM-DD hh:mm:ss Z').fromNow()}
         </Typography>
          </div>   
        </div>
      </div>
      </Link>
   }
      <div style={this.cssConditionalCss(this.props.isNotiPopUp ,webStyle.threeDotbtn ,webStyle.threeDotbtnNoti)} className={this.cssConditionalCss(this.state.friendRequest && this.props.isNotiPopUp, "friendReqThreeDot", "")}>
        {isThreeDotsOpen || !this.props.isNotiPopUp  ? (
        <div style={webStyle.threebtn}>
        <ClickAwayListener 
                      onClickAway={() => {
                  }}>
                      <div className={"threeDotTooltipContainer"} data-testid="threeDotTestId"
                          onClick={() => { this.setState({isVerticalDotsOpen:true})}}
                          onMouseEnter={() => { this.setState({isVerticalDotsOpen:true})}}
                          onMouseLeave={() => { this.setState({isVerticalDotsOpen:false})}}
                          >
                          <ThreeDotsToolTip
                              PopperProps={{
                                  disablePortal: true,
                              
                              }}
                              open={isVerticalDotsOpen}
                              arrow={true}
                              title={<VerticalDotsDropDown
                                  id=""
                                  navigation={() => { }}
                                  is_notify={this.state.userNotification}
                                  classes={this.props.classes}
                                  handleUserNotication={this.handleUserNotication}
                                  ideaBoxnotify={this.state.ideaBoxnotification}
                                  handleIdeaBoxNotification={this.handleIdeaBoxNotification}
                              />}
                              placement={this.cssConditionalCss(!this.props?.isPopup , "bottom-end" , "right-start")}
                              interactive={true}
                              id={"onHoverProfileBox"}
                          >  
                          <div>
                          <VerticalThreeDots />
                          </div>
                          </ThreeDotsToolTip>
                      </div>
                  </ClickAwayListener>
        </div>
        ) : null}
      </div>
    </div>)
    }
  }



export default NotificationPostFeed;
// Customizable Area End