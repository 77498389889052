//@ts-nocheck
export const imgAdd = require("../assets/ic_add.png");
export const imgCancel = require("../assets/ic_cancel.png");
export const imgGstin = require("../assets/ic_gstin.png");
export const imgMapIcon = require("../assets/ic_map_icon.png");
export const imgSuccessModal = require("../assets/success_modal_image.png");
export const backIcon = require("../assets/backIcon.png");
export const FeedbackIcon = require("./assets/feedback.png");
export const reloadIcon = require("./assets/reload.svg");

export { ReactComponent as ChatIcon } from "../src/assets/chat.svg";