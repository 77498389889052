import React, { useState } from 'react';
//@ts-ignore
import {baseURL} from "../../../framework/src/config.js";

const UserDetailsContext = React.createContext({
    //this is like a state
    profile_pic: 'this is a nex',
    setProfilePic: (profile_picture: string) => { },
    removeProfilePic: () => { },
    
});


export const UserDetailsConsumer = UserDetailsContext.Consumer;


export const UserDetailsProvider: React.FC = (props) => {
    const [profile, setProfile] = useState<string | null>(null);

    const handleSetProfile = (profile_picture: string) => {
        if(profile_picture !== `${baseURL}null`) {
            setProfile(profile_picture)
            console.log("working", profile_picture)
        }else{
            setProfile(null)
        }
    }
    const handleRemoveProfile = () => {
        setProfile(null)
    }

    return (
        <UserDetailsContext.Provider value={{
            profile_pic: profile as string,
            setProfilePic: handleSetProfile,
            removeProfilePic: handleRemoveProfile,
        }}>
            {props.children}
        </UserDetailsContext.Provider>
    )
}


export default UserDetailsContext