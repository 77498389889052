Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json"
exports.endPoint = "notifications/notifications";
exports.getDataMethod = "GET";
exports.markAsReadMethod = "PUT";
exports.deleteMethod = "DELETE";
exports.MethodTypePOST = "POST";

exports.notifications = "Notifications";
exports.deleteText = "DELETE"
exports.okText = "OK"
exports.friendRequestUpdateEndPoint = "bx_block_request_management/requests/"
exports.deleteMessage = "Notifications deleted!"
exports.pendingRequestEndpoint = "pending_request?status=pending"
exports.NotificationEndpoint = "bx_block_notifications/notifications";
exports.notificationsIdeaBoxEndPoint= "bx_block_notifications/change_notify_status";
exports.notificationsIdeaEndPoint ="bx_block_notifications/change_notify_status";
exports.userNotificationEndPoint = "bx_block_notifications/user_notify_status";

exports.labelNoPendingNotification = " No pending notification";
// Customizable Area End