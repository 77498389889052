//@ts-nocheck

export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const AddImage = require("../assets/addImage.png");
export const edit = require("../assets/edit.png");
export const infoIcon = require("../assets/icon_information.svg");
export const lineIcon = require("../assets/line_icon.svg");
export const crossIcon = require("../assets/cross_icon.svg");
export const logoIcon = require("../assets/logo_icon.svg");
export const profilePic = require("../assets/profilepic.png");
export const ImageLogo = require("../assets/imagelogo.svg");
export const HyperlinkLogo = require("../assets/hyperlinklogo.svg");
export const VideoLogo = require("../assets/videologo.svg");
export const PlusIcon = require("../assets/plus_icon.svg");
export const iteratedUser = require("../assets/iteratedUser.svg");
export const profiles_icon_more = require("../assets/profiles_icon_more.svg");
export const noProfileImg = require("../assets/noProfile.svg");
export const hiddenEye = require("../assets/Eye.svg")
export const redReportIcon = require("../assets/Report.svg")
export const upArrow = require("../assets/ArrowsUp.svg")
export const downArrow = require("../assets/ArrowsDown.svg")
export const shareIcon = require("../assets/Share.svg")
export const saveIcon = require("../assets/Save.svg")
export const moreIcon = require("../assets/moreIcon.svg")

export { ReactComponent as Context } from "../assets/context.svg";
export { ReactComponent as SolutionIcon } from "../assets/solution_icon.svg";
export { ReactComponent as ProblemIcon } from "../assets/fire.svg";
export { ReactComponent as DropDownIcon } from "../assets/dropdown_arrow.svg";
export { ReactComponent as ThreeDotsIcon } from "../assets/three_dots.svg";
export { ReactComponent as Disapprove } from "../assets/disapprove.svg";
export { ReactComponent as Approve } from "../assets/approve.svg";
export { ReactComponent as Share } from "../assets/share_icon.svg";
export { ReactComponent as Save } from "../assets/save_icon.svg";
export { ReactComponent as Delete } from "../assets/delete_icon.svg";
export {
  ReactComponent as NotificationBell,
} from "../assets/notification_bell_icon.svg";
export {
  ReactComponent as NotificationBellOff,
} from "../assets/notification_bell_off_icon.svg";
export { ReactComponent as Report } from "../assets/report_icon_off.svg";
export { ReactComponent as HideIdea } from "../assets/hide_idea_icon.svg";
export { ReactComponent as ViewIdea } from "../assets/viewIdea.svg";
export { ReactComponent as HoverEdit } from "../assets/onHoverEdit_icon.svg";
export { ReactComponent as WorldIcon } from "../assets/icon_world.svg";
export { ReactComponent as LockIcon } from "../assets/lock_icon.svg";
export { ReactComponent as GroupIcon } from "../assets/icon_group.svg";
export { ReactComponent as Edited } from "../assets/isEdited_icon.svg";
export { ReactComponent as AddContact } from "../assets/addcontact_icon.svg";
export { ReactComponent as Magnifier } from "../assets/magnifier_icon.svg";
export { ReactComponent as ReportOn } from "../assets/reportOn.svg";
export { ReactComponent as ReportOff } from "../assets/reportOff.svg";
export { ReactComponent as RemoveContact } from "../assets/removeContact.svg";
export { ReactComponent as AddContactIcon } from "../assets/addcontact.svg";
export { ReactComponent as Dotdotdot } from "../assets/Dotdotdot.svg";
export { ReactComponent as ReportUser } from "../assets/Report.svg";
export { ReactComponent as HidenUser } from "../assets/Eye.svg";
export { ReactComponent as Eyeclose } from "../assets/Eyeclose.svg";
export { ReactComponent as EditOnHoverIcon } from "../assets/EditOnHoverIcon.svg"
export { ReactComponent as Search } from "../assets/Search.svg"
export { ReactComponent as PopupCloseIcon } from "../assets/X.svg";



export const lockIcon = require("../assets/lock_icon.svg");
export const worldIcon = require("../assets/icon_world.svg");
export const notifyOnIcon = require("../assets/notification_bell_icon.svg");
export const notifyOffIcon = require("../assets/notification_bell_off_icon.svg");
export const reportIconOFF = require("../assets/report_icon_off.svg");
export const reportIconON = require("../assets/report_icon_on.svg");
export const reportIconWhite = require("../assets/reportOff.svg");
export const hideIcon = require("../assets/hide_idea_icon.svg");
export const hideIconWhite = require("../assets/hide_idea_icon_white.svg");
export const viewIcon = require("../assets/viewIdea.svg");
export const deleteIcon = require("../assets/delete_icon.svg");
export const revertIcon = require("../assets/revertIcon.svg");
export const revertIconBlue = require("../assets/Revert.svg");
export const swatIcons = require("../assets/SWAP.svg");
export const editIcon  = require("../assets/EditOnHoverIcon.svg")
export const swatDeactivateIcons = require("../assets/SWAP-DeActivate.svg");
export const revertActive = require("../assets/Revert-inactive.svg");
export const loader = require("../assets/loader.svg");