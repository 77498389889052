import * as React from 'react';
// Customizable Area Start
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { FeedBackSvg } from "./assets";


const useStyles = makeStyles((theme) => ({
  feedBackContainer: {
    width: '56px',
    height: '46px',
    boxSizing: 'border-box',
    position: 'fixed',
    right: 0,
    bottom: "40px",
    background: '#030F17',
    border: '1.4px solid #284A62',
    borderRadius: '30px 0px 0px 30px',
    padding: '5.5px 72px 8px 11px',
    "&:hover": {
      border: '1.4px solid #1CA9C2',
      boxShadow: "0px 0px 5px #1CA9C2",
      width: '100px',
      transition: 'width 1s',
      "& svg circle": {
        stroke: '#1CA9C2',
      },
      "& svg path": {
        stroke: '#1CA9C2',
      }
    },
  },
  avatar: {
    width: '32px',
    height: '32px',
  }
}));


const FeedbackMessageIcon = (props: any) => {
  const classes = useStyles();
  return (
    <Link to="/FeedBack">
      <Box id="FeedbackContainer" className={classes.feedBackContainer}>
        <FeedBackSvg className={classes.avatar} />
      </Box >
    </Link>
  )
}
export default FeedbackMessageIcon;

// Customizable Area End