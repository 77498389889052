// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

import {
  LockIcon,
  GroupIcon,
  WorldIcon,
} from "./assets";

type errorType = boolean | string | string[];

//@ts-ignore
import UserDetailsContext from "../../../web/src/Context/Context.web";


export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;

  TitleText?: string;
  countNeeded?: number;
  whatIsIt?: string;
  contextImageUpload?: any;
  ProblemImageUpload?: any;
  SolutionImageUpload?: any;
  ImagePreview?: any;
  VideoUpload?: any;
  removeImage?: any;
  title?: "context" | "problem" | "solution";
  handleHyperLink?: any;
  imageCropPopup?: any;
  contextImageUploadedbyUser?: any;
  profileImageCrop?: any;
  AddCropImageDailog?: any;
  handleDate?: any;
  setImageData?: any;
  ideaPicImageCrop?: any;
  testId?: any;
}

interface S {

  openPlusDialogProblem: boolean;
  openPlusDialogSolution: boolean;
  openPlusDialogContent: boolean;
  TitleText: string;
  ContentText: string;
  ProblemText: string;
  SolutionText: string;
  ErrorInTitle: boolean;
  ErrorInContent: boolean;
  ErrorInProblem: boolean;
  ErrorInSolution: boolean;
  ErrorInContentMedia: boolean;
  ErrorInProblemMedia: boolean;
  ErrorInSolutionMedia: boolean;
  WrongLetterError: boolean;
  dummy: string;
  PrivacySelectAddNewIdea: any;
  showError: string;
  firstName: any;
  lastName: any;
  date: any;
  day: any;
  month: any;
  year: any;
  deleteModalOpen: boolean;

  email: any;
  phoneNumber: any;
  currentCountryCode: any;
  data: any[];
  passwordHelperText: String;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  enableNewPasswordField: boolean;

  edtEmailEnabled: boolean;
  llDoChangePwdContainerVisible: boolean;
  llChangePwdDummyShowContainerVisible: boolean;

  currentPasswordText: any;
  newPasswordText: any;
  reTypePasswordText: any;

  edtMobileNoEnabled: boolean;
  countryCodeEnabled: boolean;

  saveButtonDisable: boolean;

  contextImageUpload: any;
  contextVideoUpload: any;
  problemVideoUpload: any;
  solutionVideoUpload: any;
  problemImageUpload: any;
  solutionImageUpload: any;
  contextImagePreview: any;
  problemImagePreview: any;
  solutionImagePreview: any;
  ImagePreview: any;
  VideoUpload: any;

  openHyperLinkDialoge: boolean;
  hyperLink: string | null;
  hyperLinkText: string | null;
  ContentTextWithHyperLink: string | null;
  ProblemTextWithHyperLink: string | null;
  SolutionTextWithHyperLink: string | null;
  openSnackBarError: boolean;
  HyperLinkModalTitle: "context" | "problem" | "solution" | null
  hyperLinkError: boolean;
  isLoading: boolean;
  totalFileError: boolean;
  ExtentionError: boolean;
  ideaAdded: boolean;
  titleValidation: boolean;
  contentValidation: boolean;
  problemValidation: boolean;
  isImageDailog: boolean;
  imageCropPopup: boolean;
  ideaPicImageCrop: any;
}

interface SS {

  id: any;

}

export default class AddNewIdeaController extends BlockComponent<Props, S, SS> {

  labelName: string;
  labelFirstName: string;
  labelMiddleName: string;
  labelLastName: string;
  labelDateOfBirth: string;
  labelDay: string;
  labelMonth: string;
  labelYear: string;
  labelLocation: string;
  labelCity: string;
  labelCountry: string;
  labelBackground: string;
  labelBackgroundContent: string;

  labelArea: string;
  labelMobile: string;
  labelEmail: string;
  labelCurrentPassword: string;
  labelNewPassword: string;
  labelRePassword: string;
  btnTextCancelPasswordChange: string;
  btnTextSaveChanges: string;
  labelHeader: any;
  btnTextChangePassword: string;

  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  apiCallMessageUpdateProfileRequestId: any;
  validationApiCallId: string = "";
  apiChangePhoneValidation: any;
  registrationAndLoginType: string = "";
  authToken: any;
  uniqueSessionRequesterId: any;
  userProfileGetApiCallId: any;
  AddNewIdeaCallID: any;
  userAttr: any;




  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      date: null,
      day: null,
      month: null,
      year: null,
      deleteModalOpen: false,
      phoneNumber: "",
      currentCountryCode: configJSON.hintCountryCode,
      data: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      enableNewPasswordField: true,
      edtEmailEnabled: true,
      llDoChangePwdContainerVisible: false,
      llChangePwdDummyShowContainerVisible: false,

      currentPasswordText: "",
      newPasswordText: "",
      reTypePasswordText: "",

      edtMobileNoEnabled: true,
      countryCodeEnabled: true,
      saveButtonDisable: false,
      openPlusDialogProblem: false,
      openPlusDialogSolution: false,
      openPlusDialogContent: false,
      ContentText: '',
      ProblemText: '',
      SolutionText: '',
      TitleText: '',
      dummy: 'something funny',
      PrivacySelectAddNewIdea: 'Contacts',
      ErrorInTitle: false,
      ErrorInContent: false,
      ErrorInProblem: false,
      ErrorInSolution: false,
      ErrorInContentMedia: false,
      ErrorInProblemMedia: false,
      ErrorInSolutionMedia: false,
      WrongLetterError: false,
      showError: '',
      contextImageUpload: null,
      problemImageUpload: null,
      solutionImageUpload: null,
      contextImagePreview: null,
      problemImagePreview: null,
      solutionImagePreview: null,
      contextVideoUpload: null,
      problemVideoUpload: null,
      solutionVideoUpload: null,
      ImagePreview: null,
      VideoUpload: null,
      openHyperLinkDialoge: false,
      hyperLink: null,
      hyperLinkText: null,
      ContentTextWithHyperLink: null,
      ProblemTextWithHyperLink: null,
      SolutionTextWithHyperLink: null,
      openSnackBarError: false,
      HyperLinkModalTitle: null,
      hyperLinkError: false,
      isLoading: true,
      totalFileError: false,
      ExtentionError: false,
      ideaAdded: false,
      titleValidation: false,
      contentValidation: false,
      problemValidation: false,
      isImageDailog: false,
      imageCropPopup: false,
      ideaPicImageCrop: ""
    };

    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");
    this.labelName = configJSON.labelName;
    this.labelFirstName = configJSON.labelFirstName;
    this.labelMiddleName = configJSON.labelMiddleName;
    this.labelLastName = configJSON.labelLastName;
    this.labelDateOfBirth = configJSON.labelDateOfBirth;
    this.labelDay = configJSON.labelDay;
    this.labelMonth = configJSON.labelMonth;
    this.labelYear = configJSON.labelYear;
    this.labelLocation = configJSON.labelLocation;
    this.labelCity = configJSON.labelCity;
    this.labelCountry = configJSON.labelCountry;
    this.labelBackground = configJSON.labelBackground;
    this.labelBackgroundContent = configJSON.labelBackgroundContent;

    this.labelArea = configJSON.labelArea;
    this.labelMobile = configJSON.labelMobile;
    this.labelEmail = configJSON.labelEmail;
    this.labelCurrentPassword = configJSON.labelCurrentPassword;
    this.labelNewPassword = configJSON.labelNewPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.btnTextCancelPasswordChange = configJSON.btnTextCancelPasswordChange;
    this.btnTextSaveChanges = configJSON.btnTextSaveChanges;
    this.labelHeader = configJSON.labelHeader;
    this.btnTextChangePassword = configJSON.btnTextChangePassword;

    runEngine.attachBuildingBlock(this, this.subScribedMessages);
  }


  async componentDidMount() {
    super.componentDidMount();
    this.setState({
      isLoading: false
    })
  }

  async receive(from: String, message: Message) {

    runEngine.debugLog("on recieive==>" + JSON.stringify(message))

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId && responseJson) {

        if (apiRequestCallId === this.AddNewIdeaCallID) {
          // console.log("responseJson.status", responseJson.status)
          if (!responseJson.errors && responseJson.status !== 500) {

            this.setState({
              isLoading: false,
              ideaAdded: true
            })
            this.clearEverythingWithoutConfirmation()
            const timer = setTimeout(() => {
              this.setState({
                ideaAdded: false
              })
              this.navigateToPage(true, "ActivityFeed")
              // this.props?.navigation?.navigate("ActivityFeed");
            }, 2000);
            return () => clearTimeout(timer);
          } else if (responseJson.status == 500) {
            this.showErrorMsg("Something went wrong. Try again.");
          } else if (responseJson.errors[0].token) {
            this.showErrorMsg("Please login again. Redirecting...");
            setTimeout(() => {
              // this.props.navigation.navigate('EmailAccountLogin')
              this.navigateToPage(true, "EmailAccountLogin")
            }, 2000);
          } else {
            this.showErrorMsg("Something went wrong. Try again.");
          }
        }
      }
    }
  }

  showErrorMsg = (errorMsg: string) => {
    this.showAlert("Failed", errorMsg)
    this.setState({
      isLoading: false
    })
  }

  checkAddIdeaFileSize = () => {
    let files = [this.state.contextImageUpload?.size,
    this.state.problemImageUpload?.size,
    this.state.solutionImageUpload?.size,
    this.state.contextVideoUpload?.size,
    this.state.problemVideoUpload?.size,
    this.state.solutionVideoUpload?.size];
    let temp = 0
    files.forEach((e) => {
      if (e !== undefined)
        temp += e
    })
    temp = (temp / 1024) / 1024
    // console.log("temp", temp)
    if (temp > 50) {
      this.setState({
        totalFileError: true
      })
      setTimeout(() => {
        this.setState({
          totalFileError: false
        })
      }, 3000);
      // console.log("return false")
      return false
    }
    // console.log("return something", temp)
    if (temp == 0)
      return true
    else
      return temp
  }

  convertHashtagsToString = () => {
    let str = this.state.TitleText + " " + this.state.ContentText + " " + this.state.ProblemText + " " + this.state.SolutionText + " "
    let hashtagString = ""
    let hashTags: any = str.match(/#[A-Za-z0-9_-]+/gmi)
    if (hashTags?.length > 0) {
      hashTags.map((each: any, index: number) => {
        if (index > 0) {
          hashtagString += ', '
        }
        hashtagString += each.substring(1)

      })
    }
    return hashtagString
  }

  submitIdeaPreCheck = () => {
    if (!this.checkAddIdeaFileSize()) return
    if (this.state.ErrorInContent
      || this.state.ErrorInProblem
      || this.state.ErrorInSolution
      || this.state.ErrorInTitle
    ) return false

    if (this.state.TitleText?.length <= 0) {
      this.setState({ titleValidation: true });
      setTimeout(() => {
        this.setState({ titleValidation: false })
      }, 6000);
      return false
    }
    if (this.state.ProblemText?.length <= 0 && this.state.SolutionText?.length <= 0) {
      this.setState({ problemValidation: true })
      setTimeout(() => {
        this.setState({ problemValidation: false })
      }, 6000);
      return false
    }

    this.submitIdea();

  }

  submitIdea = () => {

    this.setState({ isLoading: true });
    let hashtagString = this.convertHashtagsToString()

    let ideaType = '';
    switch (this.state.PrivacySelectAddNewIdea) {
      case "Private":
        ideaType = "i_private";
        break;
      case "Platform":
        ideaType = "i_public";
        break;
      case "Contacts":
        ideaType = "i_contact";
        break;
      default:
        ideaType = "i_public";
    }

    const formData = new FormData();

    formData.append("idea_type", ideaType);
    formData.append("title", this.state.TitleText);
    formData.append("idea_context_attributes[description]", this.state.ContentText);
    formData.append("idea_problem_attributes[description]", this.state.ProblemText);
    formData.append("idea_solution_attributes[description]", this.state.SolutionText);
    formData.append("tag_list", hashtagString);

    //check for images
    if (this.state.contextImageUpload)
      formData.append("idea_context_attributes[image]", this.state.contextImageUpload);

    if (this.state.problemImageUpload)
      formData.append("idea_problem_attributes[image]", this.state.problemImageUpload);

    if (this.state.solutionImageUpload)
      formData.append("idea_solution_attributes[image]", this.state.solutionImageUpload);

    //check for videos
    if (this.state.contextVideoUpload)
      formData.append("idea_context_attributes[video]", this.state.contextVideoUpload);

    if (this.state.problemVideoUpload)
      formData.append("idea_problem_attributes[video]", this.state.problemVideoUpload);

    if (this.state.solutionVideoUpload)
      formData.append("idea_solution_attributes[video]", this.state.solutionVideoUpload);

    //check for HyperLinks
    if (this.state.ContentTextWithHyperLink)
      formData.append("idea_context_attributes[url]", this.state.ContentTextWithHyperLink);

    if (this.state.ProblemTextWithHyperLink)
      formData.append("idea_problem_attributes[url]", this.state.ProblemTextWithHyperLink);

    if (this.state.SolutionTextWithHyperLink)
      formData.append("idea_solution_attributes[url]", this.state.SolutionTextWithHyperLink);

    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.AddNewIdeaCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.AddNewIdeaEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.PostAPiMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    )

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleAddIdeaPlusProblem = (event: any) => {
    event.preventDefault();
    this.setState({ openPlusDialogProblem: true }, () => { });
    // alert('clicked')
  };

  handleAddIdeaPlusContent = (event: any) => {
    event.preventDefault();
    this.setState({ openPlusDialogContent: true });
  };

  handleAddIdeaPlusSolution = (event: any) => {
    event.preventDefault();
    this.setState({ openPlusDialogSolution: true });
  };

  handleAddIdeaTooltipCloseProblem = () => {
    this.setState({ openPlusDialogProblem: false });
  };

  handleAddIdeaTooltipCloseSolution = () => {
    this.setState({ openPlusDialogSolution: false });
  };

  handleTooltipCloseContent = () => {
    if (this.state.openPlusDialogContent)
      this.setState({ openPlusDialogContent: false });
  };

  handleAddIdeaTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ TitleText: e.target.value }, () => {
      this.setState({ titleValidation: false })
    })
  }

  ErrorCheckInSolutionAddIdea = () => {
    let Text: errorType = this.state.SolutionText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      this.setState({
        ErrorInSolution: true,
      }, () => this.checkErrorsInAllFeildAddIdea())

    } else {
      this.setState({
        ErrorInSolution: false,
      }, () => this.checkErrorsInAllFeildAddIdea())

    }
  }

  ErrorCheckInProblemAddIdea = () => {
    let Text: errorType = this.state.ProblemText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      this.setState({
        ErrorInProblem: true,
      }, () => this.checkErrorsInAllFeildAddIdea())

    } else {
      this.setState({
        ErrorInProblem: false,
      }, () => this.checkErrorsInAllFeildAddIdea())
    }
  }

  ErrorCheckInContentAddIdea = () => {
    let Text: errorType = this.state.ContentText;
    if (/(\[)|(\])|(\{)|(\})|(\|)/i.test(Text)) {
      this.setState({
        ErrorInContent: true
      }, () => this.checkErrorsInAllFeildAddIdea())
    } else {
      this.setState({
        ErrorInContent: false,
        // openSnackBarError: false,
      }, () => this.checkErrorsInAllFeildAddIdea())
    }
  }
  checkErrorsInAllFeildAddIdea = () => {
    if (this.state.ErrorInContent || this.state.ErrorInProblem || this.state.ErrorInSolution) {
      this.setState({ openSnackBarError: true })
    } else {
      this.setState({ openSnackBarError: false })
    }
  }
  onCustomAlertCloseButtonClick = () => {
    this.setState({
      openSnackBarError: false,
      totalFileError: false,
      titleValidation: false,
      contentValidation: false,
      problemValidation: false,
      ExtentionError: false,
      ErrorInContent: false,
      ErrorInSolution: false,
      ErrorInProblem: false,
      openPlusDialogProblem: false,
      openPlusDialogSolution: false,
      openPlusDialogContent: false
    })
  }

  contextVideoUploadAddnewIdea = (e: any, feedType: string) => {
    const reader = new FileReader();

    //Index = 0 for Image, 1 for Video
    if (feedType === "context") {
      let video = e.target.files[0];
      if (video.name.match(/\.(mp4)$/)) {
        this.setState({ contextVideoUpload: e.target.files[0] });
      } else {
        this.setState({
          ExtentionError: true,
          ErrorInContentMedia: true,
          openPlusDialogContent: false,
        }, () => {
          setTimeout(() => {
            this.setState({
              ErrorInContentMedia: false,
              ExtentionError: false,
            })
          }, 3000)
        })
      }

    } else if (feedType === "problem") {

      let video = e.target.files[0];
      if (video.name.match(/\.(mp4)$/)) {
        this.setState({ problemVideoUpload: e.target.files[0] });
      } else {
        this.setState({
          ExtentionError: true,
          ErrorInProblemMedia: true,
          openPlusDialogProblem: false,
        }, () => {
          setTimeout(() => {
            this.setState({
              ErrorInProblemMedia: false,
              ExtentionError: false,
            })
          }, 3000)
        })
      }
    } else if (feedType === "solution") {

      let video = e.target.files[0];
      if (video.name.match(/\.(mp4)$/)) {
        this.setState({ solutionVideoUpload: e.target.files[0] })
      } else {
        this.setState({
          ExtentionError: true,
          ErrorInSolutionMedia: true,
          openPlusDialogSolution: false,
        }, () => {
          setTimeout(() => {
            this.setState({
              ErrorInSolutionMedia: false,
              ExtentionError: false,
            })
          }, 3000)
        })
      }

    }
  }

  profileImageCrop = (img: any, file: any, title: any) => {
    if (title === "context") {
      this.setState({ contextImageUpload: file }, () => {
        this.setState({
          contextImagePreview: img,
        })
      })

    } else if (title === "problem") {
      this.setState({ problemImageUpload: file }, () => {

        this.setState({
          problemImagePreview: img,
        })
      })
    } else if (title === "solution") {
      this.setState({ solutionImageUpload: file }, () => {
        this.setState({
          solutionImagePreview: img,
        })
      })
    }
  }

  AddCropImageDailog = () => {
    this.setState({
      imageCropPopup: false
    });
  }

  setImageDataAddIdea = async (event: any, feedType: string) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      if (img.name.match(/\.(jpg|jpeg|png)$/)) {
        this.setState({ ideaPicImageCrop: URL.createObjectURL(img) },
          () => { this.setState({ imageCropPopup: true }) }
        )
      } else if (img.name.match(/\.(gif)$/)) {
        switch (feedType) {
          case "context":
            return this.setState({ contextImageUpload: img }, () => {
              this.setState({
                contextImagePreview: URL.createObjectURL(img),
              })
            })
          case "problem":
            return this.setState({ problemImageUpload: img }, () => {

              this.setState({
                problemImagePreview: URL.createObjectURL(img),
              })
            });
          case "solution":
            return this.setState({ solutionImageUpload: img }, () => {
              this.setState({
                solutionImagePreview: URL.createObjectURL(img),
              })
            })
          default:
            return;
        }
      } else {
        this.setState({
          ideaPicImageCrop: null,
          ExtentionError: true,
          // ErrorInContent:true
        })
        switch (feedType) {
          case "context":
            return this.setState({
              ErrorInContentMedia: true,
              openPlusDialogContent: false
            }
              , () => {
                setTimeout(() => {
                  this.setState({
                    ErrorInContentMedia: false,
                    ExtentionError: false
                  })
                }, 3000)
              }
            );
          case "problem":
            return this.setState({ ErrorInProblemMedia: true, openPlusDialogProblem: false, }
              , () => {
                setTimeout(() => {
                  this.setState({
                    ErrorInProblemMedia: false,
                    ExtentionError: false
                  })
                }, 3000)
              }
            )
          case "solution":
            return this.setState({ ErrorInSolutionMedia: true, openPlusDialogSolution: false, }
              , () => {
                setTimeout(() => {
                  this.setState({
                    ErrorInSolutionMedia: false,
                    ExtentionError: false
                  })
                }, 3000)
              })
          default:
            return;
        }
      }
    }
  }
  handleDate = (value: string, type: "day" | "month" | "year") => {

  }
  removeImage = (title: string | undefined) => {
    if (title === 'context') this.setState({
      contextImagePreview: null,
      contextImageUpload: null,
      contextVideoUpload: null,
      ContentTextWithHyperLink: null,
    })
    else if (title === 'problem') this.setState({
      problemImagePreview: null,
      problemImageUpload: null,
      problemVideoUpload: null,
      ProblemTextWithHyperLink: null,
    })
    else if (title === 'solution') this.setState({
      solutionImagePreview: null,
      solutionImageUpload: null,
      solutionVideoUpload: null,
      SolutionTextWithHyperLink: null,
    })
  }

  handleMediaClickOpen = (title: "context" | "problem" | "solution") => {
    // console.log(title)
    this.setState({
      HyperLinkModalTitle: title,
      openHyperLinkDialoge: true,
      openPlusDialogSolution: false,
      openPlusDialogContent: false,
      openPlusDialogProblem: false,

    })
  };

  handleHyperLinkClose = () => {
    this.setState({ openHyperLinkDialoge: false })
  };

  submitHyperLink = (hyperLink: string | null, hyperLinkText: string | null, title: string) => {

    this.setState({
      hyperLinkError: false,
    })

    if (hyperLinkText?.indexOf("youtu") === -1) {
      this.setState({
        hyperLinkError: true,
      })
      // console.log("errrorrroroor")
      return
    }

    if (start = hyperLinkText!.indexOf("v="))

      if (title === "context") {
        var start: number = -1;
        var end: number = hyperLinkText!.length;
        if (start === -1) {
          start = hyperLinkText!.indexOf("v=")
          if (start !== -1) {
            start += 2
            end = hyperLinkText!.indexOf("&t=")
          }
        }

        if (start === -1) {
          start = hyperLinkText!.indexOf("embeded/")
          if (start !== -1) {
            start += 8
            end = hyperLinkText!.indexOf("&t=")
          }
        }
        if (start === -1) {
          start = hyperLinkText!.indexOf("youtu.be/")
          if (start !== -1) {
            start += 9
            end = hyperLinkText!.indexOf("&t=")
          }
        }



        //Below logic checks if "&t=" exists or not
        if (end !== -1) {
          this.setState({
            ContentTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start, end) as string
          })
        } else {
          this.setState({
            ContentTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start) as string
          })
        }
      } else if (title === "problem") {
        var start: number = -1;
        var end: number = hyperLinkText!.length;

        if (start === -1) {
          start = hyperLinkText!.indexOf("v=")
          if (start !== -1) {
            start += 2
            end = hyperLinkText!.indexOf("&t=")
          }
        }

        if (start === -1) {
          start = hyperLinkText!.indexOf("embeded/")
          if (start !== -1) {
            start += 8
            end = hyperLinkText!.indexOf("&t=")
          }
        }
        if (start === -1) {
          start = hyperLinkText!.indexOf("youtu.be/")
          if (start !== -1) {
            start += 9
            end = hyperLinkText!.indexOf("&t=")
          }
        }

        //Below logic checks if "&t=" exists or not
        if (end !== -1) {
          this.setState({
            ProblemTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start, end) as string
          })
        } else {
          this.setState({
            ProblemTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start) as string
          })
        }
      } else if (title === "solution") {
        var start: number = -1;
        var end: number = hyperLinkText!.length;

        if (start === -1) {
          start = hyperLinkText!.indexOf("v=")
          if (start !== -1) {
            start += 2
            end = hyperLinkText!.indexOf("&t=")
          }
        }

        if (start === -1) {
          start = hyperLinkText!.indexOf("embeded/")
          if (start !== -1) {
            start += 8
            end = hyperLinkText!.indexOf("&t=")
          }
        }
        if (start === -1) {
          start = hyperLinkText!.indexOf("youtu.be/")
          if (start !== -1) {
            start += 9
            end = hyperLinkText!.indexOf("&t=")
          }
        }

        //Below logic checks if "&t=" exists or not
        if (end !== -1) {
          this.setState({
            SolutionTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start, end) as string
          })
        } else {
          this.setState({
            SolutionTextWithHyperLink: "https://www.youtube.com/embed/" + hyperLinkText?.slice(start) as string
          })
        }
      }

    //closes the dialog box
    this.setState({
      openHyperLinkDialoge: false,
    })
  }

  crossButtonHandle = () => {
    this.setState({
      TitleText: '',
      SolutionText: '',
      ProblemText: '',
      ContentText: '',
      solutionImagePreview: null,
      problemImagePreview: null,
      problemImageUpload: null,
      problemVideoUpload: null,
      solutionImageUpload: null,
      solutionVideoUpload: null,
      SolutionTextWithHyperLink: null,
      contextImagePreview: null,
      contextImageUpload: null,
      contextVideoUpload: null,
      ContentTextWithHyperLink: null,
      ProblemTextWithHyperLink: null,
      openHyperLinkDialoge: false,
    });
    this.props?.navigation?.navigate("ActivityFeed");
  }

  clearEverythingWithoutConfirmation = () => {
    this.setState({
      TitleText: '',
      SolutionText: '',
      ProblemText: '',
      ContentText: '',
      solutionImagePreview: null,
      problemImagePreview: null,
      problemImageUpload: null,
      problemVideoUpload: null,
      solutionImageUpload: null,
      solutionVideoUpload: null,
      SolutionTextWithHyperLink: null,
      contextImagePreview: null,
      contextImageUpload: null,
      contextVideoUpload: null,
      ContentTextWithHyperLink: null,
      ProblemTextWithHyperLink: null,
      openHyperLinkDialoge: false,
      openPlusDialogContent: false,
      openPlusDialogProblem: false,
      openPlusDialogSolution: false,
    })
  }

  onHyperlinkErrorClose = () => {
    this.setState({
      hyperLinkError: false
    })
  }
  handleSuccessCloseIcon = () => {
    this.setState({
      ideaAdded: false,
    })
  }

  navigateToPage = (value: boolean, navPath: string) => {
    const msg: Message = new Message(getName(MessageEnum.NewPostIdeaMessage));
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationRaiseMessage), value);
    msg.addData(getName(MessageEnum.NavigationTargetMessage), navPath);
    this.send(msg);
  }

  removeIconFun = (imgLocation: string) => {
    this.removeImage(imgLocation);
    if (imgLocation === "context") {
      this.setState({ openPlusDialogContent: false });
    } else if (imgLocation === "problem") {
      this.setState({ openPlusDialogProblem: false });
    } else {
      this.setState({ openPlusDialogSolution: false });
    }
  }

  problemTextFun = (e: any) => {
    this.setState(
      { ProblemText: e.target.value.substring(0, 440) },
      () => {
        this.ErrorCheckInProblemAddIdea();
      }
    );
  }

  addNewideaDropdownFun = (event: any) => {
    setTimeout(() => {
      this.setState({
        PrivacySelectAddNewIdea: event.target.value,
      });
    }, 200);
  }

  handleContextUndo = () => this.handleUndoChange("context");
  handleProblemUndo = () => this.handleUndoChange("problem");
  handleSolutionUndo = () => this.handleUndoChange("solution");

  handleCntxtRemoveIcon = () => this.removeIconFun("context");
  handleProbRemoveIcon = () => this.removeIconFun("problem");
  handleSolRemoveIcon = () => this.removeIconFun("solution");

  handleUndoChange = (title: string) => {
    switch (title) {
      case "context":
        return this.setState({ ContentText: "" })
      case "problem":
        return this.setState({ ProblemText: "" })
      case "solution":
        return this.setState({ SolutionText: "" })
    }

  }

  handleContextTextFun = (e: any) => {
    this.setState(
      { ContentText: e.target.value.substring(0, 440) },
      () => {
        this.ErrorCheckInContentAddIdea();
      }
    );
  }

  getImage = (imgText: string) => {
    switch (imgText) {
      case "Private":
        return LockIcon;
      case "Contacts":
        return GroupIcon;
      case "Platform":
        return WorldIcon;
      default:
        return GroupIcon;
    }
  };

  checkContextMedia = () => {
    return (this.state.contextImagePreview ||
      this.state.contextVideoUpload ||
      this.state.ContentTextWithHyperLink) === null
  }

  checkProblemMedia = () => {
    return (this.state.problemImagePreview ||
      this.state.problemVideoUpload ||
      this.state.ProblemTextWithHyperLink) === null
  }

  checkSolutionMedia = () => {
    return (this.state.solutionImagePreview ||
      this.state.solutionVideoUpload ||
      this.state.SolutionTextWithHyperLink) === null
  }
}

AddNewIdeaController.contextType = UserDetailsContext;
// Customizable Area End