// Customizable Area Start
import React from "react";
import {
  Dialog,
  IconButton,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@material-ui/core";
import {PopupCloseIcon} from "./assets";

const ActivityPopup = (props: any) => {
 
  const {
    open,
    title,
    popupDescription,
    leftBtnTxt,
    rightBtnTxt,
    handleLeftBtn,
    handleRightBtn
  } = props;

  return (
    <Dialog
      open={open}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      className="hiddeniterationPopupContainer"
    >
      <IconButton
        edge="end"
        color="inherit"
        aria-label="close"
        className="close_icon_popup"
        onClick={handleLeftBtn}
      >
        <PopupCloseIcon />
      </IconButton>
      <DialogTitle id="scroll-dialog-title">{title}</DialogTitle>

      <DialogContent style={!popupDescription ? { display: "none" } : {}}>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          {popupDescription}
        </DialogContentText>
      </DialogContent>

      <DialogActions
        className="popUpAction"
        style={!leftBtnTxt ? { display: "none" } : {}}
      >
        {leftBtnTxt && <Button data-testid={"leftBtn"} onClick={handleLeftBtn}>{leftBtnTxt}</Button>}
        {rightBtnTxt && <Button data-testid={"rightBtn"} onClick={handleRightBtn}>{rightBtnTxt}</Button>}
      </DialogActions>
    </Dialog>
  );
};
export default ActivityPopup;

// Customizable Area End
