import React from "react";

// Customizable Area Start
import { Typography } from "@material-ui/core";
import "./AdvancedSearch.css";
import { withRouter } from "react-router";
import moment from "moment";
moment.locale("de");
import AdvancedSearchControllerWeb, { Props } from "./AdvancedSearchController.web";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomTypography from "../../../components/src/CustomTypography";
import CustomNoResult from "../../../components/src/CustomNoResult";
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import {
  NotifyOnIcon,
  NotifyOffIcon,
  VisibilityOnIcon,
  VisibilityOffIcon,
  ReportOnIcon,
  ReportOffIcon,
} from "./assets";
import AdvanceSearchBox from "../../../components/src/AdvanceSearchBox.web";
import ActivityPopup from "../../ActivityFeed/src/ActivityPopup.web";


// Customizable Area End

class AdvancedSearch extends AdvancedSearchControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.assignState();    
  }
  async componentDidUpdate() {
    /* async method 'componentDidUpdate' is empty */
  }


  render() {
    const postLength = this.props?.location?.state?.totalCount;
    this.assignStateInRender();

    return (
      <>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <div id="scrollableDiv">
          {/* @ts-ignore */}
          <InfiniteScroll
            dataLength={this.state.posts?.length}
            next={this.handleFetchMoreData}
            hasMore={postLength / 5 > this.state.currentPage}
            scrollableTarget="scrollableDiv"
            endMessage={<Typography></Typography>}
          >
            {this.state.posts?.length > 0 ? (
              <>
                <CustomTypography text="search results" />
                {this.state.posts?.map((result: any, index: any) => {
                  const id = result.id;
                  const respData = result.attributes.add_ideas.data.attributes;
                  const contextkeyValue = respData?.idea_context?.data?.attributes?.description;
                  const problemkeyValue = respData?.idea_problem?.data?.attributes?.description;
                  const solutionKeyValue = respData?.idea_solution?.data?.attributes?.description;
                  const daysDiff = moment().diff(
                    moment(
                      [respData.created_at as string],
                      "DD.MM.YYYY, hh:mm"
                    ),
                    "days"
                  );
                  const weekDiff = moment().diff(
                    moment(
                      [respData.created_at as string],
                      "DD.MM.YYYY, hh:mm"
                    ),
                    "weeks"
                  );
                  const propKeyword =
                    this.props.location?.state?.keyword?.toLowerCase();
                  const propSearchValue =
                    this.props.location?.state?.searchValue?.toLowerCase();
                  const propHashTag =
                    this.props.location?.state?.hashtag?.toLowerCase();

                  const dayFormat = moment(
                    [respData.created_at as string],
                    "DD.MM.YYYY, hh:mm Z"
                  ).format("dddd");
                  const yearFormat = moment(
                    [respData.created_at as string],
                    "DD.MM.YYYY, hh:mm "
                  ).format("DD.MM.YYYY");

                  const propTime = this.getDateData(
                    weekDiff,
                    daysDiff,
                    dayFormat,
                    yearFormat
                  );

                  let propSearchWord: any;

                  propSearchWord =
                    sessionStorage.getItem("searchViaAdvanceSearch") == "true"
                      ? propKeyword
                      : propSearchValue;

                  const headerKeyFound = respData.title
                    .toLowerCase()
                    .includes(propSearchWord);
                  const contextkeyFound =
                    contextkeyValue
                      .toLowerCase()
                      .includes(propSearchWord);
                  const probKeyFound =
                    problemkeyValue
                      .toLowerCase()
                      .includes(propSearchWord);
                  const solKeyFound =
                    solutionKeyValue
                      .toLowerCase()
                      .includes(propSearchWord);
                  const respHashTagList = respData.tag_list;
                  const hashTagFound = (tag: any) => {
                    return (
                      propSearchWord?.includes(tag?.toLowerCase()) ||
                      propHashTag?.includes(tag.toLowerCase())
                    );
                  };
                  const keywordInTags = respHashTagList?.findIndex(
                    (item: any) => {
                      return (
                        item?.toLowerCase() === propSearchWord?.toLowerCase()
                      );
                    }
                  );
                  const parentLink =
                    result?.attributes?.add_ideas?.data?.attributes?.add_ideas
                      ?.data;
                  const parentLinkId = parentLink[parentLink?.length - 1]?.id;
                  const navLink = result?.id;

                  return (
                    <React.Fragment key={id}>
                      <AdvanceSearchBox
                        key={id}
                        postId={id}
                        respData={respData}
                        headerKeyFound={headerKeyFound}
                        navLink={navLink}
                        keywordInTags={keywordInTags}
                        parentLinkId={parentLinkId}
                        respHashTagList={respHashTagList}
                        hashTagFound={hashTagFound}
                        solKeyFound={solKeyFound}
                        probKeyFound={probKeyFound}
                        contextkeyFound={contextkeyFound}
                        propTime={propTime}

                        notificationHandleOnClick={() => this.changeNotification(respData.is_notify, respData?.parent_id)}
                        notificationButtonIcon={this.getVariable(respData.is_notify, NotifyOnIcon, NotifyOffIcon)}
                        notificationHoverText={this.getVariable(respData.is_notify, "Notifications ON", "Notifications OFF")}

                        visibilityHandleOnClick={() => this.handleVisibility(respData?.is_hidden, id)}
                        visibilityButtonIcon={this.getVariable(respData.is_hidden, VisibilityOffIcon, VisibilityOnIcon)}
                        visibilityHoverText={this.getVariable(respData.is_hidden, "Show iteration OFF", "Show iteration ON")}

                        reportHandleOnClick={() => this.handleReportIdea(respData?.is_reported, id)}
                        reportButtonIcon={this.getVariable(respData.is_reported, ReportOnIcon, ReportOffIcon)}
                        reportHoverText={this.getVariable(respData.is_reported, "Report idea ON", "Report idea OFF")}
                      />
                    </React.Fragment>
                  );
                })}
              </>
            ) : (
              <CustomNoResult text="No Search Results Found" />
            )}
          </InfiniteScroll>
        </div>
        <ActivityPopup
          testId="submitpopup"
          open={this.state.isAlertOpen}
          title={this.state.alertMessageText}
          leftBtnTxt="ok"
          handleLeftBtn={this.handleCloseAlertMessagePopup}
        />
      </>
      // Customizable Area End
    );
  }
}
// Customizable Area Start
export { AdvancedSearch };
// Customizable Area End

//@ts-ignore
export default withRouter(AdvancedSearch);
// export default  (withRouter(AdvancedSearch)) as React.ComponentType<any>
