Object.defineProperty(exports, "__esModule", {
  value: true,
});
exports.labelAdvancedSearch = "ADVANCED SEARCH";
exports.labelUser = "User(s) ";
exports.labelHashtag = "Hashtag(s) ";
exports.labelKeyword = "Keyword(s) ";
exports.labelDate = "Date";
exports.labelFilters = "Filters";
exports.labelComplex = "Complex";
exports.labelPopular = "Popular";
exports.labelContested = "Contested";
exports.labelMedia = "Media";
exports.labelSearch = "Search";
exports.placeholderUsername = "Type username...";
exports.placeholderHashtag = "Add hashtag...";
exports.placeholderKeyword = "Add keyword...";
exports.placeholderStartDay = "Start day";
exports.placeholderEndDay = "End day";
exports.labelSearch = "Search";
exports.labelIdeaLibrary = "Idea Library";
exports.labelSavedIdeas = "Saved Ideas";
exports.labelDay = "Day";
exports.labelMonth = "Month";
exports.labelYear = "Year";
exports.copyrightText = "© 2021-2023 IDEA ATLAS LTD. All rights reserved.";
exports.btnSignOut = "Sign Out";
