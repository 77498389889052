// Customizable Area Start
import React from 'react';
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {
  AddContactIcon,
  RemoveContact,
} from "./assets";

export const configJSON = require("./config");
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  bannerFromBackend?: any;
  reportedUser?: boolean;
  userName?: string;
  firstName?: string;
  lastName?: string;
  userEmail?: string;
  hiddenUser?: boolean;
  userProfilePic?: any;
  profileId?: any;
  handleSendFriendRequest?: any;
  requestSentArray?: any;
  reportUserValue?: boolean;
  userIdeaVisibility?: boolean;
  description?: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  isLoading: boolean;
  bannerFromBackend: null | string;
  firstName: any;
  lastName: any;
  userName: string;
  userEmail: string;
  profilePicFromBackend: null | string;
  isGridView: boolean;
  allContacts: any;
  dupContacts: any;
  searchTerm: string;
  contactsCount: number;
  reportedUser: boolean;
  hiddenUser: boolean;
  userProfilePic: null | string;
  currentUsername: any,
  ideaVisibility: boolean | undefined;
  isAlertOpen: boolean;
  alertMessageText: string;

  reportContactAPIResponse: any;
  hideIdeasAPIResponse: any;
  description: string| undefined;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class UserProfileCardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userProfileReportCallID: any;
  userIdeaVisibiltyCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      isLoading: false,
      bannerFromBackend: null,
      firstName: "",
      lastName: "",
      userName: '',
      userEmail: '',
      profilePicFromBackend: null,
      isGridView: true,
      allContacts: [],
      dupContacts: [],
      searchTerm: "",
      contactsCount: 0,
      reportedUser: false,
      hiddenUser: false,
      userProfilePic: null,
      currentUsername: "",
      ideaVisibility: false,
      isAlertOpen: false,
      alertMessageText: "",
      reportContactAPIResponse: null,
      hideIdeasAPIResponse: null,
      description: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) {
      return;
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    if (apiRequestCallId && responseJson) {
      switch (apiRequestCallId) {
        case this.userProfileReportCallID:
          this.userProfileReportAPIResponse(responseJson)
          break;
        case this.userIdeaVisibiltyCallID:
          this.userIdeaVisibilityAPIResponse(responseJson)
          break;
        default:
          break;
      }
    }
  }

  userProfileReportAPIResponse(responseJson: any) {
    this.setState({ reportContactAPIResponse: responseJson });
    console.log('san userProfileReportAPIResponse responseJson', responseJson);
    
    if (!responseJson.errors && responseJson.status !== 500) {
      if(responseJson?.meta?.message === "Account reported successfully"){
        this.setState({
          isAlertOpen: true,
          alertMessageText: "Contact reported successfully."
        })
      }else{
        this.setState({
          isAlertOpen: true,
          alertMessageText: "Contact unreported successfully."
        })
      }
      setTimeout(() => {
        window.location.reload();
        this.setState({ isLoading: false })
      }, 2000)
    } else {
      this.setState({
        isLoading: false,
        isAlertOpen: true,
        alertMessageText: "Unable to report contact."
      })
    }
  }

  userIdeaVisibilityAPIResponse(responseJson: any) {
    this.setState({ hideIdeasAPIResponse: responseJson })
    if (!responseJson.errors && responseJson.status !== 500 && responseJson.status !== 404) {
      if (responseJson?.message === "Hiden account  successfully remove") {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "User Ideas have been unhided."
        })
      }
      if (responseJson?.message === "already hide account") {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "User Ideas have already been hidden."
        })
      }
      if (responseJson?.meta?.message === "Account hiden successfully") {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "User Ideas have been hidden successfully."
        })
      }
      setTimeout(() => {
        window.location.reload();
        this.setState({ isLoading: false })
      }, [1000])

    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Unable to hide/unhide user ideas."
      })
    }
  }

  reportUserProfile = (ideaID: number, value: any) => {
    this.setState({ isLoading: true })
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileReportCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportProfileEndPoint + `/${ideaID}?status=${value}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  userIdeaVisibility = (ideaID: number, ideaStatus: string) => {
    this.setState({ isLoading: true })
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userIdeaVisibiltyCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ideaVisibilityEndPoint + `/${ideaID}?status=${ideaStatus}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getContactIcon = (requestSentArray: any, profileId: any) => {
    return !requestSentArray?.includes(String(profileId))
      ? <AddContactIcon />
      : <RemoveContact />

  }

  displayUserEmail = (requestSentArray: any, profileId: any, userEmail: any) =>{
    
    if(userEmail === localStorage.getItem("email")){
      return `| ${userEmail}`
    }
    return !requestSentArray?.includes(String(profileId)) ? "" : `| ${userEmail}` 
  }

  getContactStatusText = (requestSentArray: any, profileId: any) => {
    return !requestSentArray?.includes(String(profileId))
      ? "Add Contact"
      : "Remove from contacts"
  }

  handleCloseAlertMessagePopup = () => {
    this.setState({
      isAlertOpen: false,
      alertMessageText: ""
    })
  }
  // Customizable Area End
}
// Customizable Area End