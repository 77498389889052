// Customizable Area Start
import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
  Checkbox,
  TextField,
  ClickAwayListener,
  Avatar,
  Box,
  FormControlLabel
} from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import CloseIcon from "@material-ui/icons/Close";
import { Input } from "reactstrap";
import "../../../components/src/Common.web.css";
import "./AdvancedSearch.css";
//@ts-ignore
import { ReactComponent as CustomCheckBox } from "../assets/CustomCheckBox.svg";
//@ts-ignore
import { ReactComponent as Unchecked } from "../assets/unchecked.svg";
import AdvancedSearchControllerWeb from "./AdvancedSearchController.web";

const configJSON = require("./config.js");
import { withRouter } from "react-router";
//@ts-ignore

import Chip from "@material-ui/core/Chip";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { createStyles, withStyles } from "@material-ui/core/styles";
import {
  Delete,
  noProfileImg,
  advancedFilter,
  searchIcon,
  searchIconBlack
} from "./assets";
import CustomCalendar from "./CustomCalendar.web";
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";
//@ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";

const styles = () =>
  createStyles({
    root: {
      backgroundColor: "red !important"
    },
    chipPic: {
      backgroundColor: "red",
      height: "20px",
      width: "20px"
    }
  });

const StyledChip = withStyles({
  root: {
    color: "white",
    backgroundColor: `#022540 !important`,

    "&:active": {
      boxShadow: "none",
      backgroundColor: "white",
      borderColor: "white"
    }
  },
  outlined: {
    color: "white",
    border: `1px solid ${"white"}`,
    backgroundColor: `transparent !important`
  },
  icon: {
    color: "white"
  }
})(Chip);

class CustomSearch extends AdvancedSearchControllerWeb {
  constructor(props: any) {
    super(props);
    //@ts-ignore
    this.myRef = React.createRef();
  }

  async componentDidUpdate(prevProps: any, prevState: any) {
    if (
      this.state.filterPopular !== prevState.filterPopular ||
      this.state.filterContested !== prevState.filterContested ||
      this.state.filterMedia !== prevState.filterMedia
    ) {
      sessionStorage.removeItem("hashTagsString");
    }
  }
  
  render() {
    return (
      <>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <div className="searchBarContainer">
          <img
            data-testid="settingsIcon"
            src={advancedFilter}
            className="settingsIcon"
            onClick={() => {
              this.handleAdvancedSearchIconClick();
              sessionStorage.clear();
              this.clearUserName();
            }}
          />
          <InputBase
            inputProps={{ "aria-label": "search" }}
            data-testid="cssearchBar"
            className="searchBar"
            value={this.state.searchValue}
            onChange={this.handleSearchValueInput}
            onKeyPress={(event: any) => {
              if (this.onCheckEqual(event.key, this.state.searchValue.trim())) {
                this.handleSearchBarSubmit();
              }
            }}
          />
          <button
            data-testid="cssearchBtn"
            className={this.conditionalRenderFun(
              this.state.searchValue,
              "searchIconOutline",
              "searchIconBtn"
            )}
            disabled={this.isNormalSearchValid()}
            onClick={() => this.handleSearchBarSubmit()}
          >
            <img
              src={this.conditionalRenderFun(
                this.state.searchValue,
                searchIconBlack,
                searchIcon
              )}
              className="searchIcon"
            />
          </button>
        </div>
        {this.state.searchOpen && (
          <Dialog
            open={this.state.searchOpen}
            onClose={() => {
              this.setState({ searchOpen: false });
              this.clearUserName();
            }}
            aria-describedby="customized-dialog-title"
            className="searchPopUpContainer"
          >
            <IconButton
              edge="end"
              color="inherit"
              onClick={() => this.setState({ searchOpen: false })}
              aria-label="close"
              className="close-icon searchClose"
            >
              <CloseIcon />
            </IconButton>
            <DialogTitle id="customized-dialog-title" className="searchTitle">
              {configJSON.labelAdvancedSearch}
            </DialogTitle>
            <DialogContent>
              <section className="searchContent">
                <label className="searchLabel">{configJSON.labelUser}</label>
                <div
                  className="autoCompleteWrapper"
                  id="thisContainsAutocomplete"
                >
                  <Autocomplete
                    multiple
                    id="userNameBox"
                    disableClearable
                    fullWidth
                    filterSelectedOptions
                    freeSolo
                    disablePortal
                    selectOnFocus
                    clearOnBlur
                    open
                    options={this.state.userNameList}
                    getOptionLabel={option => option.attributes.user_name}
                    renderOption={option => {
                      return (
                        !this.checkIfDataExists(
                          option.attributes.user_name
                        ) && (
                          <Box
                            className="autoCompleteBox"
                            onClick={() => {
                              this.setState({ userNameList: [] });
                            }}
                          >
                            <img
                              src={this.isRenderIfElseCondition(
                                option.attributes.image
                                  ,baseURL + option.attributes.image
                                  , noProfileImg)
                              }
                              alt=""
                              className="autoCompleteImg"
                            />
                            {option.attributes.user_name}
                          </Box>
                        )
                      );
                    }}
                    onClose={() => {
                      this.state.userName.length > 0 &&
                        this.setState({ userNameList: [] });
                      this.clearAddChip();
                    }}
                    onChange={(event, value) => {
                      this.handleUserNameChange(value);
                    }}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => {
                        return (
                          <StyledChip
                            avatar={
                              <Avatar
                                alt="profile"
                                src={this.isRenderIfElseCondition(
                                  option.attributes.image
                                    , baseURL + option.attributes.image
                                    , noProfileImg)
                                }
                              />
                            }
                            variant="default"
                            label={option.attributes.user_name}
                            deleteIcon={<Delete />}
                            {...getTagProps({ index })}
                          />
                        );
                      });
                    }}
                    renderInput={params => (
                      <TextField
                        {...params}
                        id=""
                        placeholder={configJSON.placeholderUsername}
                        type="text"
                        value={this.state.userName}
                        onChange={(e: any) => {
                          this.setState({ userName: e.target.value });
                          this.setState({ openUserSuggestion: true });
                          e.target.value.length > 0
                            ? this.getUserNames(e.target.value)
                            : this.setState({ userNameList: [] });
                          this.getUserNames(e.target.value);
                        }}
                        InputProps={{
                          ...params.InputProps,
                          disableUnderline: true
                        }}
                      />
                    )}
                  />
                </div>
              </section>
              <section className="searchContent" style={{ marginTop: 20 }}>
                <label className="searchLabel">{configJSON.labelHashtag}</label>
                <Input
                  id="txtInputLastname"
                  placeholder={configJSON.placeholderHashtag}
                  type="text"
                  autoComplete="off"
                  value={this.state.hashtag}
                  onChange={(e: any) => {
                    this.setState({ hashtag: e.target.value });
                  }}
                />
              </section>
              <section className="searchContent">
                <label className="searchLabel">{configJSON.labelKeyword}</label>
                <Input
                  id="txtInputLastname"
                  placeholder={configJSON.placeholderKeyword}
                  type="text"
                  autoComplete="off"
                  value={this.state.keyword}
                  onChange={(e: any) => {
                    this.setState({ keyword: e.target.value.replace(/[!&%;]/g, "") });
                  }}
                />
              </section>

              <section
                className="searchContent customCalendar"
                id="AdvSeachCalendar"
              >
                <label className="searchLabel">{configJSON.labelDate}</label>
                <div
                  className={`daysContainer ${this.state.anchorEl1 &&
                    "calOpen"}`}
                >
                  <ClickAwayListener
                    onClickAway={(e: any) => this.closeCalendar1()}
                  >
                    <div>
                      <input
                        readOnly
                        className="searchDateInput"
                        placeholder={configJSON.placeholderStartDay}
                        id="calStartDate"
                        onClick={e => this.handleClick1()}
                        value={
                          this.state.startDay &&
                          this.state.startMonth &&
                          this.state.startYear &&
                          `${this.state.startDay} - ${this.state.monthsValue[this.state.startMonth]
                          } - ${this.state.startYear}`
                        }
                      />
                      <span className="dropdownArrow" />
                      {this.state.anchorEl1 && (
                        <CustomCalendar
                          calDay={this.state.startDay}
                          calMonth={this.state.startMonth}
                          calYear={this.state.startYear}
                          monthsValue={this.state.monthsValue}
                          handleDate={this.handleStartDate}
                          handleMonth={this.handleStartMonth}
                          handleYear={this.handleStartYear}
                          isDateError={this.state.isDateError}
                          isYearError={this.state.isYearError}
                        />
                      )}
                    </div>
                  </ClickAwayListener>

                  <ClickAwayListener
                    onClickAway={(e: any) => this.closeCalendar2()}
                  >
                    <div>
                      <input
                        readOnly
                        className="searchDateInput"
                        placeholder={configJSON.placeholderEndDay}
                        onClick={e => this.handleClick2()}
                        value={
                          this.state.endDay &&
                          this.state.endMonth &&
                          this.state.endYear &&
                          `${this.state.endDay} - ${this.state.monthsValue[this.state.endMonth]
                          } - ${this.state.endYear}`
                        }
                      />
                      <span className="dropdownArrow" />
                      {this.state.anchorEl2 && (
                        <CustomCalendar
                          calDay={this.state.endDay}
                          calMonth={this.state.endMonth}
                          calYear={this.state.endYear}
                          monthsValue={this.state.monthsValue}
                          handleDate={this.handleEndDate}
                          handleMonth={this.handleEndMonth}
                          handleYear={this.handleEndYear}
                          isDateError={this.state.isDateError}
                          isYearError={this.state.isYearError}
                        />
                      )}
                    </div>
                  </ClickAwayListener>
                </div>
              </section>

              <section className="searchContent">
                <label className="searchLabel optionsLabel">
                  {configJSON.labelFilters}
                </label>
                <div className="searchCheckBoxContainer">
                  <FormControlLabel
                    className="searchCheckText"
                    control={
                      <Checkbox
                        className="searchCheckbox"
                        checkedIcon={<CustomCheckBox />}
                        icon={<Unchecked />}
                        checked={this.state.filterPopular}
                        onChange={() => {
                          this.setState({
                            filterPopular: !this.state.filterPopular
                          });
                        }}
                        onKeyPress={event => {
                          if (event.key === "Enter") {
                            this.setState({
                              filterPopular: !this.state.filterPopular
                            });
                          }
                        }}
                      />
                    }
                    label={configJSON.labelPopular}
                  />
                  <FormControlLabel
                    className="searchCheckText"
                    control={
                      <Checkbox
                        className="searchCheckbox"
                        checkedIcon={<CustomCheckBox />}
                        icon={<Unchecked />}
                        checked={this.state.filterContested}
                        onChange={() => {
                          this.setState({
                            filterContested: !this.state.filterContested
                          });
                        }}
                        onKeyPress={event => {
                          if (event.key === "Enter") {
                            this.setState({
                              filterContested: !this.state.filterContested
                            });
                          }
                        }}
                      />
                    }
                    label={configJSON.labelContested}
                  />
                  <FormControlLabel
                    className="searchCheckText"
                    control={
                      <Checkbox
                        className="searchCheckbox"
                        checkedIcon={<CustomCheckBox />}
                        icon={<Unchecked />}
                        checked={this.state.filterMedia}
                        onChange={() => {
                          this.setState({
                            filterMedia: !this.state.filterMedia
                          });
                        }}
                        onKeyPress={event => {
                          if (event.key === "Enter") {
                            this.setState({
                              filterMedia: !this.state.filterMedia
                            });
                          }
                        }}
                      />
                    }
                    label={configJSON.labelMedia}
                  />
                </div>
              </section>
            </DialogContent>
            <DialogActions className="submitButton searchBtn">
              <Button
                onClick={() => {
                  this.handleAdvanceSearchSubmit(
                    this.covertUserNamesToString(this.suggestedUserName)
                  );
                  this.clearAddChip();
                }}
                disabled={!this.isAdvanceSearchValid()}
              >
                {configJSON.labelSearch}
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </>
    );
  }
}

//@ts-ignore
export default withStyles(styles)(withRouter(CustomSearch));
export { CustomSearch };
// Customizable Area End
