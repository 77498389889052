import React from "react";
import "./FriendsList.css";
import { magnifier, removeContact, hide_idea_icon,hide_idea_icon_Gray, danger, reportBtn } from "./assets";
import SettingsToolTip from "./SettingsToolTip";
import { Box, Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import FriendListControllerWeb, { Props } from "./FriendListController.web";
const configJSON = require("./config.js")

export default class FriendPageList extends FriendListControllerWeb {
  constructor(props: Props) {
    // Customizable Area Start
    // Customizable Area End
    super(props);
  }
  render() {
    // Customizable Area Start
    return (
      <>
        {(!this.props.reportedStatus && !this.props.hiddenStatus) &&
          <section className="contactList">
            <img src={this.props.friendProfilePic} className="contactListPicImg" />
            <div className="conatctGroupNames">
              <p className="contactListName">{this.props.friendFullName}</p>
              <p className="contactListNickName">{this.props.friendUserName} | {this.props.friendEmail} </p>
            </div>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
            <div className="cntListSettings">
              <Link to={`UserProfile/${this.props.friendIdeasId}`} className="cntIdeas">
                <div className="ideasContainer" >
                  <img src={magnifier} className="magnifier" />
                  Ideas
                </div>
              </Link>
              <Button className="removeContactContainer" onClick={this.props.handleRemoveContact}>
                <img src={removeContact} className="removeCntImg" />
              </Button>
              <div className="contactSettingsContainer w-15">
                <SettingsToolTip
                  testId="reportUserButton"
                  reportID={this.props.reportID}
                  reportUserProfileFun={this.props.reportUserProfileFun}
                  ideaVisibilityOnFun={this.props.ideaVisibilityOnFun}
                  ideaVisibilityOffFun={this.props.ideaVisibilityOffFun}
                  hiddenStatus={this.props.hiddenStatus}
                  reportedStatus={this.props.reportedStatus}
                />
              </div>
            </div>
          </section>
        }
        {this.displayHiddenUser(this.props.selectedContactListType, this.props.hiddenStatus , this.props.reportedStatus) && 
          <section className="hiddenContactList">
            <div className="hiddedIconAndProfileImg">
              <img src={this.props.friendProfilePic} className="contactListPicImg hiddenContactListPicImg" />
              <img src={hide_idea_icon_Gray} className="reportIconlist" />
            </div>
            <div className="conatctGroupNames hiddenContactGroupNames">
              <p className="hiddenHeading">{configJSON.hiddenUser}</p>
              <p className="contactListName hiddenContactListName">{this.props.friendFullName}</p>
              <p className="contactListNickName hiddencontactListNickName">{this.props.friendUserName} | {this.props.friendEmail}</p>
            </div>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
            <div className="cntListSettings">
              <Link to={`UserProfile/${this.props.friendIdeasId}`} className="cntIdeas">
                <div className="ideasContainer" >
                  <img src={magnifier} className="magnifier" />
                  Ideas
                </div>
              </Link>
              <Button id="removeContactBtn" className="removeContactContainer" onClick={this.props.handleRemoveContact}>
                <img src={removeContact} className="removeCntImg" />
              </Button>
              <div className="contactSettingsContainer w-15 hiddenUserSetting">
                <SettingsToolTip
                  testId="unHideUserButton"
                  reportID={this.props.reportID}
                  reportUserProfileFun={this.props.reportUserProfileFun}
                  ideaVisibilityOnFun={this.props.ideaVisibilityOnFun}
                  ideaVisibilityOffFun={this.props.ideaVisibilityOffFun}
                  displayIcon={hide_idea_icon}
                  displayIconStyle="removeCntImg"
                  hiddenStatus={this.props.hiddenStatus}
                  reportedStatus={this.props.reportedStatus}
                />
              </div>
            </div>
          </section>}
        {this.displayReportedUser(this.props.selectedContactListType, this.props.reportedStatus) &&
          <section className="hiddenContactList">
            <div className="hiddedIconAndProfileImg">
              <img src={this.props.friendProfilePic} className="contactListPicImg hiddenContactListPicImg" />
              <img src={danger} className="reportIconlist" />
            </div>
            <div className="conatctGroupNames hiddenContactGroupNames">
              <p className="hiddenHeading reportedHeading">{configJSON.reportedUser}</p>
              <p className="contactListName hiddenContactListName">{this.props.friendFullName}</p>
              <p className="contactListNickName hiddencontactListNickName">{this.props.friendUserName} | {this.props.friendEmail}</p>
            </div>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }} />
            <div className="cntListSettings">
              <Button id="unReportContactBtn" className="btnReportedUser" onClick={this.props.unReportUserProfileFn}>
                <img src={reportBtn} className="removeCntImg" /> <span className="txtReportedBtn">{configJSON.textReportedUserButton}</span>
              </Button>
            </div>
          </section>}
      </>
    );
    // Customizable Area End
  }
};