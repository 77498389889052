 // Customizable Area Start
 import React, { Component } from 'react'
 import { Box, Button, Tooltip } from "@material-ui/core";
 import {
   Eyeclose,
   ViewIdea,
   HideIdea,
   Dotdotdot,
   ReportOn,
   ReportOff,
 } from "./assets";
 
 type Props = {
   hiddenUserIcon:boolean;
   ideaVisibilityOnFun:any;
   reportUserProfileFun:any;
   ideaVisibleBoolean?:any;
   feedFilter:string;
   reportUserValue:boolean | undefined;
   testId: string;
 }
 
 type State = {
   visibility:boolean;
   report:boolean;
 }
 class UserProfileTooltip extends Component<Props, State> {
 
   constructor(props: Props) {
     super(props);
     // Customizable Area Start
     this.state={
       visibility:false,
       report:false
     }
     // Customizable Area End
 }
 
 async componentDidMount() {
   this.setState({
          visibility: this.props.ideaVisibleBoolean,
          report : this.props.reportUserValue || true
   })
 }
 
  changeVisibilty = () => {
   this.setState({visibility: !this.state.visibility})
     if(this.props.ideaVisibilityOnFun){
       this.props.ideaVisibilityOnFun();
     }
   };
 
   changeReport = () => {
   this.setState({report: !this.state.report})
     if(this.props.reportUserProfileFun){
       this.props.reportUserProfileFun()
     }
   };
 render(){
   const {report} = this.state;
   let {hiddenUserIcon} = this.props;
   return(
     <div>
     <Tooltip
       PopperProps={{
         disablePortal: true,
         popperOptions: {
           modifiers: {
             offset: {
               enabled: true,
               offset: "0px, 4px",
             },
           },
           tooltipPlacementRight: {
             right: "1px",
             top: "80px",
           },
         },
       }}
       classes={{
         tooltip: "settingTooltip",
         arrow: "menu-pop-up-arrow",
       }}
       interactive={true}
       title={
         <React.Fragment>
           <Box component="span" m={1} className="box-body">
             <Box
               className="tooltiptitle"
               id="title"
               onClick={() => {}}
               style={{
                 flexDirection: "column",
                 textTransform: "uppercase",
               }}
             >
               <Box className={"userProfileDropDownBox"}>
                 <p className="userTooltipoption">
                   <Button onClick={this.changeVisibilty} className="tipButton">
                     {this.props.ideaVisibleBoolean ? (
                       <>
                         <ViewIdea />
                         <p className="tipNameUserTooltip">Show user iterations ON</p>
                       </>
                     ) : (
                       <>
                         <HideIdea />
                         <p className="tipNameUserTooltip1">Show user iterations OFF</p>
                       </>
                     )}
                   </Button>
                 </p>
               </Box>
 
               <Box className={"userProfileDropDownBox"}>
                 <p className="userTooltipoption">
                   <Button onClick={this.changeReport} className="tipButton">
                     {report ? (
                       <>
                         <ReportOn />
                         <p className="tipNameUserTooltip">Report profile ON</p>
                       </>
                     ) : (
                       <>
                         <ReportOff />
                         <p className="tipNameUserTooltip">Report profile OFF</p>
                       </>
                     )}
                   </Button>
                 </p>
               </Box>
             </Box>
           </Box>
         </React.Fragment>
       }
       arrow={true}
       placement="right-start"
     >
       <div className={hiddenUserIcon ? "hiddenIconSettings" : "iconThreeDotuser"}>
        {hiddenUserIcon ? <Eyeclose /> :
         <Dotdotdot />
        }
       </div>
     </Tooltip>
   </div>
   )
 }
 }
 
 export default UserProfileTooltip;
 // Customizable Area End