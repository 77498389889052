import React from "react";
import { Box, Button, Tooltip } from "@material-ui/core";
import {
  ViewIdea,
  HideIdea,
  ReportOn,
  ReportOff,
} from "./assets";

const SettingsContent = (props: any) => {
  return (
    <Box component="span" m={1} className="box-body">
      <Box
        className="title-box"
        id="title-box"
        style={{
          flexDirection: "column",
          textTransform: "uppercase",
        }}
      >
        <Box className={"feedFilterDropDownBox"}>
          <p className="menu-title toolTipNames">
            <Button onClick={props.changeVisibilty} className="tipButton">
              {!props.hiddenStatus ? (
                <>
                  <ViewIdea />
                  <p className="tipName">Show user iterations ON</p>
                </>
              ) : (
                <>
                  <HideIdea />
                  <p className="tipName">Show user iterations OFF</p>
                </>
              )}
            </Button>
          </p>
        </Box>

        <Box className={"feedFilterDropDownBox"}>
          <p className="menu-title toolTipNames">
            <Button onClick={props.changeReport} className="tipButton" id="reportUserButton">
              {props.reportedStatus ? (
                <>
                  <ReportOn />
                  <p className="tipName">Report Profile ON</p>
                </>
              ) : (
                <>
                  <ReportOff />
                  <p className="tipName">Report Profile OFF</p>
                </>
              )}
            </Button>
          </p>
        </Box>
      </Box>
    </Box>
  )
}


const SettingsToolTip = (props: any) => {
  const changeVisibilty = () => {
    if (!props.hiddenStatus) {
      props.ideaVisibilityOffFun(props.reportID, "true");
    } else {
      props.ideaVisibilityOnFun(props.reportID, "false");
    }
  };

  const changeReport = () => {
    console.log('san reportedStatus', props.reportedStatus);
    if (!props.reportedStatus) {
      props.reportUserProfileFun(props.reportID);
    }
  };
  return (
    <div>
      <Tooltip
        PopperProps={{
          disablePortal: true,
          popperOptions: {
            modifiers: {
              offset: {
                enabled: true,
                offset: "0px, 20px",
              },
            },
            tooltipPlacementRight: {
              right: "1px",
              top: "80px",
            },
          },
        }}
        classes={{
          tooltip: "menu-pop-up",
          arrow: "menu-pop-up-arrow",
        }}
        interactive={true}
        title={
          <SettingsContent
            changeVisibilty={changeVisibilty}
            changeReport={changeReport}
            hiddenStatus={props.hiddenStatus}
            reportedStatus={props.reportedStatus} />
        }
        arrow={true}
        placement="right"
      >
        <div className="feedSettings">
          {props.displayIcon ?
            <img src={props.displayIcon} className={props.displayIconStyle} />
            : <p className="feedSettingsIcon">...</p>}
        </div>
      </Tooltip>
    </div>
  );
};

export default SettingsToolTip;
export { SettingsToolTip }
