// Customizable Area Start
import React from "react";
import "./Common.web.css";

const CustomNoResult = (props: any) => {
  return (
    <div className="noResultWrapper">
      {props.img && <img src={props.img} alt="noResultIcon" className="noResultIcon" onClick={props.handleOnClick}/>}
      <p className="noResultText">{props.text}</p>
    </div>
  );
};

export default CustomNoResult;
// Customizable Area End
