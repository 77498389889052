import React from 'react'
import { useState } from 'react'
import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

interface props{
    history:any;
}


const ProtectedRoute: React.FC<props> = (props) => {
    const [TokenExists, setTokenExists] = useState<null|boolean>(null)


    useEffect(() => {    
        const token = localStorage.getItem('authToken')
        if (token !== null) {
            setTokenExists(true)
        }else{
            setTokenExists(false)
        }
    }, [])
    
    if (TokenExists === false) {
        // location.replace('/')
        // <Redirect to="/" />
        props.history.push('/')
    }
    
    return (
        <div>
            {props.children}
        </div>
    )
}

export default withRouter(ProtectedRoute);