// Customizable Area Start
import React from "react";
import { Button } from "@material-ui/core";
import "./CustomCalendar.css";

const CustomCalendar = (props: any) => {
  return (
    <div>
      <section className="searchCalendarContainer">
        <div className="searchCalendarInnerContainer">
          <input
            data-testid="txtCalDay"
            id="calDay"
            className={`EditBox ${props.isDateError && "calError"}`}
            type="number"
            value={props.calDay}
            onChange={() => props.handleDate()}
            onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
            placeholder="Day"
            name="Day"
            pattern="[0-9]"
            min={1}
            max={31}
          />
          <div className="EditBox month" data-placeholder="Month">
            {props.monthsValue[props.calMonth]}
          </div>
          <input
            data-testid="txtCalYear"
            id="calYear"
            name="Year"
            className={`EditBox ${props.isYearError && "calError"}`}
            type="number"
            placeholder="Year"
            value={props.calYear}
            onKeyDown={e => /[\+\-\.\,]$/.test(e.key) && e.preventDefault()}
            data-focus="false"
            visible-polyfill="false"
            onChange={() => props.handleYear()}
          />
        </div>
        <div className="monthContainer">
          {props.monthsValue.map((month: any) => {
            return (
              <Button
                data-testid="btnMonthName"
                id="monthName"
                className="monthName"
                onClick={e => props.handleMonth(e)}
                key={month}
                value={props.monthsValue.indexOf(month)}
              >
                {month}
              </Button>
            );
          })}
        </div>
      </section>
    </div>
  );
};

export default CustomCalendar;
// Customizable Area End
