// Customizable Area Start
import React from "react";
const configJSON = require("./config");
import "./InfoPageBlock.web.css";
import CustomMenuSideBar from "../../../components/src/CustomMenuSideBar";
const locationIcon = require("../assests/locationIcon.svg");
const telegramIcon = require("../assests/TelegramIcon.svg");
const emailIcon = require("../assests/emailIcon.svg");
const linkedInicon = require("../assests/linkedInicon.svg");

const InfoPageBlock = () => {
  return (
    <section className="contactUsContainer sideBarContainer">
      <section className="sideBar">
        <CustomMenuSideBar />
      </section>
      <section className="sideBarInnerContainer profileInnerContainer">
        <h2 className="contactUsHeader">{configJSON.labelContact}</h2>
        <div className="contactUsInnerContainer">
          <InfoImgText icon={emailIcon} text={configJSON.labelEmail} />
          <InfoImgText icon={linkedInicon} text={configJSON.labelLinkedIn} hrefLink="https://www.linkedin.com/company/ideaatlas/" />
          <InfoImgText icon={telegramIcon} text={configJSON.labelTelegram} hrefLink="https://t.me/ideaatlas" />
          <InfoImgText icon={locationIcon} text={configJSON.labelAddress} styleName="noHover"/>
        </div>
      </section>
    </section>
  );
};
export default InfoPageBlock;

const InfoImgText = (props: any) => {
  const TheIcon = props.icon;
  return (
    <div className="infoBlock">
      <div className="iconImgCircle">
        <img src={TheIcon} className="infoIconImg" />
      </div>
      {props.hrefLink ?
        <a style={{ textDecoration: "none" }} href={props.hrefLink} target="_blank" rel="noreferrer noopener" >
          <p className="contactUsTexthref">{props.text}</p>
        </a>
        :
        <p className={`contactUsText ${props.styleName}`}>{props.text}</p>
      }

    </div>
  );
};
// Customizable Area End