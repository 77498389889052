import React from "react";
import { Row, Col, Label } from "reactstrap";
const configJSON = require("./config.js");

const WebFooter = () => {
  return (
    <Row className="loginCopyRightText">
      <Col md={12} className="copyrightTextLogin">
        <Label>{configJSON.copyrightText}</Label>
      </Col>
    </Row>
  );
};

export default WebFooter;
