import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";
import { noProfileImg } from "./assets";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  bannerFromBackend?: any;
  reportedUser?: boolean;
  userName?: string;
  firstName?: string;
  LastName?: string;
  userEmail?: string;
  hiddenUser?: boolean;
  userProfilePic?: any;
  profileId?: any;
  handleSendFriendRequest?: any;
  requestSentArray?: any;
  open: boolean;
  anchorEl: null | HTMLElement;
  reportedStatus: boolean;
  hiddenStatus: boolean;
  friendProfilePic: any;
  friendFullName: string;
  friendUserName: string;
  friendEmail: string;
  friendIdeasId: any;
  handleRemoveContact: any;
  reportUserProfileFun: any;
  ideaVisibilityOnFun: any;
  ideaVisibilityOffFun: any;
  ideaVisibleBoolean: any;
  unReportUserProfileFn: any
  friendBanner: any;
  friendProfileImg: any;
  reportID: number;
  selectedContactListType: string;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  bannerFromBackend: null | string;
  firstName: any;
  lastName: any;
  userName: string;
  userEmail: string;
  profilePicFromBackend: null | string;
  isGridView: boolean;
  allContacts: any;
  friendList: any;
  dupContacts: any;
  searchTerm: string;
  contactsCount: number;
  selectedContactList: string;
  open: boolean;
  anchorEl: null | HTMLElement;
  allContactsAPIResponse: any;
  reportedContactsAPIResponse: any;
  allHiddenContactsAPIResponse: any;
  removeContactAPIResponse: any;
  reportContactAPIResponse: any;
  unReportProfileAPIResponse: any;
  userIdeaVisibilityAPIResponse: any;
  isAlertOpen: boolean;
  alertMessageText: string;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class FriendListControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  allContactsListCallID: any;
  removeContactCallID: any;
  userProfileReportCallID: any;
  userProfileUnBlockCallID: any;
  userProfileIdeaVisibiltyCallID: any;
  reportedContactsCallID: any;
  hiddenContactsCallID: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      bannerFromBackend: null,
      firstName: "",
      lastName: "",
      userName: '',
      userEmail: '',
      profilePicFromBackend: null,
      isGridView: true,
      allContacts: [],
      dupContacts: [],
      friendList: [],
      searchTerm: "",
      contactsCount: 0,
      selectedContactList: 'contacts',
      open: false,
      anchorEl: null,
      allContactsAPIResponse: "",
      reportedContactsAPIResponse: '',
      allHiddenContactsAPIResponse: '',
      removeContactAPIResponse: "",
      reportContactAPIResponse: "",
      unReportProfileAPIResponse: "",
      userIdeaVisibilityAPIResponse: "",
      isAlertOpen: false,
      alertMessageText: "",
      isLoading: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    this.changeMenu = this.changeMenu.bind(this);
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
  }
  // Api calling start 
  getAllContactsList = () => {
    this.setState({ isLoading: true });
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.allContactsListCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.allContactsListEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllReportedContacts = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.reportedContactsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportedContactsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getAllHiddenContacts = () => {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.hiddenContactsCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.hiddenContactsEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  removeContact = (contactId: any) => {
    const header = {
      token: localStorage.getItem("authToken"),
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.removeContactCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.removeContactEndPoint}/${contactId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypeDELETE
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  reportUserProfile = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileReportCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportProfileEndPoint + `/${ideaID}?status=true`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  unReportUserProfile = (ideaID: number) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileUnBlockCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.reportProfileEndPoint + `/${ideaID}?status=false`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  userProfileIdeaVisibility = (ideaID: number, ideaStatus: string) => {
    const header = {
      'token': localStorage.getItem('authToken')
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.userProfileIdeaVisibiltyCallID = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.userProfileIdeaVisibilityEndPoint + `/${ideaID}?status=${ideaStatus}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.MethodTypePOST
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Api calling End

  // Mapping API response Start
  async receive(from: string, message: Message) {

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        switch (apiRequestCallId) {
          case this.allContactsListCallID:
            this.allContactAPIResponse(responseJson);
            break;
          case this.reportedContactsCallID:
            this.allReportedContactsAPIResponse(responseJson);
            break;
          case this.hiddenContactsCallID:
            this.allHiddenContactsAPIResponse(responseJson);
            break;
          case this.removeContactCallID:
            this.removeContactAPIResponse(responseJson);
            break;
          case this.userProfileReportCallID:
            this.userProfileReportAPIResponse(responseJson);
            break;
          case this.userProfileUnBlockCallID:
            this.userProfileUnBlockAPIResponse(responseJson);
            break;
          case this.userProfileIdeaVisibiltyCallID:
            this.userIdeaVisibilityAPIResponse(responseJson);
            break;
          default:
            break;
        }
      }
    }
  }

  allContactAPIResponse(responseJson: any) {
    this.setState({ allContactsAPIResponse: responseJson, isLoading: false })
    if (!responseJson.errors && responseJson.status !== 500) {
      this.setState({ contactsCount: responseJson?.total_requests_count })
      if (responseJson?.total_requests_count > 0) {
        let respData;

        if (localStorage.getItem('username') == responseJson?.data[0]?.attributes?.profile_details?.data.attributes.user_name) {
          respData = responseJson?.data[0]?.attributes?.profile_details?.data.attributes
        } else {
          respData = responseJson?.data[0]?.attributes?.sender_details?.data.attributes
        }
        this.setState({
          allContacts: responseJson?.data,
          firstName: respData.first_name,
          lastName: respData.last_name,
          userName: respData.user_name,
          userEmail: respData.email,
          bannerFromBackend: this.isRenderCondition(respData.resize_banner != null, respData.resize_banner, respData.banner),
          profilePicFromBackend: this.isRenderCondition(respData.resize_image != null, respData.resize_image, respData.image),
        }, () => {
          this.setState({ friendList: this.sortAllContacts(), dupContacts: this.sortAllContacts() })
        })
      } else {
        this.setState({
          firstName: responseJson?.data[0].attributes?.first_name,
          lastName: responseJson?.data[0].attributes?.last_name,
          userName: responseJson?.data[0].attributes?.user_name,
          userEmail: responseJson?.data[0].attributes?.email,
          bannerFromBackend: this.isRenderCondition(responseJson?.data[0].attributes.resize_banner != null, responseJson?.data[0].attributes.resize_banner, responseJson?.data[0].attributes.banner),
          profilePicFromBackend: this.isRenderCondition(responseJson?.data[0].attributes.resize_image != null, responseJson?.data[0].attributes.resize_image, responseJson?.data[0].attributes.image),
        })
      }

    }
    else if (responseJson.errors[0].token) {
      setTimeout(() => {
        this.props.navigation.navigate("EmailAccountLogin");
      }, 2000)

      this.setState({
        isAlertOpen: true,
        alertMessageText: "Please Login again."
      })
    }
    else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Failed to load contacts. Please try again."
      })
    }
  }

  allReportedContactsAPIResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.status !== 500) {
      this.setState({
        allContacts: responseJson?.data,
        reportedContactsAPIResponse: responseJson
      }, () => {
        this.setState({ friendList: this.sortReportedContacts() })
      })
    } else {
      this.setState({
        reportedContactsAPIResponse: responseJson,
        isAlertOpen: true,
        alertMessageText: "Something went wrong. Please try again."
      })
    }
  }

  allHiddenContactsAPIResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.status !== 500) {
      this.setState({
        allContacts: responseJson?.data,
        allHiddenContactsAPIResponse: responseJson
      }, () => {
        this.setState({ friendList: this.sortReportedContacts() })
      })
    } else {
      this.setState({
        allHiddenContactsAPIResponse: responseJson,
        isAlertOpen: true,
        alertMessageText: "Something went wrong. Please try again."
      })
    }
  }

  removeContactAPIResponse(responseJson: any) {
    this.setState({ removeContactAPIResponse: responseJson })
    if (!responseJson.errors && responseJson.status !== 500) {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Contact removed successfully."
      })
      if (responseJson.message === "Contact removed successfully") {
        this.getAllContactsList();
      }
    } else {
      this.setState({
        isAlertOpen: true,
        alertMessageText: "Unable to remove contacts."
      })
    }
  }

  userProfileReportAPIResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.status !== 500) {
      this.setState({
        reportContactAPIResponse: responseJson,
        isAlertOpen: true,
        alertMessageText: "Contact reported successfully."
      })
      if (responseJson.message.report_status) {
        this.getAllContactsList();
      }
    } else {
      this.setState({
        reportContactAPIResponse: responseJson,
        isAlertOpen: true,
        alertMessageText: "Unable to report contact."
      })
    }
  }

  userProfileUnBlockAPIResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.status !== 500) {
      this.setState({
        unReportProfileAPIResponse: responseJson,
        isAlertOpen: true,
        alertMessageText: "Contact unblocked successfully."
      })
      if (responseJson.message === "Account report successfully remove") {
        this.getAllContactsList();
      }
    } else {
      this.setState({
        unReportProfileAPIResponse: responseJson,
        isAlertOpen: true,
        alertMessageText: "Unable to unblock contact."
      })
    }
  }

  userIdeaVisibilityAPIResponse(responseJson: any) {
    if (!responseJson.errors && responseJson.status !== 500 && responseJson.status !== 404) {
      this.setState({ userIdeaVisibilityAPIResponse: responseJson })
      if (responseJson?.message === "Hiden account  successfully remove") {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "User Ideas has been unhided."
        })
      }
      if (responseJson?.message === "already hide account") {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "User Ideas has already been hidden."
        })
      }
      if (responseJson?.meta?.message === "Account hiden successfully") {
        this.setState({
          isAlertOpen: true,
          alertMessageText: "User Ideas has been hided successfully."
        })
      }
      setTimeout(() => {
        this.getAllContactsList();
      }, 2000)
    } else {
      this.setState({
        userIdeaVisibilityAPIResponse: responseJson,
        isAlertOpen: true,
        alertMessageText: "Unable to hide/unhide user ideas."
      })

    }
  }
  // Mapping API response End

  // Logics for Functions Start

  sortAllContacts = () => {
    let totalList = [];

    for (let item of this.state.allContacts) {
      if (item.attributes.user_account_id == item.attributes.account_id) {
        totalList.push({ ...item.attributes.sender_details.data, fullName: item.attributes.full_name })
      } else {
        totalList.push({ ...item.attributes.profile_details.data, fullName: item.attributes.full_name })
      }
    }

    return totalList.sort((a, b) => a.fullName.localeCompare(b.fullName));

  }

  sortReportedContacts = () => {
    let totalList = [];

    for (let item of this.state.allContacts) {
      if (+item.attributes.profile_details.data.id == item.attributes.reporter_id) {
        totalList.push({ ...item.attributes.sender_details.data, fullName: item.attributes.sender_details.data.attributes.first_name + item.attributes.sender_details.data.attributes.last_name })
      } else {
        totalList.push({ ...item.attributes.profile_details.data, fullName: item.attributes.profile_details.data.attributes.first_name + item.attributes.profile_details.data.attributes.last_name })
      }
    }

    return totalList.sort((a, b) => a.fullName.localeCompare(b.fullName));
  }


  handleReportContact = (reportID: number) => this.reportUserProfile(reportID);

  filterPersons = () => {
    const filteredPersons = this.state.dupContacts.filter((person: any) => {
      return (person.fullName.toLowerCase()
        .includes(this.state.searchTerm.toLowerCase()))
    })
    return filteredPersons;
  }

  handleSearch = (e: any) => {
    this.setState({ searchTerm: e.target.value }, () => {
      if ((this.state.searchTerm !== "")) {
        this.setState({
          friendList: this.filterPersons()
        })
      } else {
        this.setState({ friendList: this.state.dupContacts })
      }
    })
  }

  handleSelectOption = (displayName: string, selectedValue: string) => {
    this.setState({ selectedContactList: displayName })
    if (selectedValue === "ReportedUsers") {
      this.getAllReportedContacts();
      this.handleClose();
    }
    else if (selectedValue === "HiddenUsers") {
      this.getAllHiddenContacts();
      this.handleClose();
    }
    else {
      this.getAllContactsList();
      this.handleClose();
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };


  changeMenu() {
    this.setState({ isGridView: !this.state.isGridView });
  }

  isConditionalImg() {
    return this.state.bannerFromBackend ? baseURL + this.state.bannerFromBackend : noProfileImg
  }

  isBackendConditionImg() {
    return this.state.profilePicFromBackend
      ? baseURL + this.state.profilePicFromBackend
      : noProfileImg
  }

  getProfileImgorBackground = (resize_img: string|null, img: string|null, optionalImg?: string) => {
    if (resize_img != null) {
      return `${baseURL}${resize_img}`
    } else if (img) {
      return `${baseURL}${img}`
    } else {
      return optionalImg ?? null
    }
  }

  isRenderCondition(state: any, value1: any, value2: any) {
    return state ? value1 : value2;
  }

  handleClick = (event: any) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  displayHiddenUser = (selectedContactListType: string, hiddenStatus: boolean, reportedStatus: boolean) => {
    if (hiddenStatus && selectedContactListType === 'Hidden Users') {
      return true
    }
    return hiddenStatus && !reportedStatus;
  }

  displayReportedUser = (selectedContactListType: string, reportedStatus: boolean) => {
    if (reportedStatus && selectedContactListType === 'Hidden Users') {
      return false;
    }
    return reportedStatus;
  }


  handleCloseAlertMessagePopup = () => {
    this.setState({
      isAlertOpen: false,
      alertMessageText: ""
    })
  }
  // Logics for Functions End
  // Customizable Area End
}