// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import PropTypes from 'prop-types';
import { UserDetailsProvider } from './Context/Context.web';

import { Box, IconButton } from '@material-ui/core';
// import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';

import FeedBack from '../../blocks/customform/src/FeedBack.web';
import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ForgotPassword from '../../blocks/forgot-password/src/ForgetPassword.web'
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import ChangeEmailAccount from '../../blocks/email-account-registration/src/ChangeEmailAccount';
import PrivacySettings from '../../blocks/PrivacySettings/src/PrivacySettings';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import Favourites from '../../blocks/Favourites/src/Favourites';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import Hashtags from '../../blocks/Hashtags/src/Hashtags';
import AddFriends from '../../blocks/AddFriends/src/AddFriends';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import PostCreation from '../../blocks/postcreation/src/PostCreation';
import AddNewIdea from '../../blocks/postcreation/src/AddNewIdea';
import Posts from '../../blocks/postcreation/src/Posts';
import PostDetails from '../../blocks/postcreation/src/PostDetails';
import Customform from '../../blocks/customform/src/Customform';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import ContentFlag from '../../blocks/ContentFlag/src/ContentFlag';
import SharePost from '../../blocks/share/src/SharePost.web';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import EmailAccountLogin from '../../blocks/email-account-login/src/EmailAccountLogin.web';
import Upvotedownvote from '../../blocks/Upvotedownvote/src/Upvotedownvote';
import AdManager from '../../blocks/AdManager/src/AdManager';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement';
import Gamification from '../../blocks/Gamification/src/Gamification';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import CustomAdvertisements from '../../blocks/CustomAdvertisements/src/CustomAdvertisements';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import UserGroups from '../../blocks/UserGroups/src/UserGroups';
import VideoEmbeddingYoutube from '../../blocks/VideoEmbeddingYoutube/src/VideoEmbeddingYoutube';
import ActivityFeed from '../../blocks/ActivityFeed/src/ActivityFeed';
import IdeaLibrary from '../../blocks/ActivityFeed/src/IdeaLibrary.web';
import UserProfile from '../../blocks/ActivityFeed/src/UserProfile';
import SavedPosts from '../../blocks/ActivityFeed/src/SavedPosts.web';
import Notifications from '../../blocks/notifications/src/Notifications.web';
import Analytics from '../../blocks/analytics/src/Analytics';
import WebHeader from '../../components/src/WebHeader/WebHeader.web';
import FriendList from '../../blocks/FriendList/src/FriendList.web';
import CookiePravicyPopup from '../../blocks/email-account-registration/src/cookiePravicyPopup';
import './App.css';
import { Link } from 'react-router-dom';
import FeedbackMessageIcon from "../../components/src/FeedbackMessageIcon";
import WebFooter from "../../components/src/WebFooter.web";

const routeMap = {
  ActivityFeed: {
    component: ActivityFeed,
    path: '/ActivityFeed'
  },
  IdeaLibrary: {
    component: IdeaLibrary,
    path: '/IdeaLibrary'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  UserProfile: {
    component: UserProfile,
    path: '/UserProfile/:id'
  },

  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  ChangeEmailAccount: {
    component: ChangeEmailAccount,
    path: '/ChangeEmailAccount'
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: '/PrivacySettings'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },
  Hashtags: {
    component: Hashtags,
    path: '/Hashtags'
  },
  AddFriends: {
    component: AddFriends,
    path: '/AddFriends'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  PostCreation: {
    component: PostCreation,
    path: '/PostCreation'
  },
  AddNewIdea: {
    component: AddNewIdea,
    path: '/AddNewIdea'
  },
  Posts: {
    component: Posts,
    path: '/Posts'
  },
  PostDetails: {
    component: PostDetails,
    path: '/PostDetails'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  FeedBack: {
    component: FeedBack,
    path: '/FeedBack'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  ContentFlag: {
    component: ContentFlag,
    path: '/ContentFlag'
  },
  SharePost: {
    component: SharePost,
    path: '/SharePost/:id'
  },
  SavedPosts: {
    component: SavedPosts,
    path: '/SavedPosts'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  EmailAccountLogin: {
    component: EmailAccountLogin,
    path: '/',
    exact: true
  },
  Upvotedownvote: {
    component: Upvotedownvote,
    path: '/Upvotedownvote'
  },
  AdManager: {
    component: AdManager,
    path: '/AdManager'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement'
  },
  Gamification: {
    component: Gamification,
    path: '/Gamification'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  CustomAdvertisements: {
    component: CustomAdvertisements,
    path: '/CustomAdvertisements'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  FriendList: {
    component: FriendList,
    path: '/FriendList'
  },
  UserGroups: {
    component: UserGroups,
    path: '/UserGroups'
  },
  VideoEmbeddingYoutube: {
    component: VideoEmbeddingYoutube,
    path: '/VideoEmbeddingYoutube'
  },

  Home: {
    component: HomeScreen,
    path: '/HomeScreen'
    // exact: true
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  WebHeader: {
    component: WebHeader,
    path: '/WebHeader'
  },
  FriendList: {
    component: FriendList,
    path: '/FriendList'
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});
let previousRoute = ""
class App extends Component {
  static contextTypes = {
    router: PropTypes.object
  };
  constructor(props, context) {
    super(props, context);
  }
  componentDidMount() {
    // this.clearStorage();
    previousRoute = this.context.router.route?.location?.pathname
    if (window.location.pathname === '/') {
      this.checkRemembermeAndRedirect();
    }
  }
  componentDidUpdate() {
    this.clearStorage();
    // this.checkRemembermeAndRedirect();
  }

  checkRemembermeAndRedirect = () => {
    previousRoute = this.context.router.route?.location?.pathname
    if (localStorage.getItem('checkedRememberMe') === 'true') {
      this.context.router.history.push('/ActivityFeed');
    } else {
      this.clearStorage();
    }
  };

  clearStorage = () => {
    if (
      window.location.pathname === '/' ||
      window.location.pathname === '/EmailAccountRegistration'
    ) {
   
      localStorage.clear();
      if(previousRoute && previousRoute.includes("SharePost")){
        localStorage.setItem("prevRoute",previousRoute)
        previousRoute = ""
      } else{
        localStorage.setItem("prevRoute","/")
      }
    }
  };

  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');
    const token = localStorage.getItem('authToken');

    return (
      <>
        <UserDetailsProvider >
          <View
            className={`custom-scroll webHeader ${
              token !== null ? 'web_ContainerLogined' : 'web_Container'
            }`}
          >
            {window.location.pathname === '/' ||
            window.location.pathname === '/EmailAccountRegistration'  || 
            window.location.pathname === '/ForgotPassword'
            ?  <CookiePravicyPopup className={"custom-scroll"}/> : (
              <>
                <WebHeader />
              </>
            )}
            {window.location.pathname === '/UserProfileBasicBlock' ||
            window.location.pathname === '/NewPassword' ||
            window.location.pathname === '/ChangeEmailAccount' ||
            window.location.pathname === '/InfoPage' || 
            window.location.pathname === '/ActivityFeed' ||
            window.location.pathname === '/AddNewIdea' ? (
              <FeedbackMessageIcon bottom={22} />
            ) : null}
         
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
            <WebFooter/>
          </View>
        </UserDetailsProvider>
      </>
    );
  }
}

const styles = {
  web_Container: {
    backgroundImage: 'radial-gradient(rgba(13, 40, 57, 1), rgba(3, 15, 23, 1))',
    height: '100vh',
    width: '100vw',
    overflow: 'auto'
  },
  web_ContainerLogined: {
    backgroundColor: '#081B27',
    height: '100vh',
    width: '100vw',
    overflow: 'auto'
  }
};

export default App;
