// Customizable Area Start
import React from "react";

const CustomMediaDisplay = (props: any) => {
    const {
      imagePreview,
      imagePreviewStyle,
      videoUpload,
      textWithHyperLink,
    } = props;
  
    return (
      <>
        {imagePreview && <img src={imagePreview} className={imagePreviewStyle} />}
        {videoUpload && (
          <video width="100%" height="200" controls>
            <source
              src={window.URL.createObjectURL(videoUpload)}
              type="video/mp4"
            />
            Your browser does not support the video tag.
          </video>
        )}
        {textWithHyperLink && (
          <iframe
            width="100%"
            height="200"
            src={`${textWithHyperLink}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Attached HyperLink"
          />
        )}
      </>
    );
  };
  export default CustomMediaDisplay;
  // Customizable Area End