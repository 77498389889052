// Customizable Area Start
import React from "react";
import UserProfileBasicControllerWeb, {Props} from "./UserProfileBasicControllerWeb";
import "./UserProfileBasicBlock.web.css";
import { FormGroup } from "reactstrap";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import { Button, Typography, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import {
  createStyles,
  Theme,
  withStyles,
  WithStyles,
} from "@material-ui/core/styles";
import CustomPassword from "../../../components/src/CustomPassword";
import ProtectedRouteWeb from "../../email-account-login/src/ProtectedRoute.web";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import { AddContactIcon, Search, noProfileImg } from "./assets";
//@ts-ignore
import { baseURL } from "../../../framework/src/config.js";

const configJSON = require("./config");

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });


const OnHoverProfileBoxToolTip = withStyles({
  tooltip: {
    backgroundColor: "transparent !important",
  },
  arrow: {
    "&:before": {
      backgroundColor: "#0F2C40 !important",
    },
  },
})(Tooltip);

const Profilebox = (
  rigthAlign: string
) => {
  const anonymousId = localStorage.getItem("anonymousId");
  const anonymousImage = `${baseURL}${localStorage.getItem("anonymousProfilepic")}`;
  return (
    <>
      <div
        style={{
          position: "relative",
          top: "-4px",
          paddingTop: "14px",
          paddingLeft: "14px",
          width: "282px",
          height: "82px",
          borderRadius: "4px",
          border: "2px solid #0F2C40",
          background: "#0B2434",
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.6)",
          right: rigthAlign,
        }}
      >
        <div className="AnonymousProfileBoxImage">
          <img src={anonymousImage || noProfileImg} className="hoverProfileBoxImg" />
        </div>
        <div className="ProfileBoxFullName">
          <b>
            <span>Anonymous</span>
          </b>
        </div>
        <div className="ProfileBoxUserName">
          <span>Anonymous</span>
        </div>
        <div>
          <div style={{ display: "flex", gap: 12 }}>
            <button
              className="disabledBtn"
              disabled={true}
              data-testid="btnAddContact"
            >
              <AddContactIcon />
              <span className="AddContactText" style={{ fontSize: 11 }}>Add Contact</span>
            </button>
            <Link
              style={{ textDecoration: "none" }}
              to={`/UserProfile/${anonymousId}`}
            >
              <div className="IdeaDiv">
                <Search />
                <span className="AddContactText" style={{ fontSize: 11 }}>
                  Show profile
                </span>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className="confirmationTitle">
        {children}
      </Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
// Customizable Area End

// Customizable Area Start
class DeleteUser extends UserProfileBasicControllerWeb {
  constructor(props: Props) {
    super(props);
  }
  handleClickOpen = () => {
    this.setState({ deleteModalOpen: true });
  };
  handleClose = () => {
    this.setState({ deleteModalOpen: false });
  };

  render() {
    const isInputError = this.state.passwordIncorrent ? "inputError" : "";
    return (
      <ProtectedRouteWeb>
        <section className="deleteContainer">
          <div className="deleteInnerContainer">
            <label className="labelName">{configJSON.labelDeleteAccount}</label>
            <div className="summaryLabel pb-20 pt-20">
              {configJSON.labelDeleteDescription}<br />
              {configJSON.labelDeleteSubDescription}
              <OnHoverProfileBoxToolTip
                arrow={true}
                title={Profilebox("10px")}
                placement="bottom-start"
                interactive={true}
                id={"onHoverProfileBox"}
              >
                <span className="greyText">
                  {configJSON.labelDeleteAnonymous}
                </span>
              </OnHoverProfileBoxToolTip>
            </div>
            <div className="submitButton pt-20" style={{ margin: "0px" }}>
              <Button onClick={this.handleClickOpen}>
                {configJSON.btnTextDelete}
              </Button>
            </div>
          </div>
        </section>
        <Dialog
          onClose={this.handleClose}
          aria-labelledby="customized-dialog-title"
          open={this.state.deleteModalOpen}
          className="deleteDialogTitle"
        >
          <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
            {this.state.passwordIncorrent
              ? configJSON.labelDeleteConfirmationTitle2
              : configJSON.labelDeleteConfirmationTitle1}
          </DialogTitle>
          <MuiDialogContent>
            <Typography className="deleteDescription">
              {this.state.passwordIncorrent
                ? configJSON.labelDeleteConfirmationDescription3
                : configJSON.labelDeleteConfirmationDescription1}
            </Typography>
            <Typography className="deleteDescription">
              {this.state.passwordIncorrent
                ? configJSON.labelDeleteConfirmationDescription4
                : configJSON.labelDeleteConfirmationDescription2}
            </Typography>
            <div className="deletePassword">
              <FormGroup
                className={`form-group width50 pwdError ${isInputError}`}
              >
                <CustomPassword
                  id="txtInputPassword"
                  placeholder={configJSON.labelNewPassword}
                  value={this.state.deleteAccountPassword}
                  handlePasswordChange={(e: any) =>
                    this.setState({ deleteAccountPassword: e.target.value })
                  }
                  isError={this.state.passwordIncorrent}
                  errorMsg={this.state.errorMsg}
                  handleError={() => {
                    this.setState({ passwordIncorrent: false });
                  }}
                />
              </FormGroup>
            </div>
          </MuiDialogContent>
          <MuiDialogActions className="deletePopUpContainer">
            <div className="submitButton deleteButton">
              <Button onClick={this.deleteAccount}>
                {configJSON.btnTextDelete}
              </Button>
            </div>
          </MuiDialogActions>
          <div
            className={`deleteAlertContainer ${this.state.isAccountDeleted ? "" : "hideAlert"
              }`}
          >
            <Alert severity="success">{configJSON.labelAccountDeleted}</Alert>
          </div>
        </Dialog>
      </ProtectedRouteWeb>
    );
  }
}

export default DeleteUser;
// Customizable Area End