import React from "react";

import {
    Container,
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    Tooltip,
    ClickAwayListener,
    // Customizable Area Start
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "../../ActivityFeed/src/ActivityFeed.css";

//@ts-ignore
// import UserDetailsContext from "../../../web/src/Context/Context.web";
// const user = useContext(UserDetailsContext);
import ActivityFeedController, {
    Props,
    configJSON,
} from "../../ActivityFeed/src/ActivityFeedController";
import ActivityFeedPost from "../../ActivityFeed/src/ActivityFeedPost.web";
// import PopUpMenu from "../../postcreation/src/PopUpMenu.web";
import FeedDropDown from "../../ActivityFeed/src/FeedDropDown.web";
import InfiniteScroll from "react-infinite-scroll-component";
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";

var count = 0
// Customizable Area End

export default class SharePost extends ActivityFeedController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start


    // Customizable Area End

    render() {
        console.log(this.state.likedIdeas, "this.state.likedIdeas")
        // Customizable Area Start

        return (

            <>
                {this.state.postsArray[0] && <div style={{marginTop: "2rem"}}>
                    <ActivityFeedPost
                        id=""
                        navigation={null}
                        openPlusDialogContent={this.state.openPlusDialogContent}
                        handleTooltipCloseContent={this.handleTooltipCloseContent}
                        contextImageUpload={this.contextImageUpload}
                        handleClickOpen={this.handleClickOpen}
                        handlePlusContent={this.handlePlusContent}
                        PostData={this.state.postsArray[0]}
                        handleUpdateIdea={this.handleUpdateIdea}
                        displayConfirmMsg={this.state.isIdeaUpdated}
                        handleSuccessCloseIcon={this.handleSuccessCloseIcon}
                        handleUpVote={this.handleUpVote}
                        deleteIdea={this.deleteIdea}
                        requestSentArray = {this.state.requestSentArray}
                        handleSendFriendRequest={this.handleSendFriendRequest}
                        friendRequestSent={this.friendRequestSent}
                        feedFilterChange={this.state.feedFilterChange}
                        feedFilter={this.state.feedFilter}
                        feedFilterChangeFalse= {this.feedFilterChangeFalse}
                        history={this.props.history}
                    />
                </div>}
            </>
            // Customizable Area End
        );
    }



}

// Customizable Area Start
// Customizable Area End
