 // Customizable Area Start
import { Remove, Add , Close } from "@material-ui/icons";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Slider,
  Typography,
  IconButton,
} from "@material-ui/core";
import React, { useState, useCallback } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./utils/cropImage";
import "./utils/cropImage.web.css";
import UserProfileBasicControllerWeb, {
  Props,
} from "../UserProfileBasicControllerWeb";

class CropEasy extends UserProfileBasicControllerWeb {
  constructor(props: Props) {
    super(props);
  }

  cropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    this.setState({ croppedAreaPixels });
  };

  showCroppedImage = async () => {
    try {
      const croppedImageData = await getCroppedImg(
        this.props.imgUrl,
        this.state.croppedAreaPixels,
        this.state.rotationImage
      ).then((res: any) => this.props.handleImage(res.url, res.file));
      this.props.handleCloseDailog(croppedImageData);
    } catch (error) {
      console.log(error);
    }
  };

  zoomPercent = (value: any) => {
    return `${Math.round(value * 100)}%`;
  };

  onCropChange = (cropImage: any) => {
    this.setState({ cropImage });
  };

  onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    this.setState({
      croppedAreaPixels,
    });
  };

  onZoomChange = (zoomImage: any) => {
    this.setState({ zoomImage });
  };
  onRotation = (rotationImage: any) => {
    this.setState({ rotationImage });
  };

  render() {
    const {
      croppedAreaPixels,
      cropImage,
      zoomImage,
      rotationImage,
    } = this.state;
    const { imgUrl, modelTitle } = this.props;
    return (
      <>
        <Dialog open={true} className="cropBackgroundImageDailog">
          <DialogTitle>
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h4" style={{color:"#fff", fontFamily:"Jost-Regular"}}>{modelTitle}</Typography>
              <IconButton
                onClick={(img: any) => this.props.handleCloseDailog(img)}
              >
                <Close style={{color:"#fff", fontFamily:"Jost-Regular"}}/>
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent
            dividers
            style={{
              background: "#333",
              position: "relative",
              width: "100% !important",
              height: 400,

              //   minWidth: { sm: 500 },
            }}
          >
            <Cropper
              image={imgUrl}
              crop={cropImage}
              zoom={zoomImage}
              rotation={rotationImage}
              aspect={6 / 2}
              onZoomChange={this.onZoomChange}
              onRotationChange={this.onRotation}
              onCropChange={this.onCropChange}
              onCropComplete={this.onCropComplete}
            />
          </DialogContent>
          <DialogActions style={{ flexDirection: "column" }}>
          <Box className="dailogActionContainerCrop">
           <Box className="zoomAndRotationContainer">
                <Typography style={{color:"#fff", fontFamily:"Jost-Regular",marginLeft:"15px"}}>Zoom: </Typography>
              
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={this.handleZoomIn} disabled={this.state.zoomImage <= 1}>
                  <Remove style={{color:"#fff"}}/>
                </IconButton>
                <Slider
                  valueLabelDisplay="auto"
                  valueLabelFormat={this.zoomPercent}
                  min={1}
                  max={3}
                  step={0.1}
                  value={this.state.zoomImage}
                  onChange={(e, zoom: any) =>
                    this.setState({ zoomImage: zoom })
                  }
                />
              <IconButton onClick={this.handleZoomOut} disabled={this.state.zoomImage >= 3}>
                <Add style={{color:"#fff"}}/>
              </IconButton>
            </div>
            </Box>
            <Box className="zoomAndRotationContainer">
                <Typography style={{color:"#fff", fontFamily:"Jost-Regular",marginLeft:"15px"}}>
                Straighten:
                </Typography>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                <IconButton onClick={this.handleRotationIn} disabled={this.state.rotationImage <= 0}>
                  <Remove style={{color:"#fff"}}/>
                </IconButton>
                <Slider
                  valueLabelDisplay="auto"
                  min={0}
                  max={360}
                  value={this.state.rotationImage}
                  onChange={(e, rotation: any) =>
                    this.setState({ rotationImage: rotation })
                  }
                />
              <IconButton onClick={this.handleRotationOut} disabled={this.state.rotationImage >= 360}>
                <Add style={{color:"#fff"}}/>
              </IconButton>
            </div>
            
              </Box>
            </Box>
          </DialogActions>
          <DialogActions>
            <Button
              variant="outlined"
              onClick={(img: any) => this.props.handleCloseDailog(img)}
              style={{border: "2px solid #fff"}}
            >
              <Typography style={{color:"#fff", fontFamily:"Jost-Regular"}}>
              Cancel
              </Typography>
            </Button>
            <Button
              onClick={this.showCroppedImage}
              variant="outlined"
              style={{border: "2px solid #fff"}}
            >
              <Typography style={{color:"#fff", fontFamily:"Jost-Regular"}}>
              Submit
              </Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default CropEasy;
// Customizable Area End