import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import * as Yup from "yup";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  id: string;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  accountType: string;
  accountStatus: any;
  passwordRules: any;
  emailValue: any;
  phoneValue: any;
  countryCodeSelected: any;

  token: any;
  enablePasswordField: Boolean;
  btnConfirmPasswordShowHide: Boolean;
  oldPassword: string;
  newPassword: string;
  newPasswordRepeat: string;

  isFieldEmpty: boolean;
  isOldPwdChanged: boolean;
  isOldPwdCorrect: boolean;
  isNewPwdValid: boolean;
  areBothPwdscorrect: boolean;
  areOldnew_password_same: boolean;
  errorMsgText: string;

  isLoading: boolean;
  forgotPasswordEmail: string;
  forgotPasswordSubmited: boolean;
  forgotEmailError: boolean;
  forgetPasswordApiErrMsg: string;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  navigation: any;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

export default class ForgotPasswordControllerWeb extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  validationAPICallId: any;  
  forgotPasswordCallId: string = "";
  otpToken: any;
  changePasswordCallId: any;
  isChangePassword: boolean = false;
  passwordReg: RegExp;
  //Properties from config
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start    

    this.state = {
      accountType: "sms",
      accountStatus: "ChooseAccountType",
      emailValue: "",
      phoneValue: "",
      countryCodeSelected: "",
      passwordRules: "",
      token: "",
      enablePasswordField: true,
      btnConfirmPasswordShowHide: true,
      oldPassword: "",
      newPassword: "",
      newPasswordRepeat: "",
      isFieldEmpty: false,
      isOldPwdChanged: false,
      isOldPwdCorrect: false,
      isNewPwdValid: false,
      areBothPwdscorrect: false,
      areOldnew_password_same: false,
      errorMsgText: "",

      isLoading: false,
      forgotPasswordEmail: "",
      forgotPasswordSubmited: false,
      forgotEmailError: false,
      forgetPasswordApiErrMsg: ""
    };
    this.passwordReg = new RegExp(
      "^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
    );
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.validationRulesRequest();
  }

  validationRulesRequest = () => {
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationAPICallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.profileValidationSettingsAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };


  // Customizable Area Start
  async receive(from: string, message: Message) {
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (
        this.changePasswordCallId !== null &&
        this.changePasswordCallId === apiRequestCallId
      ) {
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (responseJson !== undefined && !responseJson.errors) {
          this.setState({
            accountStatus: "Confirmation",
            isOldPwdChanged: true,
          });

          setTimeout(() => {
            this.props.navigation.navigate("AddNewIdea");
          }, 3000);
        } else {
          //password: "Your old password was entered incorrectly."
          if (responseJson.errors[0].password != undefined) {
            this.setState({
              isOldPwdCorrect: true,
            });
            this.handleError(responseJson.errors[0].password);
          }

          //confirm_password: "The new password and confirmation new password does not match."
          if (responseJson.errors[0].confirm_password != undefined) {
            this.setState({
              areBothPwdscorrect: true,
            });
            this.handleError(responseJson.errors[0].confirm_password);
          }

          //oldnew_password_check: "The new password can't be same as old password."
          if (responseJson.errors[0].oldnew_password_check != undefined) {
            this.setState({
              areOldnew_password_same: true,
            });
            this.handleError(responseJson.errors[0].oldnew_password_check);
          }
        }

      } else if (
        this.forgotPasswordCallId !== null &&
        this.forgotPasswordCallId === apiRequestCallId
      ) {
        let responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        let errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        if (!responseJson.errors) {
          this.setState({
            forgotPasswordSubmited: true
          });
          setTimeout(() => {
            this.props.navigation.navigate("EmailAccountLogin");
          }, 3000);
        } else {
          this.setState({
            forgotPasswordSubmited: false,
            forgotEmailError: true,
            forgetPasswordApiErrMsg: responseJson.errors
          });
        }

        this.setState({ isLoading: false });
      }
    }
    this.parseApiCatchErrorResponse(errorReponse);
  }


  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  /*Change password logics Start*/

  handleError = (errorMsg: string) => {
    this.setState({
      errorMsgText: errorMsg,
    });
  };

  handleIsFieldEmpty = () => {
    this.setState({ isFieldEmpty: false });
  }

  handleOldPasswordChange = (e: any) => {
    this.setState({ oldPassword: e.target.value })
  }

  handleNewPasswordChange = (e: any) => {
    this.setState({ newPassword: e.target.value })
  }

  handleConfirmNewPasswordChange = (e: any) => {
    this.setState({ newPasswordRepeat: e.target.value })
  }

  handleOldPasswordError = () => {
    this.setState({ isOldPwdCorrect: false });
  }

  handleNewPasswordError = () => {
    this.setState({
      isNewPwdValid: false,
      areOldnew_password_same: false,
    });
  }

  handleBothPasswordsCorrectError = () => {
    this.setState({ areBothPwdscorrect: false });
  }

  handleChangePassword = () => {
    this.setState({
      isFieldEmpty: false,
      isOldPwdChanged: false,
      isOldPwdCorrect: false,
      isNewPwdValid: false,
      areBothPwdscorrect: false,
      areOldnew_password_same: false,
    });
    if (
      this.isStringNullOrBlank(this.state.oldPassword) ||
      this.isStringNullOrBlank(this.state.newPassword) ||
      this.isStringNullOrBlank(this.state.newPasswordRepeat)
    ) {
      this.setState({ isFieldEmpty: true });
      return false;
    }

    if (
      this.state.newPassword.length !== this.state.newPassword.trim().length
    ) {
      this.setState({
        isNewPwdValid: true,
      });
      this.handleError(configJSON.errorPasswordNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.newPassword)) {
      this.setState({
        isNewPwdValid: true,
      });
      this.handleError(configJSON.errorPasswordNotValid);
      return false;
    }
    
    this.changePasswordWithoutOTP();
  };

  changePasswordWithoutOTP() {
    
    const header = {
      "Content-Type": configJSON.forgotPasswordAPiContentType,
    };

    const data = {
      token: localStorage.getItem("authToken"),
      old_password: this.state.oldPassword,
      new_password: this.state.newPassword,
      confirm_password: this.state.newPasswordRepeat,
    };

    const httpBody = {
      data: data,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.changePasswordCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  /*Change password logics end*/

  /*Forgot password logics start*/

  handleCloseError = () => {
    this.setState({
      forgotEmailError: false
    });
  };

  handleForgotPasswordText = (e: any) => {
    this.setState({ forgotPasswordEmail: e.target.value });
  };


  forgotPassword() {
    this.setState({ isLoading: true });
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      Connection: "keep-alive"
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.forgotPasswordCallId = getValidationsMsg.messageId;

    const httpBody = {
      email: this.state.forgotPasswordEmail
    };

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.forgotPasswordEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  handleForgotPasswordSubmit = () => {
    if (this.isStringNullOrBlank(this.state.forgotPasswordEmail)) {
      this.setState({
        forgetPasswordApiErrMsg: "Please type your e-mail",
        forgotEmailError: true
      });
      return false;
    }
    this.forgotPassword();
  };

  /*Forgot password logics end*/

  // Customizable Area End
}
