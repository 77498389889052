// Customizable Area Start
import React from "react";
import { Button, ClickAwayListener, Grid, TextField, Tooltip } from "@material-ui/core";
import { reloadIcon } from "../../blocks/customform/src/assets";
import PopUpMenuFeedBack from "../../blocks/customform/src/components/PopUpMenuFedback.web";
import { InfoReactIcon, PlusIcon, revertIcons } from "../../blocks/postcreation/src/assets";
import { getStyle } from "./CommonFunctions.web";
import CustomMediaDisplay from "./CustomMediaDisplay";

const CustomFeedInput = (props: any) => {

    const {
        feedType,
        feedIcon,
        tooltiptext,
        feedText,
        handleFeedTextUndo,
        handleFeedTextInput,
        errorInFeedText,
        errorInFeedMedia,
        handleTextRemoveIcon,
        feedImagePreview,
        feedImageUpload,
        imageCropPopup,
        profileImageCrop,
        handleDate,
        addCropImageDailog,
        setImageData,
        ideaPicImageCrop,
        feedVideoUpload,
        openPlusDialog,
        handleFeedPlus,
        handleTooltipClose,
    } = props;

    const checkFeedTextMedia = () => {
        return (feedImagePreview || feedVideoUpload) === null
    }

    return (
        <div className={`${getStyle(feedType === "context", "boxSeparator", "smaller-input")}  ${getStyle(feedText, "editedIdea", "")}`}>
            <div className={`LabelBox  ${getStyle(errorInFeedText, "Error", "")} ${getStyle(errorInFeedMedia, "Error", "")}`}>
                <label className="form-label flex-box">
                    <div className={"img-center"}>
                        <img src={feedIcon} alt={`${feedType}Icon`} />
                    </div>
                    <p className="no-margin addNewIdeaTitleBoxFont18">{feedType.toUpperCase()}</p>
                    <Grid item>
                        <Tooltip
                            arrow={true}
                            classes={{ tooltip: "customToolTip" }}
                            title={tooltiptext[feedType].map((e: any) => {
                                return (
                                    <ul className="toolTipUL" key={e.id}>
                                        <li>{e.text}</li>
                                    </ul>
                                );
                            })}
                            placement="right-start"
                        >
                            <InfoReactIcon className={getStyle(errorInFeedText, "errInfoIcon", "")}
                            />
                        </Tooltip>
                    </Grid>
                </label>
                <div style={{ display: "flex" }}>
                    {feedText.length > 0 ?
                        <Button
                            className="cursorPointer feedHoverUndo"
                            data-title="Undo changes"
                            style={{
                                minWidth: 0,
                                padding: 0,
                                marginRight: "14px",
                                color: "Gray"
                            }}
                            onClick={handleFeedTextUndo}
                        >
                            <img
                                src={revertIcons}
                                alt=""
                                color="gray"

                            />
                        </Button> : null}
                    {checkFeedTextMedia() ? (
                        <ClickAwayListener onClickAway={handleTooltipClose}>
                            <div className="w-15 plusTooltipoption">
                                <Tooltip
                                    PopperProps={{
                                        disablePortal: true,
                                    }}
                                    onClose={handleTooltipClose}
                                    open={openPlusDialog}
                                    disableFocusListener
                                    disableHoverListener
                                    disableTouchListener
                                    classes={{
                                        tooltip: "menu-pop-up",
                                        arrow: "menu-pop-up-arrow",
                                    }}
                                    interactive={true}
                                    title={
                                        <PopUpMenuFeedBack
                                            navigation={undefined}
                                            id={""}
                                            contextImageUpload={feedImageUpload}
                                            title={feedType}
                                            imageCropPopup={imageCropPopup}
                                            profileImageCrop={profileImageCrop}
                                            handleDate={handleDate}
                                            AddCropImageDailog={addCropImageDailog}
                                            setImageData={setImageData}
                                            ideaPicImageCrop={ideaPicImageCrop}
                                        />
                                    }
                                    arrow={true}
                                    placement="right-start"
                                >
                                    <Button
                                        style={{ minWidth: 0, padding: 0 }}
                                        onClick={handleFeedPlus}
                                    >
                                        <img
                                            src={PlusIcon}
                                            className="plus-icon"
                                            alt="plusIcon"
                                            onClick={handleFeedPlus}
                                            onKeyPress={handleFeedPlus}
                                        />
                                    </Button>
                                </Tooltip>
                            </div>
                        </ClickAwayListener>
                    ) : (
                        <div>
                            <img
                                src={reloadIcon}
                                className="plus-icon"
                                alt="reloadIcon"
                                title="Reset"
                                onClick={handleTextRemoveIcon}
                                onKeyPress={handleTextRemoveIcon}
                            />
                        </div>
                    )}
                </div>
            </div>
            <CustomMediaDisplay
                imagePreview={feedImagePreview}
                imagePreviewStyle={getStyle(feedType === "context", "contextPreviewImage", "previewImage")}
                videoUpload={feedVideoUpload}
            />

            <TextField
                id={feedType}
                minRows={1}
                className="form-control"
                classes={{
                    root: "height-auto",
                }}
                onChange={handleFeedTextInput}
                value={feedText}
                multiline
                placeholder={`Type ${feedType} here...`}
                InputProps={{ disableUnderline: true }}
            />
        </div>
    )
}

export default CustomFeedInput;

// Customizable Area End