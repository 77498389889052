//@ts-nocheck
export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const AddImage = require("../../ActivityFeed/assets/addImage.png");
export const background = require("../assets/Background.jpg");
export const magnifier = require("../assets/magnifier.svg");
export const removeContact = require("../assets/removeContact.svg");
export const contactSearch = require("../assets/contactSearch.svg");
export const listMenu = require("../assets/listMenu.svg");
export const groupMenu = require("../assets/groupMenu.svg");
export const editIcon = require("../assets/editIcon.svg");
export const noProfileImg = require("../assets/noProfile.svg");
export const ImageLogo = require("../assets/imagelogo.svg");
export const danger = require("../assets/danger.svg")
export const hide_idea_icon = require("../assets/hide_idea_icon.svg");
export const hide_idea_icon_Gray = require("../assets/hide_idea_icon_Gray.svg")
export const report = require("../assets/Report.svg")
export const reportBtn = require("../assets/ReportBtn.svg")
export const downArrow = require("../assets/DownArrow.svg")
export { ReactComponent as ReportOn } from "../assets/reportOn.svg";
export { ReactComponent as ReportOff } from "../assets/reportOff.svg";
export { ReactComponent as HideIdea } from "../assets/hide_idea_icon.svg";
export { ReactComponent as ViewIdea } from "../assets/viewIdea.svg";
export { ReactComponent as RemoveContact } from "../assets/removeContact.svg";
export { ReactComponent as AddContactIcon } from "../assets/addcontact.svg";
export { ReactComponent as Dotdotdot } from "../assets/Dotdotdot.svg";
export { ReactComponent as ReportUser } from "../assets/Report.svg";
export { ReactComponent as HidenUser } from "../assets/Eye.svg";
export { ReactComponent as Eyeclose } from "../assets/Eyeclose.svg";
export { ReactComponent as HoverEdit } from "../assets/onHoverEdit_icon.svg";
export { ReactComponent as noUserImg } from "../assets/imagelogo.svg"
export { ReactComponent as HideIdeaGray } from "../assets/hide_idea_icon_Gray.svg";