// Customizable Area Start
import React from "react";
import { ClickAwayListener, Button } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import PopUpMenu from "../../blocks/postcreation/src/PopUpMenu.web";
import { PlusIcon } from "../../blocks/postcreation/src/assets";

const CustomPlusTooltip = (props: any) => {
    const {
        feedTitle,
        handleTooltipCloseContent,
        openPlusDialogContent,
        contextVideoUploadAddnewIdea,
        handleMediaClickOpen,
        imageCropPopup,
        profileImageCrop,
        handleDate,
        AddCropImageDailog,
        setImageDataAddIdea,
        ideaPicImageCrop,
        handleAddIdeaPlusContent,
    } = props;
    return (
        <ClickAwayListener onClickAway={handleTooltipCloseContent}>
            <div className="w-15 plusTooltipoption">
                <Tooltip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleTooltipCloseContent}
                    open={openPlusDialogContent}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    classes={{
                        tooltip: "menu-pop-up",
                        arrow: "menu-pop-up-arrow",
                    }}
                    interactive={true}
                    title={
                        <PopUpMenu
                            navigation={undefined}
                            id={""}
                            contextImageUpload={contextVideoUploadAddnewIdea}
                            title={feedTitle}
                            handleHyperLink={handleMediaClickOpen}
                            imageCropPopup={imageCropPopup}
                            profileImageCrop={profileImageCrop}
                            handleDate={handleDate}
                            AddCropImageDailog={AddCropImageDailog}
                            setImageData={setImageDataAddIdea}
                            ideaPicImageCrop={ideaPicImageCrop}
                        />
                    }
                    arrow={true}
                    placement="right-start"
                >
                    <Button
                        style={{ minWidth: 0, padding: 0 }}
                        onClick={handleAddIdeaPlusContent}
                    >
                        <img
                            src={PlusIcon}
                            className="plus-icon"
                            alt="plus-icon"
                            onClick={handleAddIdeaPlusContent}
                            onKeyPress={handleAddIdeaPlusContent}
                        />
                    </Button>
                </Tooltip>
            </div>
        </ClickAwayListener>
    )
}

export default CustomPlusTooltip;

// Customizable Area End