import { Link } from "react-router-dom";
import React from "react";
import SettingsToolTip from "./SettingsToolTip";
import { magnifier, removeContact, HideIdeaGray, hide_idea_icon, danger, reportBtn } from "./assets";
import { Button } from "@material-ui/core";
import FriendListControllerWeb, { Props } from "./FriendListController.web";
const configJSON = require("./config.js")
export default class FriendPageMenu extends FriendListControllerWeb {
  constructor(props: Props) {
    // Customizable Area Start
    // Customizable Area End
    super(props);
  }
  render() {
    // Customizable Area Start
    return (
      <>
        {(!this.props.reportedStatus && !this.props.hiddenStatus) &&
          <section className="contactCard">
            <div className="contactBackgroundImg">
              {this.props.friendBanner ? (
                <img className="bannerImg" src={this.props.friendBanner} />
              ) : (
                <div className="bannerNoImg" />
              )}
            </div>
            <div className="contactProfilePic">
              <img src={this.props.friendProfilePic} className={this.props.friendProfileImg} />
            </div>
            <div>
              <p className="contactName">{this.props.friendFullName}</p>
              <p className="contactNickName">{this.props.friendUserName}</p>
            </div>
            <div className="detailsContainer">
              <Link to={`UserProfile/${this.props.friendIdeasId}`} className="cntIdeas">
                <div className="ideasContainer">
                  <img src={magnifier} className="magnifier" />
                  Ideas
                </div>
              </Link>
              <Button className="removeContactContainer" onClick={this.props.handleRemoveContact}>
                <img src={removeContact} className="removeCntImg" />
              </Button>
              <div className="contactSettingsContainer w-15">
                <SettingsToolTip
                  testId="hideUserButton"
                  reportID={this.props.reportID}
                  reportUserProfileFun={this.props.reportUserProfileFun}
                  ideaVisibilityOnFun={this.props.ideaVisibilityOnFun}
                  ideaVisibilityOffFun={this.props.ideaVisibilityOffFun}
                  hiddenStatus={this.props.hiddenStatus}
                  reportedStatus={this.props.reportedStatus}
                />
              </div>
            </div>
          </section>}
        {this.displayHiddenUser(this.props.selectedContactListType, this.props.hiddenStatus , this.props.reportedStatus) && 
          <section className="contactCard hiddenContactCard">
            <div className="contactBackgroundImg" >
              <div style={{ position: "relative" }}>
                <img className="reportedBannerImg" style={{ opacity: 0.2 }} src={this.props.friendBanner} />
                <div className="hiddenIcon"><HideIdeaGray /></div>
              </div>
            </div>
            <div className="reportedContactProfilePic">
              <img src={this.props.friendProfilePic} className={this.props.friendProfileImg} />
            </div>
            <div>
              <p className="txtHiddenUser">{configJSON.hiddenUser}</p>
              <p className="reportedContactName">{this.props.friendFullName}</p>
              <p className="contactNickName" style={{ opacity: 0.3 }}>{this.props.friendUserName}</p>
            </div>
            <div className="detailsContainer">
              <Link to={`UserProfile/${this.props.friendIdeasId}`} className="cntIdeas" >
                <div className="ideasContainer">
                  <img src={magnifier} className="magnifier" />
                  Ideas
                </div>
              </Link>
              <Button className="removeContactContainer" onClick={this.props.handleRemoveContact}>
                <img src={removeContact} className="removeCntImg" />
              </Button>
              <div className="contactSettingsContainer w-15 hiddenUserSetting">
                <SettingsToolTip
                  testId="unHideUserButton"
                  reportID={this.props.reportID}
                  reportUserProfileFun={this.props.reportUserProfileFun}
                  ideaVisibilityOnFun={this.props.ideaVisibilityOnFun}
                  ideaVisibilityOffFun={this.props.ideaVisibilityOffFun}
                  displayIcon={hide_idea_icon}
                  displayIconStyle="removeCntImg"
                  hiddenStatus={this.props.hiddenStatus}
                  reportedStatus={this.props.reportedStatus}
                />
              </div>
            </div>
          </section>}
        {this.displayReportedUser(this.props.selectedContactListType, this.props.reportedStatus) &&
          <section className="contactCard hiddenContactCard">
            <div className="contactBackgroundImg">
              <div style={{ position: "relative" }}>
                <img className="reportedBannerImg" src={this.props.friendBanner} />
                <img src={danger} className="hiddenIcon" />
              </div>
            </div>
            <div className="reportedContactProfilePic">
              <img src={this.props.friendProfilePic} className={this.props.friendProfileImg} />
            </div>
            <div>
              <p className="txtReportedUser">{configJSON.textReportedUser}</p>
              <p className="reportedContactName">{this.props.friendFullName}</p>
              <p className="contactNickName" style={{ opacity: 0.3 }}>{this.props.friendUserName}</p>
            </div>
            <div className="detailsContainer">
              <Button className="btnReportedUser" onClick={this.props.unReportUserProfileFn}>
                <img src={reportBtn} className="removeCntImg" /> <span className="txtReportedBtn">{configJSON.textReportedUserButton}</span>
              </Button>
            </div>
          </section>
        }
      </>
    );
    // Customizable Area End
  }
};