export const edit = require("../assets/edit.png");
export const imgPasswordVisible = require("../assets/edit.png");
export const imgPasswordInVisible = require("../assets/edit.png");
export const AddImage = require("../assets/edit.png");
export const infoIcon = require("../assets/icon_information.svg");
export const lineIcon = require("../assets/line_icon.svg");
export const crossIcon = require("../assets/cross_icon.svg");
export const logoIcon = require("../assets/logo_icon.svg");
export const profilePic = require("../assets/profilepic.png");
export const LockIcon = require("../assets/lock_icon.svg");
export const WorldIcon = require("../assets/icon_world.svg");
export const GroupIcon = require("../assets/icon_group.svg");
export const ImageLogo = require("../assets/imagelogo.svg");
export const HyperlinkLogo = require("../assets/hyperlinklogo.svg");
export const VideoLogo = require("../assets/videologo.svg");
export const PlusIcon = require("../assets/plus_icon.svg");
export const noProfileImg = require("../assets/noProfile.svg");
export const Context = require("../assets/context.svg");
export const ContextIcon = require("../assets/context.svg");
export const SolutionIcon = require("../assets/solution_icon.svg");
export const ProblemIcon = require("../assets/fire.svg");
export const InfoIcon = require("../assets/icon_information.svg");
//@ts-ignore
// export {ReactComponent as Context} from '../assets/context.svg';
//@ts-ignore
// export {ReactComponent as SolutionIcon} from '../assets/solution_icon.svg';
//@ts-ignore
// export {ReactComponent as ProblemIcon} from '../assets/fire.svg';
//@ts-ignore
export {ReactComponent as DropDownIcon} from '../assets/dropdown_arrow.svg';
//@ts-ignore
export {ReactComponent as InfoReactIcon} from '../assets/icon_information.svg';
export const revertIcons = require("../assets/Revert.svg");

// export {ReactComponent as GroupIcon} from '../assets/fire.svg';
//@ts-ignore
// export {ReactComponent as ProblemIcon} from '../assets/fire.svg';