Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.labelFirstName = "First name*";
exports.labelLastName = "Last name*";
exports.labelUserName = "Username*";
exports.labelEmail = "E-mail address*";
exports.labelPassword = "Password*";
exports.labelRegistrationCode = "Registration code*";
exports.labelSubmit = "Submit";
exports.labelCopyRight = "© 2021-2023 IDEA ATLAS LTD. All rights reserved.";
exports.labelLegalText = "I read and accept the ";
exports.labelLoginRedirection = "Already have an account?";
exports.loginText = "Sign in!";
exports.labelCancel = "Cancel";
exports.labelAccept = "Accept";
// "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms of Use";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.errorPasswordNotValid ="Invalid format or too weak password. Minimum length: 8 characters. Please include a number, an uppercase letter, a lowercase letter, and a special character (e.g., ?, !, #)."
  // "Password not valid. It should have at least a Number, Capital Letter, Small Letter, Special character, min. length of 8 char and no white space";

exports.errorTitle = "Error";
exports.errorBlankField = "can't be blank";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";
exports.errorStarredFieldsAreMandatory = "All fields are mandatory.";
exports.errorProfilePicMandatory = "Please upload a profile image.";
exports.errorNameInvalid = "Enter a valid name";
exports.errorUserNameExists = "This username already exists.";
exports.errorCheckBoxUnchecked =
  "Please accept the Terms & Conditions to proceed further.";
exports.contentTypeApiAddDetail = "application/json";
exports.accountsAPiEndPoint = "account/accounts";
exports.addCookiesEndPoint = "add_cookie"

exports.apiMethodTypeAddDetail = "POST";
exports.loginAPiURL =
  "account_block/accounts";
exports.changeEmailAPiURL =
  "change_email";

exports.errorUserNameExists = "This username already exists.";
exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations = "profile/validations";
exports.urlGetTermsAndConditions = "terms_and_conditions";
exports.cookiePrivacyPolicyURL = "privacy_policy";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.termsAndConditionMethodType = "GET"
exports.labelChangeMail = "Change E-mail";
exports.labelOldMail = "Old e-mail address";
exports.labelNewMail = "New e-mail address";
exports.labelMailRule1 = "Your updated e-mail address will be validated by e-mail.";
exports.labelMailRule2 = "You will be sent a verification e-mail.";
exports.labelMailRule3 = "If an e-mail address is undeliverable or bouncing, it will be unverified.";
exports.labelMailRule4 = "If your e-mail fails validation, we may delete the account.";
exports.labelConfirmNewMail = "Confirm new e-mail address";
exports.LabelMailSuccessMsg = "E-mail changed successfully.";

exports.labelOldMailError = "Your old e-mail address was entered incorrectly.";
exports.errorEmailsNotMatched =
  "The new e-mail id and confirmation new e-mail id does not match.";
exports.btnChange = "Change";
exports.regSuccessMsg = "You have registered succesfully.";
exports.privacyParagraph = "We want you to understand how and why Idea Atlas (“we” or “us”) collects, uses, and shares information about you when you use our platform (“Platform” or “Services”) or when you otherwise interact with us or receive a communication from us.";
exports.point1 = "What personal information does Idea Atlas collect?";
exports.subPoint1 = "We collect the following types of information:";
exports.point11 = "1.1 Information that you provide directly to us when you use the Services (such as (i) Account Information; (ii) Information Contained within Your Content; (iii) Information Sent to Us);";
exports.point12 = "1.2 Data collected automatically, associated with the Platform usage.";
exports.subPont12 = "Please see below the details of such data collection:";
exports.point1footer = "Please see below the details of such data collection:"
exports.point2 = "How does Idea Atlas use my personal data?";
exports.subPoint2 = "We use your data to:";
exports.point21 = "Provide, maintain, and improve the Services;";
exports.point22 = "Research and develop new services;";
exports.point23 = "Help protect the safety of our Platform and users;";
exports.point24 = "Send you technical notices, updates, security alerts, and other support and administrative messages;";
exports.point25 = "Provide customer service whenever you request it;";
exports.point26 = "Communicate with you about products, services, offers, promotions, and events, and provide other news and information we think will be of interest to you;";
exports.point27 = "Monitor and analyze trends, usage, and activities in connection with our Services.";
exports.point3 = "Does Idea Atlas collect cookies?";
exports.subPoint3 = "Idea Atlas only collects strictly necessary/preference cookies. These cookies are necessary for the Platform to remember your account details, and we use this information strictly for this purpose. We do not collect statistics or marketing cookies, or any form of analytics over the Platform usage.";
exports.point4 = "Does Idea Atlas share my personal data?";
exports.subPoint4 = "We may share personal information in the following ways:";
exports.point5 = "How does Idea Atlas protect my information?";
exports.subPoint5 = "We take measures to help protect information about you from loss, theft, misuse and unauthorized access, disclosure, alteration, and destruction.We store the information we collect for as long as it is necessary for the purpose(s) for which we originally collected it. We may retain certain information for legitimate business purposes or as required by law.";
exports.point6 = "Do I have any rights associated with my personal data?";
exports.subPoint6 = "You have the following rights enshrined under the GDPR:"
exports.point61 = "1. request access to, rectification of, or erasure of your personal data;";
exports.point62 = "2. data portability, when applicable;";
exports.point63 = "3. request restriction of processing;";
exports.point64 = "4. object to processing;";
exports.point65 = "5. withdraw consent for processing where you have previously provided consent; and";
exports.point66 = "6. to lodge a complaint before your local supervisory authority.";
exports.point6footer = "Before we process a request from you about your personal information, we need to verify your identity. We might do so by confirming that you have access to your account, via a verified email address associated with your Idea Atlas account, or by requesting that you send us proof of your ID."
exports.point7 = "What are the legal bases used by Idea Atlas to process my personal data?";
exports.subPoint7 = "Our legal bases depend on the Services you use and how you use them. We process your information:";
exports.point71 = "When you have consented for us to do so for a specific purpose;";
exports.point72 = "To execute a contract with you, namely to process the information to provide you the Services, including to operate the Platform, provide customer support and personalized features, when applicable, and/or to protect the safety and security of the Services;";
exports.point73 = "In our legitimate interests (when they are not overridden by your interests), such as preventing fraud, enforcing our rules and policies, protecting our legal rights and interests, research and development;";
exports.point74 = "Whenever necessary to comply with our legal obligations.";
exports.point8 = "Can this policy be changed?";
exports.subPoint8 = "Yes. We may change this Privacy Policy from time to time. If we do, we will let you know and will indicate the date of the revision at the end of the policy.";
exports.point9 = "How can I contact you?";
exports.point91 = "Idea Atlas LTD";
exports.point92 = "Email: ";
exports.contactEmail = "contact@ideaatlas.co.uk";
exports.table1th1 = "Kind of data";
exports.table1th2 = "Details";
exports.table1td11 = "1.1 (i)"
exports.table1td12 = "Account information"
exports.table1td13 = "All personal information you provide us when you create an account in the Platform. This includes, without limitation, your profile picture, username, email, registration code."
exports.table1td21 = "1.1 (ii)"
exports.table1td22 = "Information contained within Your Content"
exports.table1td23 = "All personal information which you might share in the Platform when you make posts and/or create content. Includes, without limitation, personal data contained in posts, comments, messages, texts, links, videos, amongst others, which are contained in Your Content."
exports.table1td31 = "1.1 (iii)"
exports.table1td32 = "Information You Sent to Us"
exports.table1td33 = "Includes any personal data which you might send to Idea Atlas directly, for example, when you communicate with us through email. Includes, without limitation, your email, telephone, name."
exports.table1td41 = "1.2"
exports.table1td42 = "Data Collected Automatically"
exports.table1td43 = "When you access the Platform, we might automatically collect information about you, for example: logs, IP address, browser type, operating system.It also includes essential cookies which store logged in user details."
exports.table2th1 = "Purposes for sharing your data";
exports.table2th2 = "Details";
exports.table2td11 = "With your consent";
exports.table2td12 = "We may share information about you with your consent or at your direction.";
exports.table2td21 = "With our service providers";
exports.table2td22 = "We may share information with third-party vendors, consultants, and other service providers who provide work/services for us, including, without limitation, cloud providers.";
exports.table2td31 = "To compy with the law";
exports.table2td32 = "We may share information in order to comply with an applicable law, regulation, legal process or governmental request.";
// Customizable Area End
