import React from "react";

import {
  Button,
  Typography,
  Tooltip,
  ClickAwayListener,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./ActivityFeed.css";
import { DropDownIcon, loader } from "./assets";
import CustomNoResult from "../../../components/src/CustomNoResult";

import ActivityFeedController, { Props } from "./ActivityFeedController";
import ActivityFeedPost from "./ActivityFeedPost.web";
import FeedDropDown from "./FeedDropDown.web";
import InfiniteScroll from "react-infinite-scroll-component";
import { withRouter } from 'react-router-dom'
import ActivityPopup from "./ActivityPopup.web";

// Customizable Area End

class ActivityFeed extends ActivityFeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start  

  // Customizable Area End

  render() {
    // Customizable Area Start

    return (
      <>
        <div id="scrollableDiv" className="custom-scroll">
          <div className="feedFilterDiv w-15" style={{ paddingTop: 20 }}>
            <ClickAwayListener onClickAway={this.handleTooltipCloseContent}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    modifiers: {
                      offset: {
                        enabled: true,
                      },
                    },
                    tooltipPlacementRight: {
                      right: "1px",
                    },
                  },
                }}
                onClose={this.handleTooltipCloseContent}
                open={this.state.openPlusDialogContent}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                classes={{
                  tooltip: "menu-pop-up",
                  arrow: "menu-pop-up-arrow"
                }}
                interactive={true}
                title={
                  <React.Fragment>
                    <FeedDropDown
                      id="dropdown"
                      navigation={undefined}
                      feedFilter={localStorage.getItem("homeFeedFilter") || this.state.feedFilter}
                      handleFeedFilter={this.handleActivityFeedFilter}
                    />
                  </React.Fragment>
                }
                arrow={false}
                placement="right-start"

              >
                <Button data-testid="dropdown-button" className="parentofFeedFilter" onClick={this.handlePlusContent}>
                  <p className={`feedFilterText ${this.getValue(this.state.openPlusDialogContent, 'text-white', null)}`}  >
                    {/* Below is the Feed Filter Default */}       
                                    
                    {localStorage.getItem("homeFeedFilter") || this.state.feedFilter}
                  </p>
                  <span className={this.getValue(this.state.openPlusDialogContent, 'feedDropDownIconTransitionOpen', 'feedDropDownIconTransitionClose')}  ><DropDownIcon /></span>
                </Button>
              </Tooltip>
            </ClickAwayListener>
          </div>

          {(this.state.postsArray.length == 0 && !this.state.isLoading) ?
            <CustomNoResult text={"No ideas yet"} />
            :
            <InfiniteScroll
              dataLength={this.state.postsArray.length}
              next={this.handleFetchMoreData}
              hasMore={(this.state.totalCount / 5) > this.state.currentPage}
              scrollableTarget="scrollableDiv"
              endMessage={this.state.isLoading ? <p style={{ textAlign: 'center' }} > <img src={loader} alt="loader" /> </p> : <></>}
              loader={<p style={{ textAlign: 'center' }}><img src={loader} alt="loader" /></p>}
            >
              {this.state.postsArray.map((post: any, index) => {
                return (
                  <div style={{ marginTop: "21px" }} key={index}>
                    {this.state.isViaSavedPost && post.attributes?.saved_ideas == null ?
                      <></>
                      :
                      <ActivityFeedPost
                        id=""
                        navigation={null}
                        openPlusDialogContent={this.state.openPlusDialogContent}
                        handleTooltipCloseContent={this.handleTooltipCloseContent}
                        contextImageUpload={this.contextImageUpload}
                        handleClickOpen={this.handleClickOpen}
                        handlePlusContent={this.handlePlusContent}
                        PostData={this.getValue(this.state.isViaSavedPost , post.attributes?.saved_ideas?.data , post)}
                        index={index}
                        handleUpdateIdea={this.handleUpdateIdea}
                        displayConfirmMsg={this.state.isIdeaUpdated}
                        handleSuccessCloseIcon={this.handleSuccessCloseIcon}
                        handleUpVote={this.handleUpVote}
                        deleteIdea={this.deleteIdea}
                        requestSentArray={this.state.requestSentArray}
                        handleSendFriendRequest={this.handleSendFriendRequest}
                        friendRequestSent={this.friendRequestSent}
                        feedFilterChange={this.state.feedFilterChange}
                        feedFilter={localStorage.getItem("homeFeedFilter") || this.state.feedFilter}
                        feedFilterChangeFalse={this.feedFilterChangeFalse}
                        history={this.props.history}                        
                        isIdeaLiked={this.getValue(this.state.isViaSavedPost, false, this.state.likedIdeas.includes(Number(post?.id)))}
                      />
                    }
                  </div>
                )
              })}
            </InfiniteScroll>
          }
        </div>
        <ActivityPopup
          testId="submitpopup"
          open={this.state.isAlertOpen}
          title={this.state.alertMessageText}
          leftBtnTxt="Ok"
          handleLeftBtn={this.ideaSubmittedPopupCloseFun}
        />
      </>

      // Customizable Area End
    );
  }
}

// Customizable Area Start
//@ts-ignore
export default withRouter(ActivityFeed);
export { ActivityFeed }
// Customizable Area End
