Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.MethodTypeDELETE = "DELETE";
exports.MethodTypePOST = "POST";
exports.MethodTypePUT = "PUT";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FriendList";
exports.labelBodyText = "FriendList Body";
exports.textReportedUser = "Reported user";
exports.textReportedUserButton = "Click to unblock user";
exports.hiddenUser = "HIDDEN USER";
exports.reportedUser = "Reported user";
exports.btnExampleTitle = "CLICK ME";
exports.allContactsListEndPoint =
  "bx_block_request_management/requests?status=accepted";
exports.removeContactEndPoint =
  "remove_contact";
exports.reportProfileEndPoint = "report_account";
exports.ideaVisibilityEndPoint = "hide_profile"
exports.reportedContactsEndPoint = "bx_block_request_management/reported_contact";
exports.hiddenContactsEndPoint = "bx_block_request_management/hidden_profiles";
exports.userProfileIdeaVisibilityEndPoint = "hide_profile"
// Customizable Area End
