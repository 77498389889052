// Customizable Area Start
import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import ActivityFeedPrivacyDropDown from './ActivityFeedPrivacyDropDown';
import { IsConditionalRender } from './ActivityHelpers.web';



const ActivityFeedPrivacy = (props: any) => {
    return (
        <Tooltip
           data-testid="ActivityFeedPrivacy"
            onClose={props.onClose}
            open={props.open}
            classes={{
                tooltip: "privacyTooltip",
                arrow: "menu-pop-up-arrow",
            }}
            interactive={true}
            title={
                <React.Fragment>
                    <ActivityFeedPrivacyDropDown
                        navigation={undefined}
                        id={""}
                        userEmail={props.userMail}
                        handlePrivacyDropDown={props.handlePrivacyDropDown}
                    />
                </React.Fragment>
            }
            placement="right-start"
        >
            <button
                id="customIconButton"
                className={`privacyIcon firstCustomIconBtn ${props.userMail === localStorage.getItem("email") ? '' : 'privacyIconDisabled'}`}
                onClick={props.handlePrivacyOpen}
            >
                <IsConditionalRender
                    stateValue={props.PrivacySelectAddNewIdea}
                />
            </button>
        </Tooltip>
    );
}

export default ActivityFeedPrivacy;
export {ActivityFeedPrivacy}
  // Customizable Area End