// Customizable Area Start
import { Typography, Box, Button } from "@material-ui/core";
import React from "react";
import { GroupIcon } from "./assets";
import EmailAccountRegistrationControllerWeb from "./EmailAccountRegistrationControllerWeb";
import "./EmailAccountRegistration.web.css";
import CookieInfo from "./CookieInfo";
export default class CookiePravicyPopup extends EmailAccountRegistrationControllerWeb {
  render() {
    return (
      <>
        {!this.state.add_cookieValue ? (
          <Box
            style={{ position: "absolute", bottom: 23, right: 23, zIndex: 999,background: '#082333',
            boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
            borderRadius: 8 }}
          >
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "#082333",
                color: "#fff",
                width: "540px",
                height: "120",
                borderRadius: "8px",
              }}
              id="cookiePrivacy"
            >
              <Box style={{ padding: "38px 22px 38px 28px" }}>
                <img src={GroupIcon} width={"44px"} height={"44px"} />
              </Box>
              <Box
                style={{
                  height: "34px",
                  padding: "43px 10px 43px 0px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "12px",
                    fontWeight: 400,
                    fontFamily: "Jost-Regular",
                  }}
                >
                  We use essential cookies in order to improve your on-site
                  experience.
                </Typography>
              </Box>
              <Box style={{ padding: "55px 25px 53px 12px" }}>
                <CookieInfo navigation={undefined} id="" />
              </Box>
              <Box style={{ padding: "46px 28px 48px 2px" }}>
                <Button
                  id="addCookie"
                  variant="outlined"
                  style={{
                    borderColor: "#fff",
                    color: "#fff",
                    fontFamily: "Jost-Regular, san-sarif",
                    fontSize: "10px",
                    width:82,
                    border: '1.2px solid #F1F2F2',
                    borderRadius: 4
                  }}
                  className="cookiePopupBtn"
                  onClick={() => this.addCookies(true)}
                >
                  Accept
                </Button>
              </Box>
            </Box>
          </Box>
        ) : null}
      </>
    );
  }
}
// Customizable Area End