import React, { useState } from 'react'
import { TextField, Dialog, withStyles, createStyles, Theme, WithStyles, Typography, IconButton, DialogContent, Button } from "@material-ui/core";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';
import CustomErrorAlert from '../../../components/src/CustomErrorAlert';
// import { makeStyles } from '@mui/styles';

const styles = (theme: Theme) =>
    createStyles({
        root: {
            margin: 0,
            padding: theme.spacing(2),
        },
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        ModalBody: {
            width: '400px',
            color: "#f1f2f2",
            backgroundColor: "#091b27",
            borderRadius: '4px'
        },
        center: {
            margin: 'auto'
        },
        deflex: {
            display: 'flex'
        },
        w100: {
            width: '100%',
            // '& .Mui-focused':{
            //     background: '#012540',
            //     transition: '0.1s ease-in-out',
            // },
        },
        inputBody: {
            color: "#f1f2f2", 
            backgroundColor: "#030f17", 
            borderRadius: "4px", 
            height: "auto", 
            border: "none", 
            fontFamily: "Jost-Regular",
            fontWeight: 400,
        },
        bgWhite: {
            background: 'white'
        },
        floatingLabel: {
            paddingLeft: '0.5rem'
        },
        fontColorWhite: {
            paddingLeft: '0.5rem',
            color: "#f1f2f2",
            '&:focus':{
                background: '#012540',
                transition: '0.2s ease-in-out',
                borderRadius: 4,
        }
        }, 

    });

var hyperLink: null | string = null;
var hyperLinkText: null | string = null;

interface DialogTitleProps extends WithStyles<typeof styles> {
    id: string;
    children: React.ReactNode;
    onClose: () => void;
}


const HyperLinkModal = (props: any) => {
    const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
        const { children, classes, onClose, ...other } = props;
        return (
            <MuiDialogTitle disableTypography className={classes.root} {...other}>
                <Typography variant="h6">{children}</Typography>
                {onClose ? (
                    <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                ) : null}
            </MuiDialogTitle>
        );
    });

    const { classes } = props;

    return (
        <>
            <div>
                <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.handleClickOpen} >
                    <div className={classes.ModalBody}>
                        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                            Add Hyperlink
                        </DialogTitle>
                        <DialogContent dividers >
                            <Typography gutterBottom>
                                <div className={classes.inputBody}>
                                    <TextField
                                        className={`${classes.w100}`}
                                        id="standard-basic"
                                        label="Youtube link"
                                        onChange={e => { hyperLinkText = e.target.value }}
                                        InputLabelProps={{
                                            className: classes.floatingLabel,
                                            style : {color: "#f1f2f2"}
                                        }}
                                        inputProps={{
                                            className: classes.fontColorWhite
                                        }}
                                        autoFocus
                                        />
                                </div>
                                <CustomErrorAlert
                                    isError={props.hyperLinkError}
                                    errorMsg={"Not a valid youtube link"}
                                    handleError={props.onHyperlinkErrorClose}
                                />
                            </Typography>
                            {/* <Typography gutterBottom>
                            <TextField id="standard-basic2" label="Link" onChange={e => { hyperLink = e.target.value }} />
                        </Typography> */}
                        </DialogContent>
                        {/* <DialogActions> */}
                        <div className={classes.deflex}>
                            <Button className={classes.center} onClick={() => { props.submitHyperLink(hyperLink, hyperLinkText, props.title) }} color="primary">
                                Add
                            </Button>
                        </div>
                        {/* </DialogActions> */}
                    </div>
                </Dialog>
            </div>
        </>
    )
}


export default withStyles(styles)(HyperLinkModal)