//@ts-nocheck
export const imgBell = require("../assets/bell.png");
export const personImg = require("../assets/Person.svg");
export const notificationImg = require("../assets/Person.svg");
export const Bellnotification = require("../assets/Bellnotification.svg");

export const noBellImg = require("../assets/Bell.svg");
export const bellImg = require("../assets/Bellnotification.svg");
export const noUserImg = require("../assets/noUserImg.svg");
export const userImg = require("../assets/Person.svg");


export { ReactComponent as VerticalThreeDots } from "../assets/verticalThreeDots.svg";
export { ReactComponent as NoUser } from "../assets/noUserImg.svg";
export { ReactComponet as UserImage} from "../assets/Person.svg";
export { ReactComponent as NoBell } from "../assets/Bell.svg";
export {ReactComponent as Bell} from "../assets/Bellnotification.svg";