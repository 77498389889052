export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const contextIcon = require("../assets/context.svg");
export const problemIcon = require("../assets/problem.svg");
export const solutionIcon = require("../assets/solution.svg");
export const showMoreIcon = require("../assets/showMore.svg");
export const NotificationIcon = require("../assets/notification.svg");
export const NotificationOffIcon = require("../assets/notification_bell_off_icon.svg");
export const hidePassword = require("../assets/hidePassword.svg");
export const viewPassword = require("../assets/viewPassword.svg");
export const ReportOn = require("../assets/reportOn.svg");
export const ReportOff = require("../assets/reportOff.svg");
export const addImage = require("../assets/addImage.png");
export const noProfileImg = require("../assets/noProfile.svg");

export const ForwardIcon = require("../assets/icon_forward.svg");
export const NotifyOnIcon = require("../assets/icon_bell_on.svg");
export const NotifyOffIcon = require("../assets/icon_bell_off.svg");
export const VisibilityOnIcon = require("../assets/icon_visible_on.svg");
export const VisibilityOffIcon = require("../assets/icon_visible_off.svg");
export const ReportOnIcon = require("../assets/icon_report_on.svg");
export const ReportOffIcon = require("../assets/icon_report_off.svg");

export const advancedFilter = require("../assets/advancedFilter.svg");
export const searchIcon = require("../assets/search.svg");
export const searchIconBlack = require("../assets/searchIconBlack.svg");

//@ts-ignore
export { ReactComponent as Delete } from "../assets/delete.svg";
//@ts-ignore
export { ReactComponent as SearchIcon } from "../assets/search.svg";
