// Customizable Area Start
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import ActivityFeedPost from "./ActivityFeedPost.web";
import ActivityFeedController, { profileId } from "./ActivityFeedController";
import UserProfileCard from "../../FriendList/src/UserProfileCard.web";
import { loader } from "./assets";

export class UserProfile extends ActivityFeedController {
  render() {
        return (
      <div id="scrollableDiv" >
        {!this.state.isLoading &&
          <UserProfileCard
            bannerFromBackend={this.state.bannerFromBackend}
            userProfilePic={this.state.profilePicFromBackend}
            reportedUser={this.state.userReported}
            hiddenUser={this.state.userBlocked}
            userIdeaVisibility={this.state.userIdeaVisibility}
            userName={this.state.userName}
            firstName={this.state.firstName}
            lastName={this.state.lastName}
            userEmail={this.state.userEmail}
            profileId={profileId}
            handleSendFriendRequest={this.handleUserProfileFriendRequest}
            requestSentArray={this.state.requestSentArray}
            description={this.state.description || ''}
            navigation={undefined} id={""}
          />
        }
        <div style={{ marginTop: '-40px' }}>
          <InfiniteScroll
            dataLength={this.state.postsArray.length}
            next={this.handleFetchMoreData}
            hasMore={(this.state.totalCount / 5) > this.state.currentPage}
            scrollableTarget="scrollableDiv"
            endMessage={this.state.isLoading ? <p style={{ textAlign: 'center' }} > <img src={loader} alt="loader" /> </p> : <></>}
            loader={<p style={{ textAlign: 'center' }}><img src={loader} alt="loader" /></p>}
          >
            {this.state.postsArray.map((PostData:any, index:any) => (
              <ActivityFeedPost
                key={+PostData.id}
                id=""
                navigation={null}
                openPlusDialogContent={this.state.openPlusDialogContent}
                handleTooltipCloseContent={this.handleTooltipCloseContent}
                contextImageUpload={this.contextImageUpload}
                handleClickOpen={this.handleClickOpen}
                handlePlusContent={this.handlePlusContent}
                PostData={PostData}
                handleUpdateIdea={this.handleUpdateIdea}
                displayConfirmMsg={this.state.isIdeaUpdated}
                handleSuccessCloseIcon={this.handleSuccessCloseIcon}
                isIdeaLiked={this.state.likedIdeas.includes(Number(PostData?.id))}
                handleUpVote={this.handleUpVote}
                index={index}
                deleteIdea={this.deleteIdea}
                requestSentArray={this.state.requestSentArray}
                handleSendFriendRequest={this.handleSendFriendRequest}
                friendRequestSent={this.friendRequestSent}
                feedFilterChange={this.state.feedFilterChange}
                feedFilter={this.state.feedFilter}
                feedFilterChangeFalse={this.feedFilterChangeFalse}
                history={this.props.history}
                activeContainerZindex={0}
              />
            ))}
          </InfiniteScroll>
        </div>
      </div>
    );
  }
}
export default UserProfile;
// Customizable Area End