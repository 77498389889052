import React from "react";
import { Input } from "reactstrap";
import "./Common.web.css";
import CustomErrorAlert from "./CustomErrorAlert";

const CustomInput = (props: any) => {
  const { disabled = false } = props;
  return (
    <>
      <Input
        id={props.id}
        placeholder={props.placeholder}
        type={props.type}
        autoComplete="off"
        className={props.className}
        onChange={props.handleInputChange}
        value={props.value}
        onKeyDown={(e: any) => {
          if (
            props.placeholder === "Country or state" ||
            props.placeholder === "City or locality" 
          ) {
            !/^[a-zA-Z ]+$/.test(e.key) && e.preventDefault();
          }
        }}
        onKeyPress={(e)=>{
          if(e.key === "Enter"){
            if(props.placeholder === "Confirm new e-mail address" || 
            props.id === "forgetpassword"){
              props.pressEnter()
            }    
          }
        }}
        disabled={disabled}
      />
      <CustomErrorAlert {...props} />
    </>
  );
};
export default CustomInput;
