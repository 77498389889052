// Customizable Area Start
import React from "react";
import { Button, Checkbox } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Link } from "react-router-dom";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";
import CustomInput from "../../../components/src/CustomInput";
import CustomPassword from "../../../components/src/CustomPassword";
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import ProfileImageUpload from "../../user-profile-basic/src/userProfileComponent/profileImageUpload";
import "./EmailAccountRegistration.web.css";
import EmailAccountRegistrationControllerWeb, {
  Props,
} from "./EmailAccountRegistrationControllerWeb";
import TermsAndConditions from "./TermsAndConditions.web";
import { AddProfileImage, Logo } from "./assets";
const configJSON = require("./config");

export default class EmailAccountRegistration extends EmailAccountRegistrationControllerWeb {
  constructor(props: Props) {
    super(props);
  }
  profileImageCrop = (img: any, file: any) => {
    this.setState({ profilePicImg: img });
    this.setState({ profilePicImgBinary: file });
  };
  AddCropImageDailog = () => {
    this.setState({
      isImageDailog: false,
      isImageCropDailog: false,
      isEditImageDailog: false,
    });
  };
  handleDate = (value: string, type: "day" | "month" | "year") => {

  }
  render() {
    return (
      <>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <section className="mainContainer">
          <img src={Logo} className="logo" alt="logo" style={{ marginTop: "100px", marginBottom: "126px" }} />
          <div className="signUporInContainer signUpbox">

            <div className={`profileUpload ${this.state.isprofilePicError ? "errorPicUpload" : ""}`}>
              <img
                src={this.state.profilePicImg || AddProfileImage}
                alt="profilepic"
                className={
                  this.state.profilePicImg
                    ? "regProfileImg"
                    : "regNoprofileImg"
                }
                onClick={(event) => {
                  this.setImageData(event);
                }}
              />
              <input
                data-testid="profilePicTest"
                accept="image/x-png,image/jpeg"
                id="regProfileInput"
                onChange={(event) => {
                  this.setImageData(event);
                }}
                type="file"
                className={
                  this.state.profilePicImg
                    ? "regProfileInputBlock"
                    : "regNonProfileInputBlock"
                }
              />
            </div>
            {this.state.isImageDailog && (
              <ProfileImageUpload
                modelTitle={"Profile Photo"}
                imgUrl={
                  this.state.profilePicImageCrop ||
                  this.context.profile_pic ||
                  AddProfileImage
                }
                handleImage={this.profileImageCrop}
                handleCloseDailog={this.AddCropImageDailog}
                navigation={undefined}
                id={""}
                handleDate={this.handleDate}
                day={undefined}
                month={undefined}
                year={undefined}
                dayErr={false}
                monthErr={false}
                yearErr={false}
                errorMsg={undefined}
                img={undefined}
              />
            )}
            {/* <h2 className="signUporInText">SIGN UP</h2> */}

            <div className="twoLabelContainer">
              <div className={`form-group width50 ${this.getStyle(this.state.isFirstNameError, "inputError", "")}`}>
                <CustomInput
                  data-testid="txtInputFirstname"
                  id="txtInputFirstname"
                  name='firstName'
                  placeholder={configJSON.labelFirstName}
                  type="text"
                  className="firstName"
                  value={this.state.firstName}
                  // handleInputChange={(e:any) => this.handleFirstname(e)}
                  handleInputChange={this.handleFirstname}
                  isError={this.state.isFirstNameError}
                  handleError={this.handleFirstNameError}
                  errorMsg={configJSON.errorNameInvalid}
                />
              </div>
              <div className={`form-group width50 ${this.getStyle(this.state.isLastNameError, "inputError", "")}`}>
                <CustomInput
                  data-testid="txtInputLastname"
                  id="txtInputLastname"
                  name='lastName'
                  placeholder={configJSON.labelLastName}
                  type="text"
                  className="lastName"
                  value={this.state.lastName}
                  handleInputChange={this.handleLastname}
                  isError={this.state.isLastNameError}
                  handleError={this.handleLastNameError
                  }
                  errorMsg={configJSON.errorNameInvalid}
                />
              </div>
            </div>
            <div className={`form-group ${this.getStyle(this.state.isUserNameError, "inputError", "")}`}>
              <CustomInput
                data-testid="txtInputUserName"
                id="txtInputUserName"
                placeholder={configJSON.labelUserName}
                name='userName'
                type="text"
                handleInputChange={this.handleUserName}
                value={this.state.userName}
                isError={this.state.isUserNameError}
                handleError={this.handleUserNameError}
                // errorMsg={configJSON.errorUserNameExists}
                errorMsg={this.state.errorMsgText}
              />
            </div>
            <div className={`form-group ${this.getStyle(this.state.isEmailError, "inputError", "")}`}>
              <CustomInput
                data-testid="txtInputEmail"
                id="txtInputEmail"
                name='email'
                placeholder={configJSON.labelEmail}
                type="text"
                handleInputChange={this.handleEmailChange}
                //@ts-ignore
                value={this.state.email}
                isError={this.state.isEmailError}
                errorMsg={this.state.errorMsgText}
                handleError={this.handleEmailError}
                disabled={this.state.isURLReferralEmail ? true : false}
              />
            </div>
            <div className={`form-group pwdError ${this.getStyle(this.state.isPasswordError, "inputError", "")}`}>
              <CustomPassword
                data-testid="txtInputPassword"
                id="txtInputPassword"
                name='password'
                placeholder={configJSON.labelPassword}
                value={this.state.password}
                handlePasswordChange={this.handlePasswordChange}
                isError={this.state.isPasswordError}
                errorMsg={configJSON.errorPasswordNotValid}
                handleError={this.handlePasswordError}
              />
            </div>
            <div className={`form-group ${this.getStyle(this.state.isTokenError, "inputError", "")}`}>
              <CustomInput
                data-testid="txtInputRegistrationCode"
                id="txtInputRegistrationCode"
                placeholder={configJSON.labelRegistrationCode}
                type="text"
                name='referralCode'
                value={this.state.referralCode}
                handleInputChange={this.handleRegistrationToken}
                isError={this.state.isTokenError}
                handleError={this.handleTokenError}
                errorMsg={this.state.errorMsgText}
                disabled={!!this.state.isURLReferralCode}
              />
            </div>

            <div
              className={`alertContainer regSuccessMsg ${this.state.regSuccess ? "" : "hideRegSuccessmsg"
                }`}
            >
              <Alert severity="success">{configJSON.regSuccessMsg}</Alert>
            </div>

            <div className="LoginErrorBox">
              <CustomErrorAlert
                isError={this.state.isFieldEmpty}
                handleError={this.handleRegistrationError}
                errorMsg={configJSON.errorStarredFieldsAreMandatory}
              />
            </div>
            <div className="LoginErrorBox">
              <CustomErrorAlert
                isError={this.state.isprofilePicError}
                handleError={this.handleProfileError}
                errorMsg={configJSON.errorProfilePicMandatory}
              />
            </div>
            <div className="LoginErrorBox">
              <CustomErrorAlert
                isError={this.state.isCheckBoxError}
                handleError={this.handleCheckboxError}
                errorMsg={configJSON.errorCheckBoxUnchecked}
              />
            </div>

            <div className="termsContainer">
              <Checkbox
                data-testid="termsCheckbox"
                className="termsCheckbox"
                value={this.state.isCheckBoxChecked}
                checked={this.state.isCheckBoxChecked}
                onClick={this.handleTermsAndConditionCheckBox}
                onKeyPress={this.handleCheckBoxEnterKeyDown}
              />

              <label className="termsText">{configJSON.labelLegalText}</label>
              <TermsAndConditions
                respData={this.state.termsAndConditonsContent}
                termsConditionFun={this.getTermsAndConditions}
              />
            </div>

            <div className="submitButton">
              <Button onClick={this.handleSubmit}>
                {configJSON.labelSubmit}
              </Button>
            </div>

            <div className="loginRedirectionText">
              <label className="termsText">{configJSON.labelLoginRedirection}</label>
              <Link to="/">
                <label className="loginLinkText">{configJSON.loginText}</label>
              </Link>
            </div>
          </div>
        </section>
      </>
    );
  }

}
// Customizable Area End
