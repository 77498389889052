// Customizable Area Start
import React, { useContext } from "react";
import { Collapse, Button } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Link, withRouter } from "react-router-dom";
import { SettingsIcon, GroupsIcon } from "../assets";
//@ts-ignore
import UserDetailsContext from "../../../web/src/Context/Context.web";
const configJSON = require("../config.js");
import { getResizedProfilepic } from "../CommonFunctions.web";

const EditProfilepopUp = (props: any) => {
  const user = useContext(UserDetailsContext);
  const handleOnSignOut = () => {
    user.setProfilePic("");
    props.history.push("/");
  };

  return (
    <>
      <div className="editProfileContainer">
        <Collapse in={true}>
          <Alert icon={false} className="profileBox profileBoxArrowTop">
            <div className="barIconContainer">
              <Link to="/UserProfileBasicBlock" onClick={props.handleClose}>
                <Button className="barIcon leftBtn onHoverIcon">
                  <SettingsIcon />
                </Button>
              </Link>
              <div className="barProfilePic">
                <img
                  src={getResizedProfilepic()}
                  alt="profile"
                  id="Web_user_profile1"
                  className={
                    localStorage.getItem("profilepic") !== "null"
                      ? "barProfilePicImg"
                      : "barProfilePicImgNull"
                  }
                />
              </div>
              <Link to="/FriendList" onClick={props.handleClose}>
                <Button className="barIcon rightBtn onHoverIcon">
                  <GroupsIcon />
                </Button>
              </Link>
            </div>
            <div className="barDetailsContainer"
            >
              <div className="barProfileDetails name">
                {localStorage.getItem("username")}
              </div>
              <div className="barProfileDetails mail">
                {localStorage.getItem("email")}
              </div>
            </div>
            <div className="barButtonContainer">
              <Link to="/IdeaLibrary" style={{ textDecoration: "none" }} onClick={props.handleClose}>
                <Button className="barButton leftBtn">
                  {configJSON.labelIdeaLibrary}
                </Button>
              </Link>
              <Link to="/SavedPosts" style={{ textDecoration: "none" }} onClick={props.handleClose}>
                <Button className="barButton rightBtn ">
                  {configJSON.labelSavedIdeas}
                </Button>
              </Link>
            </div>
          </Alert>
        </Collapse>
        <div className="signOutContainer" onClick={handleOnSignOut}>
          <Button className="signOutText">
            {configJSON.btnSignOut}
          </Button>
        </div>
      </div>
    </>
  );
};

export default withRouter(EditProfilepopUp);
// Customizable Area End