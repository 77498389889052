import React from "react";
import "./Common.web.css";
import MenuIcon from "@material-ui/icons/Menu";
import { NavLink } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import SecurityOutlinedIcon from "@material-ui/icons/SecurityOutlined";
import MailOutlinedIcon from "@material-ui/icons/MailOutlined";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import { personIcon, privacyIcon, chatIcon, globeIcon} from './assets'

const CustomMenuSideBar = () => {
  return (
    <>
      <NavLink to="/UserProfileBasicBlock" className="SideIcon">
        <img src={personIcon} className="iconColor"/>
      </NavLink>
      <NavLink to="/NewPassword" className="SideIcon">
        <img src={privacyIcon} className="iconColor"/>
      </NavLink>
      <NavLink to="/ChangeEmailAccount" className="SideIcon">
        <MailOutlinedIcon className="iconColor" />
      </NavLink>
      <NavLink to="/FeedBack" className="SideIcon">
        <img src={chatIcon} className="iconColor"/>
      </NavLink>
      <NavLink to="/InfoPage" className="SideIcon">
        <img src={globeIcon} className="iconColor"/>
      </NavLink>    
    </>
  );
};

export default CustomMenuSideBar;
