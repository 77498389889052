import React from "react";

import {
  Typography,
  Tooltip,
  ClickAwayListener,
  // Customizable Area Start
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./ActivityFeed.css";
import { DropDownIcon, loader } from "./assets";
import ActivityFeedController, { Props } from "./ActivityFeedController";
import ActivityFeedPost from "./ActivityFeedPost.web";
import FeedDropDown from "./FeedDropDown.web";
import InfiniteScroll from "react-infinite-scroll-component";
import CustomNoResult from "../../../components/src/CustomNoResult";
// Customizable Area End

export default class IdeaLibrary extends ActivityFeedController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start  

  render() {

    return (
      <>
        <div id="scrollableDiv">
          <div className="feedFilterDiv w-15" style={{ paddingTop: 35 }}>
            <ClickAwayListener onClickAway={this.handleTooltipCloseContent}>
              <Tooltip
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    modifiers: {
                      offset: {
                        enabled: true,
                        offset: "40px, 0px",
                      },
                    },
                    tooltipPlacementRight: {
                      right: "1px",
                      top: "80px",
                    },
                  },
                }}
                onClose={this.handleTooltipCloseContent}
                open={this.state.openPlusDialogContent}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                classes={{
                  tooltip: "menu-pop-up",
                  arrow: "menu-pop-up-arrow",
                }}
                interactive={true}
                title={
                  <React.Fragment>
                    <FeedDropDown
                      id=""
                      navigation={undefined}
                      feedFilter={localStorage.getItem("libraryFeedFilter") || this.state.libraryFeedFilter}
                      handleFeedFilter={this.handleLibraryPostsFilter}
                      feedOptions="libraryOptions"
                    />
                  </React.Fragment>
                }
                arrow={true}
                placement="right-start"
              >
                <div
                  className="parentofFeedFilter"
                  onClick={this.handlePlusContent}
                >
                  <p
                    className={`feedFilterText ${this.state.openPlusDialogContent ? "text-white" : null
                      }`}
                  >
                    {/* Below is the display for Feed Filter  */}
                    {localStorage.getItem("libraryFeedFilter") || this.state.libraryFeedFilter}
                  </p>
                  <span
                    className={`${this.state.openPlusDialogContent
                        ? "feedDropDownIconTransitionOpen"
                        : "feedDropDownIconTransitionClose"
                      }`}
                  >
                    <DropDownIcon />
                  </span>
                </div>
              </Tooltip>
            </ClickAwayListener>
          </div>
          {this.state.postsArray.length === 0 && !this.state.isLoading ? (
            <CustomNoResult text={"No Ideas Posted by you"} />
          ) : (
            <InfiniteScroll
              dataLength={this.state.postsArray.length}
              next={this.handleFetchMoreData}
              hasMore={this.state.totalCount / 5 > this.state.currentPage}
              scrollableTarget="scrollableDiv"
              endMessage={this.state.isLoading ? <p style={{ textAlign: 'center' }} > <img src={loader} alt="loader" /> </p> : <></>}
              loader={<p style={{ textAlign: 'center' }}><img src={loader} alt="loader" /></p>}
            >
              {this.state.postsArray.map((PostData: any, index) => (
                <div style={{ marginTop: "1rem" }} key={index}>
                  <ActivityFeedPost
                    id=""
                    index={index}
                    navigation={null}
                    openPlusDialogContent={this.state.openPlusDialogContent}
                    handleTooltipCloseContent={this.handleTooltipCloseContent}
                    contextImageUpload={this.contextImageUpload}
                    handleClickOpen={this.handleClickOpen}
                    handlePlusContent={this.handlePlusContent}
                    PostData={PostData}
                    handleUpdateIdea={this.handleUpdateIdea}
                    displayConfirmMsg={this.state.isIdeaUpdated}
                    handleSuccessCloseIcon={this.handleSuccessCloseIcon}
                    handleUpVote={this.handleUpVote}
                    deleteIdea={this.deleteIdea}
                    requestSentArray={this.state.requestSentArray}
                    handleSendFriendRequest={this.handleSendFriendRequest}
                    friendRequestSent={this.friendRequestSent}
                    feedFilterChange={this.state.feedFilterChange}
                    feedFilter={localStorage.getItem("libraryFeedFilter") || this.state.libraryFeedFilter}
                    feedFilterChangeFalse={this.feedFilterChangeFalse}
                    history={this.props.history}
                  />
                </div>
              ))}
            </InfiniteScroll>
          )}
        </div>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
