// Customizable Area Start
import React from "react";
import "./UserProfileCard.web.css";
import { Box, Button } from "@material-ui/core";
import {
  AddContactIcon,
  ReportUser,
  HidenUser,
  ReportOff,
  HoverEdit,
  ImageLogo,
} from "./assets";
import UserProfileTooltip from "./UserProfileTooltip.web";
import UserProfileCardController from "./UserProfileCardController.web";
import { Link } from "react-router-dom";
// @ts-ignore
import { baseURL } from "../../../framework/src/config.js";
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import ActivityPopup from "../../ActivityFeed/src/ActivityPopup.web";
class UserProfileCard extends UserProfileCardController {
  isConditionalClass(
    condition: any,
    condition1: any,
    condition2: any,
    value1: any,
    value2: any
  ) {
    return (condition && condition1) || condition2 ? value1 : value2;
  }
  isConditionalRender(condition1: any, value1: any, value2: any) {
    return condition1 ? value1 : value2;
  }
  handleFriendRequestFun = () =>
    this.props.handleSendFriendRequest(
      String(this.props.profileId),
      !this.props.requestSentArray?.includes(String(this.props.profileId))
    );
  handleReportUserFun = () =>
    this.reportUserProfile(this.props.profileId, true);
  handleReportUserOffFun = () =>
    this.reportUserProfile(this.props.profileId, false);
  handleIdeaVisibilityFun = () => {
    if (this.props.hiddenUser) {
      this.userIdeaVisibility(this.props.profileId, "false");
      this.setState({ ideaVisibility: !this.state.ideaVisibility })
    } else {
      this.userIdeaVisibility(this.props.profileId, "true");
      this.setState({ ideaVisibility: !this.state.ideaVisibility })
    }
  }
  async componentDidMount() {
    this.setState({
      userName: this.props.userName ?? "",
      firstName: this.props.firstName,
      lastName: this.props.lastName,
      userEmail: this.props.userEmail ?? "",
      bannerFromBackend: this.props.bannerFromBackend,
      userProfilePic: this.props.userProfilePic,
      currentUsername: localStorage.getItem("username"),
      ideaVisibility: this.props.userIdeaVisibility,
      description: this.props.description || ""
    });
  }

  getCheckUserType() {
    return this.state.currentUsername !== this.props.userName && !this.props.reportedUser || this.props.hiddenUser
  }
  render() {
    let {
      userName,
      firstName,
      lastName,
      userEmail,
      bannerFromBackend,
      reportedUser,
      hiddenUser,
      userProfilePic,
    } = this.props;
    return (
      <>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <div style={{ position: "relative" }} className="profileCardContainer" test-id="userProfileCard">
          <Box
            className={this.isConditionalClass(
              this.state.currentUsername !== userName,
              reportedUser,
              hiddenUser,
              "userProfileReported",
              ""
            )}
          >
            <section className={"userProfileBackgroundImg"}>
              <img
                className={this.isConditionalRender(
                  bannerFromBackend,
                  "UserProfilebackImg",
                  "noUserImg"
                )}
                src={this.isConditionalRender(
                  bannerFromBackend,
                  baseURL + bannerFromBackend,
                  ImageLogo
                )}
              />
            </section>
            <section className="userProfileBackgroundDetail">
              <div className="userInfoContainer">
                <div className="userNameContainer">
                  <p className="feedUserName userProfileUserName">
                    {firstName} {lastName}
                  </p>
                  <p className="userLastName">
                    {userName} {this.displayUserEmail(this.props.requestSentArray, this.props.profileId, userEmail)}
                  </p>
                </div>
              </div>

              <div className="userProfilePicContainer">
                <div className={"userProfilePic"}>
                  <img
                    src={this.isConditionalRender(
                      userProfilePic,
                      baseURL + userProfilePic,
                      ImageLogo
                    )}
                    className={this.isConditionalRender(
                      userProfilePic,
                      "userProfilepicimg",
                      "noUserImg"
                    )}
                  />
                </div>
              </div>

              <div className={`userAddContactContainer ${this.props.profileId.toString() === localStorage.getItem("anonymousId") ? "feedDisableBtn" : ""}`}>
                {this.getCheckUserType() ? (
                  <Button
                    className="feedAddContactBtn"
                    onClick={this.handleFriendRequestFun}
                    disabled={this.props.profileId.toString() === localStorage.getItem("anonymousId")}
                  >
                    {this.getContactIcon(this.props.requestSentArray, this.props.profileId)}
                    <p className="userAddContact">
                      {this.getContactStatusText(this.props.requestSentArray, this.props.profileId)}
                    </p>
                  </Button>
                ) : (
                  <Link to="/UserProfileBasicBlock" className="EditBtnLink" style={reportedUser ? { display: 'none' } : {}}>
                    <Button className="feedEditContactBtn">
                      <HoverEdit style={{ width: 12 }} />
                      <p className="userAddContact">Edit Profile</p>
                    </Button>
                  </Link>
                )}
                {this.state.currentUsername !== userName && (
                  <div className="userThreeDotBtn" style={reportedUser ? { display: 'none' } : {}}>
                    <UserProfileTooltip
                      testId="threeIconButton"
                      reportUserProfileFun={this.handleReportUserFun}
                      ideaVisibilityOnFun={this.handleIdeaVisibilityFun}
                      ideaVisibleBoolean={!hiddenUser}
                      feedFilter={""}
                      hiddenUserIcon={false}
                      reportUserValue={reportedUser}
                    />
                  </div>
                )}
              </div>

            </section>
            {this.state.description != "" ?
              <section className="userProfileDescription">
                {this.state.description}
              </section>
            : <></>}
          </Box>
          {this.state.currentUsername !== userName ? (
            <>
              {reportedUser || hiddenUser ? (
                <>
                  <section className="reportIconContainer">
                    {this.isConditionalRender(
                      reportedUser,
                      <ReportUser />,
                      <HidenUser />
                    )}
                  </section>
                  <section className="reportTextContainer">
                    {this.isConditionalRender(
                      reportedUser,
                      <p className="reporteduserText">Reported user</p>,
                      <p className="hidenuserText">hidden user</p>
                    )}
                  </section>
                  <section className={`${this.isConditionalRender(
                    reportedUser,
                    "reportedUserBtnContainer",
                    "hiddenUserBtnContainer"

                  )}`}>
                    {reportedUser ? (
                      <Button
                        className="contactButton reportedButton"
                        onClick={this.handleReportUserOffFun}
                      >
                        <ReportOff />
                        <p className="reportAddContact">Click to unblock user</p>
                      </Button>
                    ) : (
                      <>
                        <Button
                          className="contactButton hiddenUserContactBtn"
                          onClick={this.handleFriendRequestFun}
                        >
                          <AddContactIcon />
                          <p className="userAddContact">Remove from contacts</p>
                        </Button>
                        <Button className="hiddenButton">
                          <UserProfileTooltip
                            testId="threeIconButton"
                            feedFilter={""}
                            hiddenUserIcon={true}
                            reportUserProfileFun={this.handleReportUserFun}
                            ideaVisibilityOnFun={this.handleIdeaVisibilityFun}
                            ideaVisibleBoolean={!hiddenUser}
                            reportUserValue={reportedUser}
                          />
                        </Button>
                      </>
                    )}
                  </section>
                </>
              ) : null}
            </>
          ) : null}
        </div>

        <ActivityPopup
          testId="submitpopup"
          open={this.state.isAlertOpen}
          title={this.state.alertMessageText}
          leftBtnTxt="ok"
          handleLeftBtn={this.handleCloseAlertMessagePopup}
        />
      </>
    );
  }
}

export default UserProfileCard;
// Customizable Area End
