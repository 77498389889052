// Customizable Area Start
import React from "react";
import { Box } from "@material-ui/core";
import "./PopUpMenu.web.css";
import { ImageLogo, VideoLogo } from "../../../postcreation/src/assets";
import CropEasy from "../../../user-profile-basic/src/userProfileComponent/CropEasy";


const PopUpMenuFeedBack: React.FC<any> = (props) => {

  const ImageInputRef = React.createRef<HTMLInputElement>();
  const VideoInputRef = React.createRef<HTMLInputElement>();
  
  const handleImageRef = () => { ImageInputRef.current?.click()}
  const handleVideoRef = () => { VideoInputRef.current?.click()}
  const handleVideoFun = (e:any) => {
    props.contextImageUpload(e, props.title)
   }
   const handleCropImage = (img:any,file:any) => props.profileImageCrop(img,file,props.title)
   const handleImageFun =(e:any) =>  props.setImageData(e,props.title)

  return (
    <>
      <Box component="span" m={1} className="box-body">
          <div className="title-box" data-testid="imgWrapper" onClick={handleImageRef}>
            <img src={ImageLogo} alt="" className="title-icons" 
            onClick={handleImageFun}
            />
            <p className="menu-title">Image</p>
            <input
              data-testid = "txtInputimg"
              accept=".png, .jpg, .jpeg"
              id="inputinfeedback"
              type="file"
              ref={ImageInputRef}
              onChange={handleImageFun}
            />
          </div>
           {props.imageCropPopup && (
                    <CropEasy
                      modelTitle={"Crop Photo"}
                      imgUrl={
                        props.ideaPicImageCrop ||
                        ImageLogo
                      }
                      handleImage={handleCropImage}
                      handleCloseDailog={props.AddCropImageDailog}
                      navigation={undefined}
                      id={""}
                      handleDate={props.handleDate}
                      day={undefined}
                      month={undefined}
                      year={undefined}
                      dayErr={false}
                      monthErr={false}
                      yearErr={false}
                      errorMsg={undefined}
                      img={undefined}
                    />
                  )}
          <div className="title-box" data-testid="vidWrapper" onClick={handleVideoRef}>
            <img src={VideoLogo} alt="" className="title-icons" />
            <p className="menu-title">Video</p>
            <input
              accept="video/*"
              id="inputinfeedback"
              type="file"
              ref={VideoInputRef}
              onChange={handleVideoFun}
            />
          </div>
    
        </Box>
    </>
  )

}

export default PopUpMenuFeedBack;
// Customizable Area End