//Customizable Area Start
import { FormGroup } from "@material-ui/core";
import React from "react";
import { Button, Row, Col, Label, Collapse } from "reactstrap";
import CustomInput from "../../../components/src/CustomInput";
import { Logo } from "./assets";
import "../../email-account-registration/src/EmailAccountRegistration.web.css";
import "./ForgetPassword.web.css";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";
import Alert from "@material-ui/lab/Alert";
import ForgotPasswordControllerWeb, {Props} from "./ForgotPasswordControllerWeb";
// @ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";

const configJSON = require("./config");
//Customizable Area End
export default class ForgotPassword extends ForgotPasswordControllerWeb {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    //Customizable Area End
  }
  //Customizable Area Start
  webStyle = {
    emailEnterContaner: {
      marginBottom: 0
    },
    forgetEmailSubmit: {
      marginBottom: 20
    }
  };

  render() {
    return (
      <>
      {this.state.isLoading && <FullScreenSnippingLoader />}
      <section className="mainContainer">
        <img src={Logo} className="logo" alt="logo" />
        <div className="forgetPasswordContainer">
          <h2 className="forgetPasswordHeading">
            {configJSON.forgetPasswordHeading}
          </h2>
          <h2 id="simple-modal-title" className="forgetPasswordIntruction">
            {configJSON.forgetPasswordInstruction}
          </h2>
          <FormGroup
            className={`emailEnterContaner  ${
              this.state.forgotEmailError ? `inputError` : ``
            }`}
            style={
              this.state.forgotPasswordSubmited
                ? this.webStyle.emailEnterContaner
                : this.webStyle.forgetEmailSubmit
            }
          >
            <CustomInput
              data-testid="txtInputEmail"
              type="text"
              id="forgetpassword"
              name="forgetpassword"
              className="forgetPasswordInputfeild"
              placeholder="E-mail"
              handleInputChange={this.handleForgotPasswordText}
              value={this.state.forgotPasswordEmail}
              pressEnter={this.handleForgotPasswordSubmit}
            />
            {this.state.forgotPasswordSubmited && (
              <Collapse
                isOpen={this.state.forgotPasswordSubmited}               
                unmountOnExit
                className="emailSuccessMessage"
              >
                <Alert severity="success" className="">
                  {configJSON.emailSuccessMessage}
                </Alert>
              </Collapse>
            )}
            <div className="errormessageContainer">
              <CustomErrorAlert
                isError={this.state.forgotEmailError}
                handleError={this.handleCloseError}
                errorMsg={this.state.forgetPasswordApiErrMsg}
              />
            </div>
          </FormGroup>
          <div className="forgetPasswordSubmit">
            <Button
              data-testid="submitBtnId"
              variant="outlined"
              onClick={this.handleForgotPasswordSubmit}
              className="forgetPasswordSubmitBtn"
            >
              {configJSON.forgetPasswordButton}
            </Button>
          </div>
        </div>
        <Row className="copyrightTex-container">
          <Col md={12} className="copyrightText">
            <Label>{configJSON.labelCopyRight}</Label>
          </Col>
        </Row>
      </section>
      </>
    );
  }
   //Customizable Area End
}
//Customizable Area Start
//Customizable Area End
