// Customizable Area Start
import React from "react";
import { Input, InputAdornment, Button } from "@material-ui/core";
import { ChatIcon } from "./assets";
import "./FeedBack.web.css";
import CustomErrorAlert from "../../../components/src/CustomErrorAlert";
import FeedBackController, { Props } from "./FeedBackController";
import CustomMenuSideBar from "../../../components/src/CustomMenuSideBar";
import ProtectedRouteWeb from "../../email-account-login/src/ProtectedRoute.web";
//@ts-ignore
import FullScreenSnippingLoader from "../../../web/src/assets/SpinningLoader/FullScreenSnippingLoader";
import ActivityPopup from "../../ActivityFeed/src/ActivityPopup.web";
import {
  ContextIcon,
  SolutionIcon,
  ProblemIcon
} from "../../postcreation/src/assets";
import CustomFeedInput from "../../../components/src/CustomFeedInput";
// Customizable Area End

class FeedBack extends FeedBackController {
    constructor(props: Props) {
      super(props);
      // Customizable Area Start
      // Customizable Area End
    }

  // Customizable Area Start

  render() {
    const tooltiptext = {
      context: [
        {
          id: "1",
          text:
            "Where and when did you encounter the problem you would like to see solved by the Idea Atlas Team? Add a full screen screenshot if possible."
        }
      ],
      problem: [
        {
          id: "1",
          text:
            "What is the problem in your view? Please let us know if you encounter it before or if you believe it is a major and/or urgent issue. Feel free to add a specific screenshot or error code where applicable."
        },
        {
          id: "2",
          text:
            "If you would like to make a suggestion for a new feature or intending to share your UI/UX idea, you can leave this box empty."
        }
      ],
      solution: [
        {
          id: "1",
          text:
            "Do you know how to solve the problem? How would you approach it? Outline the best option(s) or feel free to share a specific solution by attaching an image/screenshot, hyperlink, or video."
        }
      ]
    };
    return (
      <ProtectedRouteWeb>
        {this.state.isLoading && <FullScreenSnippingLoader />}
        <section className="contactUsContainer sideBarContainer">
          <section className="sideBar">
            <CustomMenuSideBar />
          </section>
          <section
            className="sideBarInnerContainer feedbackContainer"
            style={{ width: "798.23px", border: "1.6px solid #0F2C40" }}
          >
            <div style={webStyle.frontbox}>
              <p style={webStyle.dialogBoxtitle}>FEEDBACK</p>

              {/* Below box for Title Input */}
              <div
                className="flex-box sm-view addTitleBox"
                style={{ justifyContent: "space-between" }}
              >
                <Input
                  id="title"
                  name="title"
                  placeholder="Type your title here..."
                  type="text"
                  className={`AddNewIdeaInput text-color-white ${this.getStyle(
                    this.state.TitleText,
                    "titleAdded",
                    ""
                  )}`}
                  onChange={this.handleTitleTextInput}
                  value={this.state.TitleText}
                  endAdornment={
                    this.state.TitleText.length !== 0 ? (
                      <InputAdornment position="end">
                        {this.state.TitleText.length}/44
                      </InputAdornment>
                    ) : null
                  }
                  inputProps={{ maxLength: 44, width: "100%" }}
                  disableUnderline={true}
                />
                <div className="feedbackDiv">
                  <ChatIcon style={webStyle.FeedbackIcon} />
                  <p className="feedbackText">Feedback</p>
                </div>
              </div>

              {/* Below box for Context */}

              <CustomFeedInput
                feedType="context"
                feedIcon={ContextIcon}
                tooltiptext={tooltiptext}
                feedText={this.state.ContextText}
                handleFeedTextUndo={this.handleContextUndo}
                handleFeedTextInput={this.handleContextTextInput}
                errorInFeedText={this.state.ErrorInContent}
                errorInFeedMedia={this.state.ErrorInContentMedia}
                handleTextRemoveIcon={this.handleCntxtRemoveIcon}
                feedImagePreview={this.state.contextImagePreview}
                feedImageUpload={this.contextImageUpload}
                imageCropPopup={this.state.imageCropPopup}
                profileImageCrop={this.profileImageCrop}
                handleDate={this.handleDate}
                addCropImageDailog={this.AddCropImageDailog}
                setImageData={this.setImageDataFeedback}
                ideaPicImageCrop={this.state.ideaPicImageCrop}
                feedVideoUpload={this.state.contextVideoUpload}
                openPlusDialog={this.state.openPlusDialogContent}
                handleFeedPlus={this.handlePlusContent}
                handleTooltipClose={this.handleTooltipCloseContent}
              />

              {/* Below box for Problem and solution*/}

              <div className="flex-box justify-content smbottom">
                <CustomFeedInput
                  feedType="problem"
                  feedIcon={ProblemIcon}
                  tooltiptext={tooltiptext}
                  feedText={this.state.ProblemText}
                  handleFeedTextUndo={this.handleProblemUndo}
                  handleFeedTextInput={this.handleProblemTextInput}
                  errorInFeedText={this.state.ErrorInProblem}
                  errorInFeedMedia={this.state.ErrorInProblemMedia}
                  handleTextRemoveIcon={this.handleProbRemoveIcon}
                  feedImagePreview={this.state.problemImagePreview}
                  feedImageUpload={this.contextImageUpload}
                  imageCropPopup={this.state.imageCropPopup}
                  profileImageCrop={this.profileImageCrop}
                  handleDate={this.handleDate}
                  addCropImageDailog={this.AddCropImageDailog}
                  setImageData={this.setImageDataFeedback}
                  ideaPicImageCrop={this.state.ideaPicImageCrop}
                  feedVideoUpload={this.state.problemVideoUpload}
                  openPlusDialog={this.state.openPlusDialogProblem}
                  handleFeedPlus={this.handlePlusProblem}
                  handleTooltipClose={this.handleTooltipCloseProblem}
                />

                <CustomFeedInput
                  feedType="solution"
                  feedIcon={SolutionIcon}
                  tooltiptext={tooltiptext}
                  feedText={this.state.SolutionText}
                  handleFeedTextUndo={this.handleSolutionUndo}
                  handleFeedTextInput={this.handleSolutionTextInput}
                  errorInFeedText={this.state.ErrorInSolution}
                  errorInFeedMedia={this.state.ErrorInSolutionMedia}
                  handleTextRemoveIcon={this.handleSolRemoveIcon}
                  feedImagePreview={this.state.solutionImagePreview}
                  feedImageUpload={this.contextImageUpload}
                  imageCropPopup={this.state.imageCropPopup}
                  profileImageCrop={this.profileImageCrop}
                  handleDate={this.handleDate}
                  addCropImageDailog={this.AddCropImageDailog}
                  setImageData={this.setImageDataFeedback}
                  ideaPicImageCrop={this.state.ideaPicImageCrop}
                  feedVideoUpload={this.state.solutionVideoUpload}
                  openPlusDialog={this.state.openPlusDialogSolution}
                  handleFeedPlus={this.handlePlusSolution}
                  handleTooltipClose={this.handleTooltipCloseSolution}
                />
              </div>

              <div className="CustomErrorOverwrite">
                <CustomErrorAlert
                  isError={this.state.openSnackBarError}
                  errorMsg={
                    "Text cannot contain any of the following characters: { } [ ] | "
                  }
                  handleError={this.onCustomAlertCloseButtonClick}
                  closeBtnDisabled={true}
                />
                <CustomErrorAlert
                  isError={this.state.totalFileError}
                  errorMsg={"Combined file size should be less than 50MB"}
                  handleError={this.onCustomAlertCloseButtonClick}
                />
                <CustomErrorAlert
                  isError={this.state.titleValidation}
                  errorMsg={"Please add title."}
                  handleError={this.onCustomAlertCloseButtonClick}
                />
                <CustomErrorAlert
                  isError={this.state.problemValidation}
                  errorMsg={"Either problem or solution are mandatory."}
                  handleError={this.onCustomAlertCloseButtonClick}
                />
                <CustomErrorAlert
                  isError={this.state.ExtentionError}
                  errorMsg={
                    "Only files with the following extensions are allowed: .png, .gif, .jpg, .jpeg, .mp4"
                  }
                  handleError={this.onCustomAlertCloseButtonClick}
                />
              </div>

              <div style={{ position: "relative" }}>
                <Button
                  style={webStyle.submitBtn}
                  variant="outlined"
                  onClick={this.submitFeedback}
                  id="submitBtnId"
                >
                  SEND
                </Button>
              </div>
              {this.state.isLoading && <p>Submiting. Please wait...</p>}
            </div>
          </section>
        </section>

        <ActivityPopup
          testId="submitpopup"
          open={this.state.isAlertOpen}
          title={this.state.alertMessageText}
          leftBtnTxt="ok"
          handleLeftBtn={this.handleCloseAlertMessagePopup}
        />
      </ProtectedRouteWeb>
    );
  }

  // Customizable Area End
}

// Customizable Area Start
const webStyle = {
  frontbox: {
    color: "#6A8393",
    border: "none",
    padding: "60px 84px",
    fontFamily: "Jost-Regular",
    marginRight: "auto",
    fontWeight: 400,
    minHeight: "500",
    width: "auto"
  },
  dialogBoxtitle: {
    fontFamily: "Jost-Regular",
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "32px",
    letterSpacing: "0.1em",
    // TextTransform: "uppercase",
    color: "#6A8393",
    justifyContent: "center",
    paddingBottom: "36px",
    margin: "0px"
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0px 20px",
    maxHeight: 39,
    "& .Mui-focused": {
      background: "#012540",
      transition: "0.1s ease-in-out"
    }
  },
  probSolWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: "0px 20px",
    marginTop: "30px"
  },
  feedbackInput: {
    width: 500,
    color: "white",
    backgroundColor: "#091b27",
    maxHeight: "39px !important",
    padding: "11px 18px",
    fontFamily: "Jost-Regular",
    fontWeight: 400,
    marginLeft: "auto",
    marginRight: "0",
    height: "40px",
    borderRadius: "4px"
  },
  FeedbackIcon: {
    paddingRight: "8px"
  },
  width345: {
    alignSelf: "flex-start",
    width: "345"
  },
  submitBtn: {
    border: "1.6px solid #F1F2F2",
    width: "200px",
    height: 40,
    BoxSizing: "border-box",
    borderRadius: "4px",
    color: "#F1F2F2",
    marginLeft: "auto",
    marginRight: "auto",
    Position: "relative",
    marginTop: 40,
    fontFamily: "Jost-Regular",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "13px",
    lineHeight: "19px"
  },
  previewImage: {
    width: "100%",
    ObjectFit: "cover",
    height: "200px",
    borderRadius: "4px"
  }
};
export default FeedBack;

// Customizable Area End
