// Customizable Area Start
import React from "react";
//@ts-ignore
import { baseURL } from "../../framework/src/config.js";
import { noProfileImg } from "./assets";

export const getResizedProfilepic = () => {
  if (localStorage.getItem("resizeProfilepic") != "null") {
    return baseURL + localStorage.getItem("resizeProfilepic")
  } else if (localStorage.getItem("profilepic") != "null") {
    return baseURL + localStorage.getItem("profilepic")
  } else {
    return noProfileImg
  }
}

export const getStyle = (stateValue: any, style1: string, style2: string) => {
  return stateValue ? style1 : style2;
};

export const getTrueOrFalse = (condition: any) => {
  return !!condition
};

export const getOptionalCondition = (condition: any, value1: string, value2: string) => {
  return condition ? value1 : value2;
}

export const checkNullOrNot = (condition: any, value1: string, value2: string) => {
  return (condition === "null" || condition === null) ? value1 : value2;
}

export const checkPureNullOrNot = (condition: any, value1: string, value2: string) => {
  return condition != null ? value1 : value2;
}


// Customizable Area End